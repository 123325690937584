import className from 'classnames';
import React from 'react';
import { IoMdCheckboxOutline } from "react-icons/io";
import { MdCheckBoxOutlineBlank } from "react-icons/md";


interface ButtonComponentProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    children: JSX.Element;
    toggleValue: boolean;
    primary?: boolean;
    secondary?: boolean;
    subtle?: boolean;
    solo?: boolean;
    left?: boolean;
    center?: boolean;
    right?: boolean;
    lefttoright?: boolean
}


function ButtonToggle(props: ButtonComponentProps) {
    const {
        children,
        toggleValue,
        primary,
        secondary,
        subtle,
        solo,
        left,
        center,
        right,
        lefttoright,
        ...rest } = props;


    const classes = className(
        rest.className,
        'font-face-montserrat-medium text-sm flex items-center px-3',
        {
            'mb-3 border border-black bg-black text-white': primary,
            'mb-3 border-2 border-black bg-white text-black': secondary,
            'border hover:text-black': subtle,
            'border-gray-500 text-black': toggleValue,
            'border-gray-400 text-gray-400': !toggleValue,
            'rounded-lg py-1.5': solo,
            'rounded-l-lg': left,
            '': center,
            'rounded-r-lg': right,
            'rounded-lg': lefttoright
        }
    );

        const renderedIcon = (toggleValue)
        ? <IoMdCheckboxOutline />
        : <MdCheckBoxOutlineBlank />

    return (
        <button {...rest} className={classes}>
            {renderedIcon}
            &nbsp;
            {children}
        </button>
    );

}

export default ButtonToggle;
