import React, { PropsWithChildren } from "react";
import "./ToolContainer.css";


type ToolContainerProps = {
    title?: string;
    reference?: string;
}

function ToolContainer(props: PropsWithChildren<ToolContainerProps>) {
    const {
        children } = props;

    return (
        <div className="tool-container border-gray-200 rounded-lg">
                {children}
        </div>
    );

}

export default ToolContainer;
