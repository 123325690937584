interface ITableEntry {
    name: string;
    minRange: number;
    maxRange: number;
}

interface IReferenceTableEntry {
    name: string;
}

class EmptyEntry implements ITableEntry {
    name: string = '';
    minRange: number = -1;
    maxRange: number = -1;
}

enum RollType {
    Index = "Index",
    Range = "Range"
}

interface ITable<ENTRY extends ITableEntry> {
    name: string;
    rulesSection: string;
    description: string;
    dieType: string;
    rollType: RollType;
    entries: ENTRY[];
    emptyEntry: ENTRY;
}

interface IReferenceTable<ENTRY extends IReferenceTableEntry> {
    name: string;
    rulesSection: string;
    description: string;
    entries: ENTRY[];
}

interface ISubtable<ENTRY extends ITableEntry> extends ITable<ENTRY>, ITableEntry {
    // combines ITable and ITableEntry
}

interface ITwoFactorTable<ENTRY extends ITableEntry> {
    [key: string]: ITable<ENTRY>;
}


export { 
    ITable
    , ITableEntry 
    , ISubtable 
    , RollType
    , EmptyEntry
    , ITwoFactorTable
    , IReferenceTable
    , IReferenceTableEntry 
}
