import "../Npc.css";
import React from "react";
import GMTools from "../../../tools/GMTools";
import { NpcDataType } from "../../../rules/models/NpcData";
import GmDisplayPanel from "../general/GmDisplayPanel";
import { IoCopy } from "react-icons/io5";
import { BsPinAngleFill } from "react-icons/bs";
import GmPinBoard, { GmPinBoardItem } from "../general/GmPinBoard";
import ToolContainer from "../general/ToolContainer";
import Button from "../general/Button";
import { copyTextToClipboard } from "../../../tools/CopyTextToClipboard";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
    setNpcSelectedAncestry
    , setNpcList
    , setNpcQuantity
    , setSelectedNpc
    , removeNpc
    , addNpc
} from "../../../store";
import { ButtonWithOptionsOption } from "../general/ButtonWithOptions";
import ToolPanelFull from "../general/ToolPanelFull";
import GmDisplayTable from "../general/GmDisplayTable";
import ButtonTwoOptions from "../general/ButtonTwoOptions";


function NpcMobile() {
    const MAX_PINS = 6;
    const dispatch = useAppDispatch();
    const {
        selectedNpcAncestry,
        npcRoster,
        selectedNpc,
        npcQuantity,
        npcList
    } = useAppSelector((state) => {
        return {
            selectedNpcAncestry: state.npc.selectedAncestry,
            npcRoster: state.npc.npcRoster,
            selectedNpc: state.npc.selectedNpc,
            npcQuantity: state.npc.npcQuantity,
            npcList: state.npc.npcList
        }
    });

    const selectAncestryOptions = [
        { "label": "Dwarf", "value": "Dwarf" },
        { "label": "Elf", "value": "Elf" },
        { "label": "Goblin", "value": "Goblin" },
        { "label": "Halfling", "value": "Halfling" },
        { "label": "Half-Orc", "value": "Half-Orc" },
        { "label": "Human", "value": "Human" },
        { "label": "Random", "value": "" },
    ];
    const handleAncestryChange = (event: React.FormEvent<HTMLSelectElement>) => {
        const newAncestry = event.currentTarget.value;
        // console.log(`handleAncestryChange = ${newAncestry}`);
        dispatch(setNpcSelectedAncestry(newAncestry));
    };
    const handleCreateNpcClick = (event: React.MouseEvent<HTMLElement>) => {
        // console.log(`handleCreateNpcClick => selectedNpcAncestry = ${selectedNpcAncestry}`);
        const newNpcs = GMTools.getNpcs(npcQuantity, selectedNpcAncestry);
        dispatch(setNpcList(newNpcs));
    }

    const selectQuantityOptions = [
        { "label": "1", "value": "1" },
        { "label": "2", "value": "2" },
        { "label": "3", "value": "3" },
        { "label": "4", "value": "4" },
        { "label": "5", "value": "5" },
    ];
    const handleNpcQuantityChange = (event: React.FormEvent<HTMLSelectElement>) => {
        const newQuantity = +(event.currentTarget.value);
        dispatch(setNpcQuantity(newQuantity));
    };

    const pins: GmPinBoardItem<NpcDataType>[] = npcRoster.map((npc) => {
        return new GmPinBoardItem(`${npc.name} - ${npc.ancestry} ${npc.job}`, npc);
    });



    const handleRowClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
        // console.log(`handleRowClick row = ${index}`);
        const newSelectedNpc = { ...npcList[index] };
        dispatch(setSelectedNpc(newSelectedNpc));
    }
    const headerValuesMobile = ["Name", "Ancestry/Job"];
    const renderedNpcsMobile = npcList.map((npc, index) => {
        return (
            <tr
                key={index}
                className="border-y-2 border-black cursor-pointer"
                onClick={(e) => handleRowClick(e, index)}
            >
                <td className="pl-4">{npc.name}</td>
                <td className="pl-4">{`${npc.ancestry} ${npc.job}`}</td>
                {/* <td className="pl-4 text-sm">{npc.job}</td>
                <td className="pl-4 text-sm">{npc.alignment}</td>
                <td className="pl-4 text-sm">{npc.behavior}</td> */}
            </tr>
        );
    });
    const renderedNpcsTableMobile = npcList.length === 0
        ? null
        : <GmDisplayTable
            id="NPC List"
            heading="NPCS (Click for details.)"
            headerCells={headerValuesMobile}
        >
            {renderedNpcsMobile}
        </GmDisplayTable>




    const removePinClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
        dispatch(removeNpc(npcRoster[index]));
    }
    const selectPinClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
        dispatch(setSelectedNpc(npcRoster[index]));
    }
    const pinNpc = (event: React.MouseEvent<HTMLElement>) => {
        // console.log('pinNpc');
        dispatch(addNpc(selectedNpc));
    }
    function copyNpc(event: React.MouseEvent<HTMLElement>): void {
        const junko = document.getElementById(selectedNpc.name)?.innerText;
        // console.log(`JUNKO = ${junko}`);
        copyTextToClipboard(junko as string);
    }

    const functionButtons = (npcRoster.length >= MAX_PINS)
        ? <div className="flex flex-row items-center">
            <Button subtle lefttoright
                className='w-full justify-center'
                onClick={copyNpc}
            >
                <><IoCopy />&nbsp;Copy</>
            </Button>
        </div>
        : <div className="flex flex-row items-center">
            <Button subtle left
                className='w-1/2 justify-center'
                onClick={copyNpc}
            >
                <><IoCopy />&nbsp;Copy</>
            </Button>
            <Button subtle right
                className='w-1/2 justify-center'
                onClick={pinNpc}
            >
                <>Pin&nbsp;<BsPinAngleFill /></>
            </Button>
        </div>


    const renderedNpc = selectedNpc.name === "" ?
        null :
        <div className="grow pt-3">
            {functionButtons}
            <GmDisplayPanel
                heading={selectedNpc.name}
                subheading={`${selectedNpc.ancestry} ${selectedNpc.job}`}
            >
                <ul className="body-text text-left list-inside list-disc">
                    <li><b>Age:</b> {selectedNpc.age}</li>
                    <li><b>Alignment:</b> {selectedNpc.alignment}</li>
                    <li><b>Wealth:</b> {selectedNpc.wealth}</li>
                    <li><b>Appearance:</b> {selectedNpc.appearance}</li>
                    <li><b>Behavior:</b> {selectedNpc.behavior}</li>
                    <li><b>Secret:</b> {selectedNpc.secret}</li>
                </ul>
            </GmDisplayPanel>
        </div>;










    return (
        <ToolContainer>
            <ToolPanelFull
                title="NPC Details">
                <div className="flex flex-col w-full pb-2">
                    <ButtonTwoOptions
                        primary taller centerSelectorSmall rightSelectorMedium
                        optionsCenter={selectQuantityOptions as ButtonWithOptionsOption[]}
                        selectedValueCenter={npcQuantity.toString()}
                        onSelectChangeCenter={handleNpcQuantityChange}
                        optionsRight={selectAncestryOptions as ButtonWithOptionsOption[]}
                        selectedValueRight={selectedNpcAncestry}
                        onSelectChangeRight={handleAncestryChange}
                        onClick={handleCreateNpcClick}
                    >
                        <>Create NPCs</>
                    </ButtonTwoOptions>
                    <div>
                        <GmPinBoard
                            maxQuantity={MAX_PINS}
                            pinnedItems={pins}
                            pinnedItemName="NPCs"
                            removePin={removePinClick}
                            selectPin={selectPinClick}
                        />
                    </div>
                </div>
            </ToolPanelFull>
            <ToolPanelFull>
                {renderedNpcsTableMobile}
            </ToolPanelFull>
            <ToolPanelFull>
                {renderedNpc}
            </ToolPanelFull>

        </ToolContainer>
    );

}

export default NpcMobile;
