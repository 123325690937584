import "./GMToolsPage.css";
import RandomEncounters from "../components/gmtools/RandomEncounters";
import "./Page.css";
import React from "react";
import AndMore from "../components/gmtools/AndMore";
import NpcNames from "../components/gmtools/NpcNames";
import Npc from "../components/gmtools/Npc";
import TavernGenerator from "../components/gmtools/TavernGenerator";
import ShopGenerator from "../components/gmtools/ShopGenerator";
import MagicItemGenerator from "../components/gmtools/MagicItemGenerator";
import RivalCrawlers from "../components/gmtools/RivalCrawlers";
import { BrowserView, MobileView } from "react-device-detect";
import RandomEncountersMobile from "../components/gmtools/mobile/RandomEncounter.mobile";
import NpcMobile from "../components/gmtools/mobile/Npc.mobile";
import NpcNamesMobile from "../components/gmtools/mobile/NpcNames.mobile";
import ShopGeneratorMobile from "../components/gmtools/mobile/ShopGenerator.mobile";
import TavernGeneratorMobile from "../components/gmtools/mobile/TavernGenerator.mobile";
import MagicItemGeneratorMobile from "../components/gmtools/mobile/MagicItemGenerator.mobile";
import RivalCrawlersMobile from "../components/gmtools/mobile/RivalCrawlers.mobile";


function GMToolsPage() {

    return (
        <div>
            <BrowserView>
                <RandomEncounters />
                <NpcNames />
                <Npc />
                {/* <TrapsHazards /> */}
                <RivalCrawlers />
                <ShopGenerator />
                <TavernGenerator />
                <MagicItemGenerator />
                <AndMore />
            </BrowserView>
            {/* <div className="mb-3 mt-6 body-text">
            </div> */}
            <MobileView>
                <RandomEncountersMobile />
                <NpcNamesMobile />
                <NpcMobile />
                <RivalCrawlersMobile />
                <ShopGeneratorMobile />
                <TavernGeneratorMobile />
                <MagicItemGeneratorMobile />
                <AndMore />
            </MobileView>
        </div>
    );

}

export default GMToolsPage;
