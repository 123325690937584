import React from "react";
import ToolContainer from "../general/ToolContainer";
import GMTools from "../../../tools/GMTools";
import ToolPanelFull from "../general/ToolPanelFull";
import GmDisplayTable from "../general/GmDisplayTable";
import GmDisplayPanel from "../general/GmDisplayPanel";
import GmPinBoard, { GmPinBoardItem } from "../general/GmPinBoard";
import { copyTextToClipboard } from "../../../tools/CopyTextToClipboard";
import { IoCopy } from "react-icons/io5";
import Button from "../general/Button";
import { BsPinAngleFill } from "react-icons/bs";
import { TavernDataType } from "../../../rules/models/TavernData";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
    setTavernList
    , addTavern
    , removeTavern
    , setSelectedTavern
    , setTavernTier
    , setTavernQuantity
} from "../../../store";
import ButtonTwoOptions, { ButtonWithOptionsOption } from "../general/ButtonTwoOptions";

function TavernGenerator() {
    const MAX_PINS = 6;
    const dispatch = useAppDispatch();
    const {
        selectedTier,
        quantity,
        tavernList,
        tavernRoster,
        selectedTavern
    } = useAppSelector((state) => {
        return {
            selectedTier: state.tavern.selectedTier,
            quantity: state.tavern.quantity,
            tavernList: state.tavern.tavernList,
            tavernRoster: state.tavern.tavernRoster,
            selectedTavern: state.tavern.selectedTavern
        }
    });


    const pins: GmPinBoardItem<TavernDataType>[] = tavernRoster.map((tavern) => {
        return new GmPinBoardItem(`${tavern.name} - ${tavern.tier}`, tavern);
    });

    const removePinClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
        dispatch(removeTavern(tavernRoster[index]));
    }
    const selectPinClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
        dispatch(setSelectedTavern(tavernRoster[index]));
    }
    const pinTavern = (event: React.MouseEvent<HTMLElement>) => {
        dispatch(addTavern(selectedTavern));
    }



    const handleRowClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
        // console.log(`handleGetShopsClick row = ${index}`);
        const newSelectedTavern = { ...tavernList[index] };
        dispatch(setSelectedTavern(newSelectedTavern));
        // const newShops = GMTools.getShops(quantity, tier);
        // console.log(`SHOPS = ${JSON.stringify(newShops, undefined, 2)}`);
        // setShops(newShops);
    }

    const headerValues = ["Name (Tier)", "Drinks"];
    const renderedTaverns = tavernList.map((tavern, index) => {
        const drinkMenu = tavern.drinks.map((drink) => {
            return `${drink.name} (${drink.cost})`;
        });
        return (
            <tr
                key={index}
                className="border-y-2 border-black cursor-pointer"
                onClick={(e) => handleRowClick(e, index)}
            >
                <td className="pl-4">{`${tavern.name} (${tavern.tier.charAt(0)})`}</td>
                {/* <td className="pl-4 text-sm">{tavern.tier}</td>
                <td className="pl-4 text-sm">{tavern.knownFor}</td> */}
                {/* <td className="pl-4 text-sm">{tavern.menu.join("; ")}</td> */}
                <td className="pl-4 text-sm">{drinkMenu.join("; ")}</td>
            </tr>
        );
    });

    const renderedTavernsTable = tavernList.length === 0
        ? null
        : <GmDisplayTable
            id="Tavern List"
            heading="TAVERNS (Click for details.)"
            headerCells={headerValues}
        >
            {renderedTaverns}
        </GmDisplayTable>

    function copyTavern(event: React.MouseEvent<HTMLElement>): void {
        const junko = document.getElementById(selectedTavern.name)?.innerText;
        // console.log(`JUNKO = ${junko}`);
        copyTextToClipboard(junko as string);
    }

    const functionButtons = (pins.length >= MAX_PINS)
        ? <div className="flex flex-row items-center">
            <Button subtle lefttoright
                className='w-full justify-center'
                onClick={copyTavern}
            >
                <><IoCopy />&nbsp;Copy</>
            </Button>
        </div>
        : <div className="flex flex-row items-center">
            <Button subtle left
                className='w-1/2 justify-center'
                onClick={copyTavern}
            >
                <><IoCopy />&nbsp;Copy</>
            </Button>
            <Button subtle right
                className='w-1/2 justify-center'
                onClick={pinTavern}
            >
                <>Pin&nbsp;<BsPinAngleFill /></>
            </Button>
        </div>


    const renderedFoodMenu = selectedTavern.name === ""
        ? null
        : <ul className="body-text list-inside list-disc">
            {
                selectedTavern.menu.map((food, index) => {
                    return <li key={index} className="ml-6">
                        {food}
                    </li>;
                })
            }
        </ul>;

    const renderedDrinkMenu = selectedTavern.name === ""
        ? null
        : <ul className="body-text list-inside list-disc">
            {
                selectedTavern.drinks.map((drink, index) => {
                    return <li key={index} className="ml-6">
                        <b>{`${drink.name} (${drink.cost})`}</b>{`, ${drink.effect}`}
                    </li>;
                })
            }
        </ul>;
    const renderedSelectedTavern = selectedTavern.name === "" ?
        null :
        <div className="grow pt-3">
            {functionButtons}
            <GmDisplayPanel
                heading={selectedTavern.name}
            // subheading={`${selectedTavern.type}`}
            >
                <ul className="body-text text-left list-inside list-disc">
                    <li><b>Tier:</b> {selectedTavern.tier}</li>
                    <li><b>Known for:</b> {selectedTavern.knownFor}</li>
                    {/* <li><b>Menu:</b> {selectedTavern.menu.join("; ")}</li> */}
                    <li><b>Menu:</b> {renderedFoodMenu}</li>
                    {/* <li><b>Drinks:</b> {drinkMenu?.join("; ")}</li> */}
                    <li><b>Drinks:</b> {renderedDrinkMenu}</li>
                    {/* <li><b>Customer:</b> {selectedTavern.customer}</li> */}
                </ul>
            </GmDisplayPanel>
        </div>;






    const selectTierOptions = [
        { "label": "Poor", "value": "Poor" },
        { "label": "Standard", "value": "Standard" },
        { "label": "Wealthy", "value": "Wealthy" },
        { "label": "Rand. Tier", "value": "" },
    ];
    const handleShopTierChange = (event: React.FormEvent<HTMLSelectElement>) => {
        // console.log("length selector changed", (event.currentTarget.value));
        const newTier = event.currentTarget.value;
        dispatch(setTavernTier(newTier));
    };

    const selectQuantityOptions = [
        { "label": "1", "value": "1" },
        { "label": "2", "value": "2" },
        { "label": "3", "value": "3" },
        { "label": "4", "value": "4" },
        { "label": "5", "value": "5" },
    ];
    const handleTavernQuantityChange = (event: React.FormEvent<HTMLSelectElement>) => {
        // console.log("length selector changed", (event.currentTarget.value));
        const newQuantity = +(event.currentTarget.value);
        dispatch(setTavernQuantity(newQuantity));
    };


    const handleGetShopsClick = (event: React.MouseEvent<HTMLElement>) => {
        // console.log("handleGetShopsClick");
        const newTaverns = GMTools.getTaverns(quantity, selectedTier);
        // console.log(`SHOPS = ${JSON.stringify(newShops, undefined, 2)}`);
        dispatch(setTavernList(newTaverns));
    }






    return (
        <ToolContainer>
            <ToolPanelFull
                title="Taverns"
            >
                <div className="flex flex-col w-full pb-2">
                    <ButtonTwoOptions
                        primary taller centerSelectorSmall rightSelectorMedium
                        optionsCenter={selectQuantityOptions as ButtonWithOptionsOption[]}
                        selectedValueCenter={quantity.toString()}
                        onSelectChangeCenter={handleTavernQuantityChange}
                        optionsRight={selectTierOptions as ButtonWithOptionsOption[]}
                        selectedValueRight={selectedTier}
                        onSelectChangeRight={handleShopTierChange}
                        onClick={handleGetShopsClick}
                    >
                        <>Get Taverns</>
                    </ButtonTwoOptions>
                    <div className="pb-3">
                        <GmPinBoard
                            maxQuantity={MAX_PINS}
                            pinnedItems={pins}
                            pinnedItemName="Taverns"
                            removePin={removePinClick}
                            selectPin={selectPinClick}
                        />
                    </div>
                    <ToolPanelFull>
                        {renderedTavernsTable}
                    </ToolPanelFull>
                    <ToolPanelFull>
                        {renderedSelectedTavern}
                    </ToolPanelFull>
                </div>
            </ToolPanelFull>
        </ToolContainer>
    );

}

export default TavernGenerator;
