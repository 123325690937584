type ShopDataType = {
    id: string,
    tier: string,
    name: string,
    type: string,
    knownFor: string,
    customer: string
}

class ShopData {
    id: string = "";
    tier: string = "";
    name: string = "";
    type: string = "";
    knownFor: string = "";
    customer: string = "";

    public getCleanType(): ShopDataType {
        const result = {
            id: this.id,
            tier: this.tier,
            name: this.name,
            type: this.type,
            knownFor: this.knownFor,
            customer: this.customer
        };
        return result;
    }

    public static getEmptyType(): ShopDataType {
        const result = {
            id: '',
            tier: '',
            name: '',
            type: '',
            knownFor: '',
            customer: '',
        };
        return result;
    }
}

export { ShopDataType };
export default ShopData;
