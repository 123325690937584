import "./HomePage.css";
import React from "react";


function CharacterPage() {

    return (
        <div>
            <div className="mb-3 mt-6 body-text">
                The Character generator isn't quite ready yet. There are some great ones out there already.
                See the Resource pages for links.
            </div>
            <div>
                <div>
                    <h2>What's on the Way</h2>
                </div>
                <div>
                    <ul className="body-text list-inside list-disc">
                        <li>Random character generator, with ability to customize certain items.</li>
                        <li>Peasant (Level 0) character generator.</li>
                        <li>Gauntlet 4-Pack generator.</li>
                    </ul>
                </div>
            </div>
        </div>
    );

}

export default CharacterPage;
