import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit';
import RivalCrawlerParty, { RivalCrawlerPartyType } from "../../rules/models/RivalCrawlersData";

const emptyRivalParty = RivalCrawlerParty.getEmptyType();

const rivalCrawlersSlice = createSlice({
    name: 'rivals',
    initialState: {
        rivalParty: emptyRivalParty as RivalCrawlerPartyType,
        rivalPartySize: 0, 
    },
    reducers: {
        setRivalParty(state, action: PayloadAction<RivalCrawlerPartyType>) {
            state.rivalParty = action.payload;
        },
        setRivalPartySize(state, action: PayloadAction<number>) {
            state.rivalPartySize = action.payload;
        },
        removeRivalParty(state, action: PayloadAction<RivalCrawlerPartyType>) {
            state.rivalParty = RivalCrawlerParty.getEmptyType();
        },
    }
});

export const {
    setRivalParty
    , setRivalPartySize
    , removeRivalParty
} = rivalCrawlersSlice.actions;
export const rivalCrawlersReducer = rivalCrawlersSlice.reducer;
