import "./RandomEncounters.css";
import React from "react";
import GMTools from "../../tools/GMTools";
import ToolPanelRight from "./general/ToolPanelRight";
import ToolContainer from "./general/ToolContainer";
import ToolPanelLeft from "./general/ToolPanelLeft";
import GmDisplayPanel from "./general/GmDisplayPanel";
import Button from "./general/Button";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
    removeLogEntry,
    setEncounter,
    updateEncounter,
    toggleShowLog,
    toggleConfirmLogClear,
    clearEncounterLog,
    setEncounterTableDie
} from "../../store";
import ToolPanelFull from "./general/ToolPanelFull";
import GmDisplayTable from "./general/GmDisplayTable";
import { IoCloseCircle, IoCopy } from "react-icons/io5";
import { EncounterDataType } from "../../rules/models/EncounterData";
import { copyTextToClipboard } from "../../tools/CopyTextToClipboard";

function RandomEncounters() {
    const dispatch = useAppDispatch();
    const {
        encounter,
        encounterLog,
        showLog,
        confirmLogClear,
        encounterTableDie
    } = useAppSelector((state) => {
        return {
            encounter: state.encounter.data,
            encounterLog: state.encounter.log,
            showLog: state.encounter.showLog,
            confirmLogClear: state.encounter.confirmLogClear,
            rollEncounterTableDie: state.encounter.rollEncounterTableDie,
            encounterTableDie: state.encounter.encounterTableDie
        }
    });

    // console.log(`RANDOM ENCOUNTER ${JSON.stringify(encounter, undefined, 3)}`);
    // console.log(`ENCOUNTER LOG: ${JSON.stringify(encounterLog, undefined, 3)}`);
    // const [encounter, setEncounter] = useState<EncounterData>(new EncounterData());

    const selectEncounterTableDieOptions = [
        { "label": "none", "value": "" },
        { "label": "d4", "value": "d4" },
        { "label": "d6", "value": "d6" },
        { "label": "d8", "value": "d8" },
        { "label": "d10", "value": "d10" },
        { "label": "d12", "value": "d12" },
        { "label": "d20", "value": "d20" },
        { "label": "d100", "value": "d100" },
    ];
    const renderedEncounterTableDieSelectOptions = selectEncounterTableDieOptions.map((option) => {
        return (
            <option
                key={option.label}
                value={option.value}
            >{option.label}</option>
        );
    });

    const selectCharismaOptions = [
        { "label": "-4", "value": -4 },
        { "label": "-3", "value": -3 },
        { "label": "-2", "value": -2 },
        { "label": "-1", "value": -1 },
        { "label": " 0", "value": 0 },
        { "label": "+1", "value": 1 },
        { "label": "+2", "value": 2 },
        { "label": "+3", "value": 3 },
        { "label": "+4", "value": 4 },
    ];
    const renderedCharismaSelectOptions = selectCharismaOptions.map((option) => {
        return (
            <option
                key={option.label}
                value={option.value}
            >{option.label}</option>
        );
    });

    const handleCharismaChange = (event: React.FormEvent<HTMLSelectElement>) => {
        // console.log("handleCharismaChange", (event.currentTarget.value));
        const newChaMod = +(event.currentTarget.value);
        const newEncounter = GMTools.updateEncounterReaction(encounter, newChaMod);
        dispatch(updateEncounter(newEncounter));
    };

    const handleEncounterCheckClick = (event: React.MouseEvent<HTMLElement>) => {
        // console.log("handleEncounterCheckClick");
        const newEncounter = GMTools.getRandomEncounter(encounter.charismaMod, encounterTableDie);
        dispatch(setEncounter(newEncounter));
    }

    const handleGetEncounterClick = (event: React.MouseEvent<HTMLElement>) => {
        // console.log("handleGetEncounterClick");
        const newEncounter = GMTools.getEncounter(encounter.charismaMod, encounterTableDie);
        dispatch(setEncounter(newEncounter));
    }

    let resultTitle = "All's Quiet...";
    let renderedRandomEncounter = null;
    let encounterTableRoll = (encounter.encounterTableRoll === 0)
        ? null
        : <li><b>Encounter Table Roll:</b> {encounter.encounterTableRoll}</li>
    if (encounter.encounterRoll === 1) {
        resultTitle = "";
        renderedRandomEncounter = <GmDisplayPanel
            heading="Encounter!">
            <ul className="body-text text-left pl-8 list-inside list-disc">
                {encounterTableRoll}
                <li><b>Distance:</b> {encounter.distance}</li>
                <li><b>Activity:</b> {encounter.activity}</li>
                <li><b>Reaction:</b> {encounter.reaction}</li>
                <li><b>CHA mod:</b> {encounter.charismaMod}</li>
                <li><b>Treasure:</b> {encounter.treasure}</li>
            </ul>
        </GmDisplayPanel>;
    }

    const renderEncounterRoll = <div className="pl-2 text-right">1d6 rolled: {encounter.encounterRoll}</div>

    const special = <div>
        <div className="w-full bg-gray-300 mt-2 p-1 text-center">
            FREQUENCY
        </div>
        <table className="table-auto w-full">
            <tbody>
                <tr className="border-y-2 border-black">
                    <td className="pl-2">Unsafe</td>
                    <td>Every 3 rounds</td>
                </tr>
                <tr className="border-b-2 border-black">
                    <td className="pl-2">Risky</td>
                    <td>Every 2 rounds</td>
                </tr>
                <tr className="border-b-2 border-black">
                    <td className="pl-2">Deadly</td>
                    <td>Every round</td>
                </tr>
            </tbody>
        </table>
    </div>


    const handleToggleEncounterLog = (event: React.MouseEvent<HTMLElement>) => {
        // console.log("handleToggleEncounterLog");
        dispatch(toggleShowLog(showLog));
    }
    const handleRemoveEntryClick = (event: React.MouseEvent<HTMLElement>, entry: EncounterDataType) => {
        // console.log("handleRemoveEntryClick");
        dispatch(removeLogEntry(entry));
    }
    const headerValues = ["Elapsed", "Roll", "ETRoll", "Distance", "Activity", "Reaction", "Treasure", ""];
    const renderedLogEntries = encounterLog.map((entry, index) => {
        const totalSec = (index === 0)
            ? 0
            : Math.floor(entry.when - encounterLog[index - 1].when) / 1000;
        const elapsedMin = Math.floor(totalSec / 60);
        const elapsedSec = Math.floor(totalSec - (elapsedMin * 60));
        const elapsedTime = (elapsedSec === 0 && index === 0)
            ? "start"
            : `${elapsedMin}m ${elapsedSec}s`;
        return (
            <tr key={entry.id} className="border-y-2 border-black">
                <td className="pl-4 text-sm">{elapsedTime}</td>
                <td className="pl-4 text-sm">{entry.encounterRoll}</td>
                <td className="pl-4 text-sm">{(entry.encounterRoll === 1) ? entry.encounterTableRoll : "--"}</td>
                <td className="pl-4 text-sm">{(entry.encounterRoll === 1) ? entry.distance : "--"}</td>
                <td className="pl-4 text-sm">{(entry.encounterRoll === 1) ? entry.activity : "--"}</td>
                <td className="pl-4 text-sm">{(entry.encounterRoll === 1) ? entry.reaction : "--"}</td>
                <td className="pl-4 text-sm">{(entry.encounterRoll === 1) ? entry.treasure : "--"}</td>
                <td className="pl-4 text-sm">
                    <button
                        className="pt-2 text-xl rounded-r-lg px-1 text-gray-400  hover:text-black"
                        onClick={(e) => handleRemoveEntryClick(e, entry)}
                    >
                        <IoCloseCircle />
                    </button>
                </td>
            </tr>
        );
    });

    function copyLog(event: React.MouseEvent<HTMLElement>): void {
        const junko = document.getElementById("Encounter Check Log")?.innerText;
        // console.log(`JUNKO = ${junko}`);
        copyTextToClipboard(junko as string);
    }
    const handleToggleConfirmLogClear = (event: React.MouseEvent<HTMLElement>) => {
        // console.log("handleToggleEncounterLog");
        dispatch(toggleConfirmLogClear(confirmLogClear));
    }
    const handleLogClear = (event: React.MouseEvent<HTMLElement>) => {
        // console.log("handleToggleEncounterLog");
        dispatch(clearEncounterLog());
    }

    const renderedLogTable = (encounterLog.length === 0 || !showLog)
        ? null
        : <GmDisplayTable
            id="Encounter Check Log"
            heading="ENCOUNTER CHECK LOG"
            headerCells={headerValues}
        >
            {renderedLogEntries}
        </GmDisplayTable>

    const renderedCopyLogButton = (!showLog || encounterLog.length === 0)
        ? null
        : <Button
            subtle
            lefttoright
            onClick={copyLog}
            className="ml-1 mb-4 justify-right"
        >
            <><IoCopy />&nbsp;Copy</>
        </Button>;

    const renderedClearLogButtonName = (confirmLogClear) ? "Cancel" : "Clear";
    const renderedClearLogButton = (!showLog || encounterLog.length === 0)
        ? null
        : <Button
            subtle
            lefttoright
            onClick={handleToggleConfirmLogClear}
            className="ml-1 mb-4 justify-right"
        >
            <><IoCloseCircle />&nbsp;{renderedClearLogButtonName}</>
        </Button>;
    const renderedConfirmClearLogButton = (!showLog || !confirmLogClear)
        ? null
        : <Button
            subtle
            lefttoright
            onClick={handleLogClear}
            className="ml-1 mb-4 justify-right"
        >
            <><IoCloseCircle />&nbsp;No, Really. Clear it.</>
        </Button>;

    const handleETDieChange = (event: React.FormEvent<HTMLSelectElement>) => {
        // console.log("handleETDieChange", (event.currentTarget.value));
        const newDieRoll = event.currentTarget.value;
        dispatch(setEncounterTableDie(newDieRoll));
    };


    return (
        <ToolContainer>
            <div className="flex">
                <ToolPanelLeft
                    title="Random Encounters"
                    special={special}
                >
                    <div>
                        <div className='pr-2 pt-4'>
                            <label
                                htmlFor="encounter table die"
                                className="tool-input-label">Encounter Table Die</label>
                            <select
                                id="encounter table die"
                                name="encounter table die"
                                value={encounterTableDie}
                                className="encounters-die-selector"
                                onChange={handleETDieChange}
                            >
                                {renderedEncounterTableDieSelectOptions}
                            </select>
                        </div>
                    </div>
                    <div className="mt-2 flex flex-row items-center">
                        <Button primary solo
                            onClick={handleEncounterCheckClick}
                        >
                            <>Encounter Check</>
                        </Button>
                        <div className="mb-3 flex items-center">{renderEncounterRoll}</div>
                    </div>
                    <div className="flex flex-row">
                        <Button primary solo
                            onClick={handleGetEncounterClick}
                        >
                            <>Get Encounter</>
                        </Button>
                    </div>
                    <div>
                        <div className='px-2 pb-4'>
                            <label
                                htmlFor="charisma modifier"
                                className="tool-input-label">CHA modifier</label>
                            <select
                                id="charisma modifier"
                                name="charisma modifier"
                                value={encounter.charismaMod}
                                className="encounters-charisma-selector"
                                onChange={handleCharismaChange}
                            >
                                {renderedCharismaSelectOptions}
                            </select>
                        </div>
                    </div>
                </ToolPanelLeft>
                <ToolPanelRight
                    title={resultTitle}>
                    {renderedRandomEncounter}
                </ToolPanelRight>
            </div>
            <div className="flex">
                <Button
                    subtle
                    lefttoright
                    onClick={handleToggleEncounterLog}
                    className="mb-4"
                >
                    <>{`${(showLog) ? "Hide" : "Show"}`} Enounter Check Log ({`${encounterLog.length} entries`})</>
                </Button>
                <div className="grow"></div>
                {renderedConfirmClearLogButton}
                {renderedClearLogButton}
                {renderedCopyLogButton}
            </div>
            <ToolPanelFull>
                {renderedLogTable}
            </ToolPanelFull>
        </ToolContainer>
    );

}

export default RandomEncounters;
