import { ITableEntry } from "../interfaces/ITable";

//
// Objects that represent the entries in the tables 
// CANNOT have functions defined in them.
//
class TavernDrinkEntry implements ITableEntry {
    minRange: number = 0;
    maxRange: number = 0;
    name: string = '';
    cost: string = '';
    effect: string = '';
}

//
// Plain types are required for working with React-Redux Toolkit.
// These types, and the conversion function on the associated classes
// are only to support the toolkit.
//
type TavernDrinkDataType = {
    name: string;
    cost: string;
    effect: string;
}

class TavernDrinkData {
    name: string = '';
    cost: string = '';
    effect: string = '';

    public getCleanType(): TavernDrinkDataType {
        const result = {
            name: this.name,
            cost: this.cost,
            effect: this.effect
        };
        return result;
    }

    public static getEmptyType(): TavernDrinkDataType {
        const result = {
            name: '',
            cost: '',
            effect: ''
        };
        return result;
    }

    public static getCleanTypeFromEntry(entry: TavernDrinkEntry): TavernDrinkDataType {
        const result = {
            name: entry.name,
            cost: entry.cost,
            effect: entry.effect
        };
        return result;
    }
}


type TavernDataType = {
    id: string;
    tier: string;
    name: string;
    knownFor: string;
    menu: string[];
    drinks: TavernDrinkDataType[];
}

class TavernData {
    id: string = "";
    tier: string = "";
    name: string = "";
    knownFor: string = "";
    menu: string[] = [];
    drinks: TavernDrinkDataType[] = [];

    public getCleanType(): TavernDataType {
        const result = {
            id: this.id,
            tier: this.tier,
            name: this.name,
            knownFor: this.knownFor,
            menu: this.menu.slice(),
            drinks: this.drinks.slice(),
        };
        return result;
    }

    public static getEmptyType(): TavernDataType {
        const result = {
            id: '',
            tier: '',
            name: '',
            knownFor: '',
            menu: [],
            drinks: []
        };
        return result;
    }
}

export { TavernDataType, TavernDrinkEntry, TavernDrinkData, TavernDrinkDataType };
export default TavernData;
