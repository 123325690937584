import "./Npc.css";
import React from "react";
import GMTools from "../../tools/GMTools";
import GmDisplayPanel from "./general/GmDisplayPanel";
import { IoCopy } from "react-icons/io5";
import { BsPinAngleFill } from "react-icons/bs";
import GmPinBoard, { GmPinBoardItem } from "./general/GmPinBoard";
import ToolContainer from "./general/ToolContainer";
import ToolPanelLeft from "./general/ToolPanelLeft";
import ToolPanelRight from "./general/ToolPanelRight";
import Button from "./general/Button";
import { copyTextToClipboard } from "../../tools/CopyTextToClipboard";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
    setMagicItemList
    , setMagicItemQuantity
    , setSelectedMagicItemType
    , addMagicItem
    , removeMagicItem
    , setSelectedMagicItem
    , setAllowPriestSpells
} from "../../store";
import { ButtonWithOptionsOption } from "./general/ButtonWithOptions";
import ToolPanelFull from "./general/ToolPanelFull";
import GmDisplayTable from "./general/GmDisplayTable";
import ButtonTwoOptions from "./general/ButtonTwoOptions";
import { MagicItemDataType, MagicItemDisplay, MagicItemType, selectMagicItemOptions } from "../../rules/models/MagicItemData";
import ButtonToggle from "./general/ButtonToggle";


function MagicItemGenerator() {
    const MAX_PINS = 6;
    const dispatch = useAppDispatch();
    const {
        magicItemType,
        magicItemQuantity,
        magicItemList,
        selectedMagicItem,
        magicItemRoster,
        magicItemAllowPriestSpells
    } = useAppSelector((state) => {
        return {
            magicItemType: state.magicItem.selectedMagicItemType,
            magicItemQuantity: state.magicItem.magicItemQuantity,
            magicItemList: state.magicItem.magicItemList,
            selectedMagicItem: state.magicItem.selectedMagicItem,
            magicItemRoster: state.magicItem.magicItemRoster,
            magicItemAllowPriestSpells: state.magicItem.magicItemAllowPriestSpells
        }
    });


    const handleItemTypeChange = (event: React.FormEvent<HTMLSelectElement>) => {
        const newItemType = event.currentTarget.value;
        // console.log(`handleItemTypeChange = ${newItemType}`);
        dispatch(setSelectedMagicItemType(newItemType));
    };
    const handleCreateItemClick = (event: React.MouseEvent<HTMLElement>) => {
        // console.log(`handleCreateNpcClick => selectedNpcAncestry = ${selectedNpcAncestry}`);
        const itemType: MagicItemType = MagicItemType[magicItemType as keyof typeof MagicItemType]
        const newItems = GMTools.getMagicItems(magicItemQuantity, itemType, magicItemAllowPriestSpells);
        dispatch(setMagicItemList(newItems));
    }

    const selectQuantityOptions = [
        { "label": "1", "value": "1" },
        { "label": "2", "value": "2" },
        { "label": "3", "value": "3" },
        { "label": "4", "value": "4" },
        { "label": "5", "value": "5" },
    ];
    const handleItemQuantityChange = (event: React.FormEvent<HTMLSelectElement>) => {
        const newQuantity = +(event.currentTarget.value);
        dispatch(setMagicItemQuantity(newQuantity));
    };

    const handleAllowPriestSpellsClick = (event: React.MouseEvent<HTMLElement>) => {
        dispatch(setAllowPriestSpells(!magicItemAllowPriestSpells));
    }



    const handleRowClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
        // console.log(`handleRowClick row = ${index}`);
        const newSelectedItem = { ...magicItemList[index] };
        dispatch(setSelectedMagicItem(newSelectedItem));
    }
    const headerValues = ["Item", "Feature", "Benefits", "Curses", "V/F/A"]; //, "Alignment", "Behavior"];
    const renderedMagicItems = magicItemList.map((magicItem, index) => {
        const itemDisplay = new MagicItemDisplay(magicItem);
        return (
            <tr
                key={index}
                className="border-y-2 border-black cursor-pointer"
                onClick={(e) => handleRowClick(e, index)}
            >
                <td className="pl-4">{itemDisplay.description()}</td>
                <td className="pl-4 text-sm">{itemDisplay.feature()}</td>
                <td className="pl-4 text-sm">{itemDisplay.benefitNames()}</td>
                <td className="pl-4 text-sm">{itemDisplay.curseNames()}</td>
                <td className="pl-4 text-sm">{itemDisplay.personalitySummary()}</td>
            </tr>
        );
    });
    const renderedMagicItemsTable = magicItemList.length === 0
        ? null
        : <GmDisplayTable
            id="NPC List"
            heading="Magic Items (Click on a row to see item details.)"
            headerCells={headerValues}
        >
            {renderedMagicItems}
        </GmDisplayTable>




    const removePinClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
        dispatch(removeMagicItem(magicItemRoster[index]));
    }
    const selectPinClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
        dispatch(setSelectedMagicItem(magicItemRoster[index]));
    }
    const pinMagicItem = (event: React.MouseEvent<HTMLElement>) => {
        // console.log('pinMagicItem');
        dispatch(addMagicItem(selectedMagicItem));
    }
    function copyMagicItem(event: React.MouseEvent<HTMLElement>): void {
        const junko = document.getElementById(selectedItemDisplay.description())?.innerText;
        // console.log(`JUNKO = ${junko}`);
        copyTextToClipboard(junko as string);
    }

    const functionButtons = (magicItemRoster.length >= MAX_PINS)
        ? <div className="flex flex-row items-center">
            <Button subtle lefttoright
                className='w-full justify-center'
                onClick={copyMagicItem}
            >
                <><IoCopy />&nbsp;Copy</>
            </Button>
        </div>
        : <div className="flex flex-row items-center">
            <Button subtle left
                className='w-1/2 justify-center'
                onClick={copyMagicItem}
            >
                <><IoCopy />&nbsp;Copy</>
            </Button>
            <Button subtle right
                className='w-1/2 justify-center'
                onClick={pinMagicItem}
            >
                <>Pin&nbsp;<BsPinAngleFill /></>
            </Button>
        </div>


    const pins: GmPinBoardItem<MagicItemDataType>[] = magicItemRoster.map((magicItem) => {
        const pinItemDisplay = new MagicItemDisplay(magicItem);
        return new GmPinBoardItem(`${magicItem.type} ${magicItem.bonus} - ${pinItemDisplay.feature()}`, magicItem);
    });


    const selectedItemDisplay = new MagicItemDisplay(selectedMagicItem);
    const renderedFeatureItems = selectedItemDisplay.featureList().map((feature, index) => {
        return (
            <li key={index} className="ml-6 text-sm"><b>{feature.name}: </b>{feature.description}</li>
        );
    });
    const renderedFeatures = selectedItemDisplay.featureList().length === 0
        ? null
        : <li><b>Features</b>
            <ul className="body-text list-inside list-disc">
                {renderedFeatureItems}
            </ul>
        </li>;


    const renderedBenefitsCursesItems = selectedItemDisplay.benfitsCursesList().map((bc, index) => {
        return (
            <li key={index} className="ml-6 text-sm"><b>{bc.name}: </b>{bc.description}</li>
        );
    });
    const renderedBenefitsCurses = selectedItemDisplay.benfitsCursesList().length === 0
        ? null
        : <li><b>Benefits/Curses</b>
            <ul className="body-text list-inside list-disc">
                {renderedBenefitsCursesItems}
            </ul>
        </li>;


    const renderedPersonalityItems = selectedItemDisplay.personalityList().map((p, index) => {
        return (
            <li key={index} className="ml-6 text-sm"><b>{p.name}: </b>{p.description}</li>
        );
    });
    const renderedPersonality = selectedItemDisplay.personalityList().length === 0
        ? null
        : <li><b>Personality</b>
            <ul className="body-text list-inside list-disc">
                {renderedPersonalityItems}
            </ul>
        </li>;


    const renderedItem = selectedMagicItem.type === "" ?
        null :
        <div className="grow">
            {functionButtons}
            <GmDisplayPanel
                heading={selectedItemDisplay.description()}
                subheading={selectedItemDisplay.subheading()}
            >
                <ul className="body-text text-left list-inside list-disc">
                    {renderedFeatures}
                    {renderedBenefitsCurses}
                    {renderedPersonality}
                    {/* <li><b>Alignment:</b> {selectedNpc.alignment}</li>
                    <li><b>Wealth:</b> {selectedNpc.wealth}</li>
                    <li><b>Appearance:</b> {selectedNpc.appearance}</li>
                    <li><b>Behavior:</b> {selectedNpc.behavior}</li>
                    <li><b>Secret:</b> {selectedNpc.secret}</li> */}
                </ul>
            </GmDisplayPanel>
        </div>;

    return (
        <ToolContainer>
            <div className="flex pb-4">
                <ToolPanelLeft
                    title="Magic Item Generator"
                >
                    <ButtonTwoOptions
                        primary taller centerSelectorSmall rightSelectorLarge
                        optionsCenter={selectQuantityOptions as ButtonWithOptionsOption[]}
                        selectedValueCenter={magicItemQuantity.toString()}
                        onSelectChangeCenter={handleItemQuantityChange}
                        optionsRight={selectMagicItemOptions as ButtonWithOptionsOption[]}
                        selectedValueRight={magicItemType}
                        onSelectChangeRight={handleItemTypeChange}
                        onClick={handleCreateItemClick}
                    >
                        <>Create Items</>
                    </ButtonTwoOptions>
                    <ButtonToggle
                        toggleValue={magicItemAllowPriestSpells}
                        subtle solo
                        onClick={((e) => handleAllowPriestSpellsClick(e))}
                    >
                        <>Allow Priest Spells for Scrolls/Wands</>
                    </ButtonToggle>
                    <div className="pt-4">
                        <GmPinBoard
                            maxQuantity={MAX_PINS}
                            pinnedItems={pins}
                            pinnedItemName="Magic Items"
                            removePin={removePinClick}
                            selectPin={selectPinClick}
                        />
                    </div>

                </ToolPanelLeft>
                <ToolPanelRight>

                    {renderedItem}

                </ToolPanelRight>
            </div>
            <ToolPanelFull>
                {renderedMagicItemsTable}
            </ToolPanelFull>
        </ToolContainer>
    );

}

export default MagicItemGenerator;
