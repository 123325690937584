import { createSlice, nanoid } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit';
import NpcData, { NpcDataType } from "../../rules/models/NpcData";

const emptyNpcDataType = NpcData.getEmptyNpcDataType();

const npcSlice = createSlice({
    name: 'npcs',
    initialState: {
        npcRoster: [] as NpcDataType[],
        selectedNpc: emptyNpcDataType as NpcDataType, 
        selectedAncestry: '',
        npcList: [] as NpcDataType[],
        npcQuantity: 3
    },
    reducers: {
        setNpcList(state, action: PayloadAction<NpcDataType[]>) {
            state.npcList = action.payload;
        },
        setNpcQuantity(state, action: PayloadAction<number>) {
            state.npcQuantity = action.payload;
        },
        setNpcSelectedAncestry(state, action: PayloadAction<string>) {
            state.selectedAncestry = action.payload;
        },
        addNpc(state, action: PayloadAction<NpcDataType>) {
            const newData = { ...action.payload };
            newData.id = nanoid();
            state.npcRoster.push(newData);
        },
        removeNpc(state, action: PayloadAction<NpcDataType>) {
            const updatedData = state.npcRoster.filter((npc) => {
                return npc.id !== action.payload.id;
            });
            state.npcRoster = updatedData;
        },
        setSelectedNpc(state, action: PayloadAction<NpcDataType>) {
            state.selectedNpc = action.payload;
        }
    }
});

export const {
    setNpcSelectedAncestry
    , setNpcList
    , setNpcQuantity
    , addNpc
    , removeNpc
    , setSelectedNpc
} = npcSlice.actions;
export const npcReducer = npcSlice.reducer;
