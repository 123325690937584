import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit';

const npcNamesSlice = createSlice({
    name: 'npcNames',
    initialState: {
        names: [] as string[],
        namesType: "" as string,
        nameQuantity: 5, 
        nameLength: 0,
        ancestryQuantity: 5,
        ancestry: "dwarf"
    },
    reducers: {
        setNpcNames(state, action: PayloadAction<string[]>) {
            state.names = action.payload;
        },
        setNpcNameQuantity(state, action: PayloadAction<number>) {
            state.nameQuantity = action.payload;
        },
        setNpcNameLength(state, action: PayloadAction<number>) {
            state.nameLength = action.payload;
        },
        setNpcAncestryQuantity(state, action: PayloadAction<number>) {
            state.ancestryQuantity = action.payload;
        },
        setNpcAncestry(state, action: PayloadAction<string>) {
            state.ancestry = action.payload;
        },
        setNamesType(state, action: PayloadAction<string>) {
            state.namesType = action.payload;
        }
    }
});

export const {
    setNpcNames
    , setNpcNameQuantity
    , setNpcNameLength
    , setNpcAncestryQuantity
    , setNpcAncestry
    , setNamesType
} = npcNamesSlice.actions;
export const npcNamesReducer = npcNamesSlice.reducer;
