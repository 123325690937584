import { createSlice, nanoid } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit';
import TavernData, { TavernDataType } from "../../rules/models/TavernData";

const emptyTavernDataType = TavernData.getEmptyType();

const tavernSlice = createSlice({
    name: 'taverns',
    initialState: {
        tavernList: [] as TavernDataType[],
        tavernRoster: [] as TavernDataType[],
        selectedTavern: emptyTavernDataType as TavernDataType, 
        selectedTier: "",
        quantity: 3
    },
    reducers: {
        setTavernList(state, action: PayloadAction<TavernDataType[]>) {
            state.tavernList = action.payload;
        },
        addTavern(state, action: PayloadAction<TavernDataType>) {
            const newData = { ...action.payload };
            newData.id = nanoid();
            state.tavernRoster.push(newData);
        },
        removeTavern(state, action: PayloadAction<TavernDataType>) {
            const updatedData = state.tavernRoster.filter((tavern) => {
                return tavern.id !== action.payload.id;
            });
            state.tavernRoster = updatedData;
        },
        setSelectedTavern(state, action: PayloadAction<TavernDataType>) {
            state.selectedTavern = action.payload;
        },
        setTavernTier(state, action: PayloadAction<string>) {
            state.selectedTier = action.payload;
        },
        setTavernQuantity(state, action: PayloadAction<number>) {
            state.quantity = action.payload;
        }
    }
});

export const {
    setTavernList
    , addTavern
    , removeTavern
    , setSelectedTavern
    , setTavernTier
    , setTavernQuantity
} = tavernSlice.actions;
export const tavernReducer = tavernSlice.reducer;
