import React from "react";
import ToolContainer from "../general/ToolContainer";
import { ShopDataType } from "../../../rules/models/ShopData";
import GMTools from "../../../tools/GMTools";
import ToolPanelFull from "../general/ToolPanelFull";
import GmDisplayTable from "../general/GmDisplayTable";
import GmDisplayPanel from "../general/GmDisplayPanel";
import GmPinBoard, { GmPinBoardItem } from "../general/GmPinBoard";
import { copyTextToClipboard } from "../../../tools/CopyTextToClipboard";
import { IoCopy } from "react-icons/io5";
import Button from "../general/Button";
import { BsPinAngleFill } from "react-icons/bs";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
    setSelectedShop
    , setShopList
    , removeShop
    , addShop
    , setShopTier
    , setShopQuantity
} from "../../../store";
import ButtonTwoOptions, { ButtonWithOptionsOption } from "../general/ButtonTwoOptions";


function ShopGenerator() {
    const MAX_PINS = 6;
    const dispatch = useAppDispatch();
    const {
        selectedTier,
        quantity,
        shopList,
        shopRoster,
        selectedShop
    } = useAppSelector((state) => {
        return {
            selectedTier: state.shop.selectedTier,
            quantity: state.shop.quantity,
            shopList: state.shop.shopList,
            shopRoster: state.shop.shopRoster,
            selectedShop: state.shop.selectedShop
        }
    });


    const pins: GmPinBoardItem<ShopDataType>[] = shopRoster.map((shop) => {
        return new GmPinBoardItem(`${shop.name} - ${shop.type}`, shop);
    });


    const removePinClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
        dispatch(removeShop(shopRoster[index]));
    }
    const selectPinClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
        dispatch(setSelectedShop(shopRoster[index]));
    }
    const pinShop = (event: React.MouseEvent<HTMLElement>) => {
        dispatch(addShop(selectedShop));
    }



    const handleRowClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
        // console.log(`handleRowClick row = ${index}`);
        const newSelectedShop = { ...shopList[index] };
        dispatch(setSelectedShop(newSelectedShop));
    }
    const headerValues = ["Name", "Tier / Type", "Known For"];
    const renderedShopsMobile = shopList.map((shop, index) => {
        return (
            <tr
                key={index}
                className="border-y-2 border-black cursor-pointer"
                onClick={(e) => handleRowClick(e, index)}
            >
                <td className="pl-4 text-sm">{shop.name}</td>
                <td className="pl-4 text-sm">{`${shop.tier} / ${shop.type}`}</td>
                {/* <td className="pl-4 text-sm">{shop.tier}</td> */}
                <td className="pl-4 text-sm">{shop.knownFor}</td>
                {/* <td className="pl-4 text-sm">{shop.customer}</td> */}
            </tr>
        );
    });
    const renderedShopsTableMobile = shopList.length === 0
        ? null
        : <GmDisplayTable
            id="Shop List"
            heading="SHOPS (Click for details.)"
            headerCells={headerValues}
        >
            {renderedShopsMobile}
        </GmDisplayTable>


    function copyShop(event: React.MouseEvent<HTMLElement>): void {
        const junko = document.getElementById(selectedShop.name)?.innerText;
        // console.log(`JUNKO = ${junko}`);
        copyTextToClipboard(junko as string);
    }

    const functionButtons = (pins.length >= MAX_PINS)
        ? <div className="flex flex-row items-center">
            <Button subtle lefttoright
                className='w-full justify-center'
                onClick={copyShop}
            >
                <><IoCopy />&nbsp;Copy</>
            </Button>
        </div>
        : <div className="flex flex-row items-center">
            <Button subtle left
                className='w-1/2 justify-center'
                onClick={copyShop}
            >
                <><IoCopy />&nbsp;Copy</>
            </Button>
            <Button subtle right
                className='w-1/2 justify-center'
                onClick={pinShop}
            >
                <>Pin&nbsp;<BsPinAngleFill /></>
            </Button>
        </div>

    const renderedSelectedShop = selectedShop.name === "" ?
        null :
        <div className="grow pt-3">
            {functionButtons}
            <GmDisplayPanel
                heading={selectedShop.name}
                subheading={`${selectedShop.type}`}
            >
                <ul className="body-text text-left list-inside list-disc">
                    <li><b>Tier:</b> {selectedShop.tier}</li>
                    <li><b>Known for:</b> {selectedShop.knownFor}</li>
                    <li><b>Customer:</b> {selectedShop.customer}</li>
                </ul>
            </GmDisplayPanel>
        </div>;






    const selectTierOptions = [
        { "label": "Poor", "value": "Poor" },
        { "label": "Standard", "value": "Standard" },
        { "label": "Wealthy", "value": "Wealthy" },
        { "label": "Rand. Tier", "value": "" },
    ];
    const handleShopTierChange = (event: React.FormEvent<HTMLSelectElement>) => {
        // console.log("length selector changed", (event.currentTarget.value));
        const newTier = event.currentTarget.value;
        dispatch(setShopTier(newTier));
    };

    const selectQuantityOptions = [
        { "label": "1", "value": "1" },
        { "label": "2", "value": "2" },
        { "label": "3", "value": "3" },
        { "label": "4", "value": "4" },
        { "label": "5", "value": "5" },
    ];
    const handleShopQuantityChange = (event: React.FormEvent<HTMLSelectElement>) => {
        // console.log("handleShopQuantityChange", (event.currentTarget.value));
        const newQuantity = +(event.currentTarget.value);
        dispatch(setShopQuantity(newQuantity));
    };

    const handleGetShopsClick = (event: React.MouseEvent<HTMLElement>) => {
        // console.log("handleGetShopsClick");
        const newShops = GMTools.getShops(quantity, selectedTier);
        dispatch(setShopList(newShops));
    }









    return (
        <ToolContainer>
            <ToolPanelFull
                title="Shops"
            >
                <div className="flex flex-col w-full pb-2">
                    <ButtonTwoOptions
                        primary taller centerSelectorSmall rightSelectorMedium
                        optionsCenter={selectQuantityOptions as ButtonWithOptionsOption[]}
                        selectedValueCenter={quantity.toString()}
                        onSelectChangeCenter={handleShopQuantityChange}
                        optionsRight={selectTierOptions as ButtonWithOptionsOption[]}
                        selectedValueRight={selectedTier}
                        onSelectChangeRight={handleShopTierChange}
                        onClick={handleGetShopsClick}
                    >
                        <>Get Shops</>
                    </ButtonTwoOptions>
                    <div className="pb-3">
                        <GmPinBoard
                            maxQuantity={MAX_PINS}
                            pinnedItems={pins}
                            pinnedItemName="Shops"
                            removePin={removePinClick}
                            selectPin={selectPinClick}
                        />
                    </div>
                    <ToolPanelFull>
                        {renderedShopsTableMobile}
                    </ToolPanelFull>
                    <ToolPanelFull>
                        {renderedSelectedShop}
                    </ToolPanelFull>
                </div>
            </ToolPanelFull>
        </ToolContainer>
    );

}

export default ShopGenerator;
