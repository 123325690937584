import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit';
import { TrapDataType } from "../../rules/models/TrapData";
import { HazardDataType } from "../../rules/models/HazardData";

const trapsHazardsSlice = createSlice({
    name: 'trapsHazards',
    initialState: {
        traps: [] as TrapDataType[],
        trapQuantity: 3,
        hazards: [] as HazardDataType[],
        hazardQuantity: 3
    },
    reducers: {
        setTraps(state, action: PayloadAction<TrapDataType[]>) {
            state.traps = action.payload;
        },
        setTrapQuantity(state, action: PayloadAction<number>) {
            state.trapQuantity = action.payload;
        },
        setHazards(state, action: PayloadAction<HazardDataType[]>) {
            state.hazards = action.payload;
        },
        setHazardQuantity(state, action: PayloadAction<number>) {
            state.hazardQuantity = action.payload;
        }
    }
});

export const {
    setTraps
    , setTrapQuantity
    , setHazards
    , setHazardQuantity
} = trapsHazardsSlice.actions;
export const trapsHazardsReducer = trapsHazardsSlice.reducer;
