import className from 'classnames';
import React from 'react';
import { MapLocationType } from '../../rules/models/MapData';
import { ImCheckboxUnchecked } from 'react-icons/im';
import { RxDotFilled } from 'react-icons/rx';
import {
    GiWolfTrap,
    GiMonsterGrasp,
    GiPerson,
    GiOpenTreasureChest,
    GiCrownedSkull,
    GiMeepleGroup,
    GiCarnivorousPlant,
    GiWaterFountain
} from 'react-icons/gi';
import {
    BsFillExclamationOctagonFill,
    BsFillExclamationTriangleFill,
    BsPlusLg
} from 'react-icons/bs';
import { TbPlant2 } from "react-icons/tb";
import { GrGroup } from 'react-icons/gr';
import { IconContext } from 'react-icons';
import { HiUserGroup } from "react-icons/hi";

// trap - GiWolfTrap
// empty - ImCheckboxUnchecked
// minor hazard - BsFillExclamationTriangleFill
// solo monster - GiMonsterGrasp
// npc - GiPerson
// monster mob - GrGroup
// major hazard - BsFillExclamationOctagonFill
// treasure - GiOpenTreasureChest
// boss monster - GiCrownedSkull

// Empty - ImCheckboxUnchecked
// Trick/Trap - GiWolfTrap
// Faction - GiMeepleGroup
// NPCs - GiPerson
// Monster - GiMonsterGrasp
// Flora/Fauna - TbPlant2 - GiCarnivorousPlant
// Feature - GiWaterFountain
// Treasure - GiOpenTreasureChest
// Objective - GiCrownedSkull


interface MapLocationComponentProps extends React.HTMLAttributes<HTMLDivElement> {
    mapLocation: MapLocationType;
    children: JSX.Element;
    present?: boolean;
    absent?: boolean;
    selected?: boolean;
}


function MapLocationTile(props: MapLocationComponentProps) {
    const {
        mapLocation,
        children,
        present,
        absent,
        selected,
        ...rest } = props;

    const classes = className(
        rest.className,
        'font-face-montserrat-medium p-2 w-10 h-10 rounded-lg cursor-pointer',
        {
            'bg-black text-white shadow-lg': present || mapLocation.present,
            'bg-gray-200 text-white shadow-sm': absent || !mapLocation.present,
            'shadow-black': selected
        }
    );

    let renderedIcon = <RxDotFilled />
    switch (mapLocation.type.toLowerCase()) {
        case "empty":
            renderedIcon = <ImCheckboxUnchecked />
            break;
        case "trap":
        case "trick/trap":
            renderedIcon = <GiWolfTrap />
            break;
        case "minor hazard":
            renderedIcon = <BsFillExclamationTriangleFill />
            break;
        case "solo monster":
        case "monster":
            renderedIcon = <GiMonsterGrasp />
            break;
        case "flora/fauna":
            renderedIcon = <GiCarnivorousPlant />;
            renderedIcon = <TbPlant2 />;
            break;
        case "feature":
            renderedIcon = <GiWaterFountain />;
            break;
        case "npc":
        case "npcs":
            renderedIcon = <GiPerson />
            break;
        case "monster mob":
        case "faction":
            renderedIcon = <GrGroup />
            renderedIcon = <GiMeepleGroup />
            renderedIcon= <HiUserGroup />
            break;
        case "major hazard":
            renderedIcon = <BsFillExclamationOctagonFill />
            break;
        case "treasure":
            renderedIcon = <GiOpenTreasureChest />
            break;
        case "boss monster":
        case "objective":
            renderedIcon = <GiCrownedSkull />
            break;
        default:
            renderedIcon = <BsPlusLg />;
            break;
    }


    return (
        <div {...rest} className={classes}>
            <IconContext.Provider value={{ size: "1.75em" }}>
                {renderedIcon}
            </IconContext.Provider>
            {/* {children} */}
        </div>
    );

}

export default MapLocationTile;
