import React from "react";
import "./MonsterReference.css";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import ToolContainer from "../gmtools/general/ToolContainer";
import MonsterManual, { MonsterSearchOptions } from "../../tools/MonsterManual";
import ButtonToggle from "../gmtools/general/ButtonToggle";
import {
    setIncludeCSMonsters
    , setMonsterList
    , setLowerLevel
    , setUpperLevel
    , addBiomeTag
    , clearBiomeTags
    , removeBiomeTag
    , addMoveTag
    , removeMoveTag
    , clearMoveTags
    , addAttackTag
    , removeAttackTag
    , clearAttackTags
    , addTypeTag
    , removeTypeTag
    , clearTypeTags
    , setKeywords
} from "../../store";
import Button from "../gmtools/general/Button";
import GmDisplayTable from "../gmtools/general/GmDisplayTable";
import ToolPanelFull from "../gmtools/general/ToolPanelFull";


function MonsterReference(): JSX.Element {
    const dispatch = useAppDispatch();
    const {
        includeCSMonsters,
        monsterList,
        levelLower,
        levelUpper,
        selectedBiomeTags,
        selectedMoveTags,
        selectedAttackTags,
        selectedTypeTags,
        keywords
    } = useAppSelector((state) => {
        return {
            includeCSMonsters: state.monsterReference.includeCSMonsters,
            monsterList: state.monsterReference.monsterList,
            levelLower: state.monsterReference.levelLower,
            levelUpper: state.monsterReference.levelUpper,
            selectedBiomeTags: state.monsterReference.selectedBiomeTags,
            selectedMoveTags: state.monsterReference.selectedMoveTags,
            selectedAttackTags: state.monsterReference.selectedAttackTags,
            selectedTypeTags: state.monsterReference.selectedTypeTags,
            keywords: state.monsterReference.keywords
        }
    });


    const handleIncludeCSMonstersClick = (event: React.MouseEvent<HTMLElement>) => {
        dispatch(setIncludeCSMonsters(!includeCSMonsters));
    }


    const selectMonsterLevelOptions = [
        { "label": "ignore", "value": -1 },
        { "label": "0", "value": 0 },
        { "label": "1", "value": 1 },
        { "label": "2", "value": 2 },
        { "label": "3", "value": 3 },
        { "label": "4", "value": 4 },
        { "label": "5", "value": 5 },
        { "label": "6", "value": 6 },
        { "label": "7", "value": 7 },
        { "label": "8", "value": 8 },
        { "label": "9", "value": 9 },
        { "label": "10", "value": 10 },
        { "label": "11", "value": 11 },
        { "label": "12", "value": 12 },
        { "label": "13", "value": 13 },
        { "label": "14", "value": 14 },
        { "label": "15", "value": 15 },
        { "label": "16", "value": 16 },
        { "label": "17", "value": 17 },
        { "label": "18", "value": 18 },
        { "label": "19", "value": 19 },
        { "label": "30", "value": 30 },
    ];
    const renderedMonsterLevelSelectOptions = selectMonsterLevelOptions.map((option) => {
        return (
            <option
                key={option.label}
                value={option.value}
            >{option.label}</option>
        );
    });
    const handleLowerLevelChange = (event: React.FormEvent<HTMLSelectElement>) => {
        // console.log("handleLowerLevelChange", (event.currentTarget.value));
        const newLevel = +(event.currentTarget.value);
        dispatch(setLowerLevel(newLevel));
    };
    const handleUpperLevelChange = (event: React.FormEvent<HTMLSelectElement>) => {
        // console.log("handleLowerLevelChange", (event.currentTarget.value));
        const newLevel = +(event.currentTarget.value);
        dispatch(setUpperLevel(newLevel));
    };






    // console.log(`MonsterReference selectedMoveTags = ${selectedMoveTags}`);

    // const testSearchOptions = new MonsterSearchOptions();
    // testSearchOptions.levelLower = 20;
    // // testSearchOptions.levelUpper = 20;
    // const test = MonsterManual.getMonsters(testSearchOptions);
    // console.log(`test length: ${test.length}`);

    // DEBUG
    // console.log(`DEBUG monsterList.length = ${monsterList.length}`);
    // const debugNames = monsterList.map((m) => {
    //     return m.name;
    // });
    // console.log(`DEBUG debugNames ${debugNames}`);


    const biomeTypes = MonsterManual.getAllBiomeTags();
    const handleBiomeTypesClick = (event: React.MouseEvent<HTMLElement>, biomeName: string) => {
        // console.log(biomeName);
        if (selectedBiomeTags.indexOf(biomeName) > -1) {
            dispatch(removeBiomeTag(biomeName));
        }
        else {
            dispatch(addBiomeTag(biomeName));
        }
    };
    const biomeButtons = biomeTypes.map((b) => {
        return (
            <ButtonToggle
                key={b}
                className="mr-2 mb-2"
                toggleValue={selectedBiomeTags.indexOf(b) > -1}
                subtle solo
                onClick={((e) => handleBiomeTypesClick(e, b))}
            >
                <>{b}</>
            </ButtonToggle>
        );
    });


    const moveTypes = MonsterManual.getAllMoveTags();
    const handleMoveTypesClick = (event: React.MouseEvent<HTMLElement>, moveName: string) => {
        // console.log(moveName);
        if (selectedMoveTags.indexOf(moveName) > -1) {
            dispatch(removeMoveTag(moveName));
        }
        else {
            dispatch(addMoveTag(moveName));
        }
    };
    const moveButtons = moveTypes.map((m) => {
        return (
            <ButtonToggle
                key={m}
                className="mr-2 mb-2"
                toggleValue={selectedMoveTags.indexOf(m) > -1}
                subtle solo
                onClick={((e) => handleMoveTypesClick(e, m))}
            >
                <>{m}</>
            </ButtonToggle>
        );
    });


    const attackTypes = MonsterManual.getAllAttackTags();
    const handleAttackTypesClick = (event: React.MouseEvent<HTMLElement>, attackName: string) => {
        // console.log(attackName);
        if (selectedAttackTags.indexOf(attackName) > -1) {
            dispatch(removeAttackTag(attackName));
        }
        else {
            dispatch(addAttackTag(attackName));
        }
    };
    const attackButtons = attackTypes.map((a) => {
        return (
            <ButtonToggle
                key={a}
                className="mr-2 mb-2"
                toggleValue={selectedAttackTags.indexOf(a) > -1}
                subtle solo
                onClick={((e) => handleAttackTypesClick(e, a))}
            >
                <>{a}</>
            </ButtonToggle>
        );
    });


    const tagTypes = MonsterManual.getAllTypeTags();
    const handleTypeTagClick = (event: React.MouseEvent<HTMLElement>, typeName: string) => {
        // console.log(typeName);
        if (selectedTypeTags.indexOf(typeName) > -1) {
            dispatch(removeTypeTag(typeName));
        }
        else {
            dispatch(addTypeTag(typeName));
        }
    };
    const typeButtons = tagTypes.map((t) => {
        return (
            <ButtonToggle
                key={t}
                className="mr-2 mb-2"
                toggleValue={selectedTypeTags.indexOf(t) > -1}
                subtle solo
                onClick={((e) => handleTypeTagClick(e, t))}
            >
                <>{t}</>
            </ButtonToggle>
        );
    });




    const filterMonstersClick = (event: React.MouseEvent<HTMLElement>) => {
        // console.log("filterMonstersClick");
        const searchOptions = new MonsterSearchOptions();
        searchOptions.includeCSMonsters = includeCSMonsters;
        searchOptions.levelLower = levelLower;
        searchOptions.levelUpper = levelUpper;
        searchOptions.biomeTags = selectedBiomeTags;
        searchOptions.moveTags = selectedMoveTags;
        searchOptions.attackTags = selectedAttackTags;
        searchOptions.typeTags = selectedTypeTags;
        searchOptions.keywords = keywords;

        const searchResult = MonsterManual.searchMonsters(searchOptions);

        dispatch(setMonsterList(searchResult));
    };

    const singleMonstersClick = (event: React.MouseEvent<HTMLElement>) => {
        // console.log("filterMonstersClick");
        const searchOptions = new MonsterSearchOptions();
        searchOptions.includeCSMonsters = includeCSMonsters;
        searchOptions.levelLower = levelLower;
        searchOptions.levelUpper = levelUpper;
        searchOptions.biomeTags = selectedBiomeTags;
        searchOptions.moveTags = selectedMoveTags;
        searchOptions.attackTags = selectedAttackTags;
        searchOptions.typeTags = selectedTypeTags;
        searchOptions.keywords = keywords;

        const searchResult = MonsterManual.searchForNMonsters(searchOptions, 1);

        dispatch(setMonsterList(searchResult));
    };

    const clearFiltersClick = (event: React.MouseEvent<HTMLElement>) => {
        // console.log("filterMonstersClick");
        dispatch(setLowerLevel(-1));
        dispatch(setUpperLevel(-1));
        dispatch(setKeywords(''));
        dispatch(clearBiomeTags());
        dispatch(clearAttackTags());
        dispatch(clearMoveTags());
        dispatch(clearTypeTags());
        dispatch(setIncludeCSMonsters(false));
    };


    const monsterListHeaders = ["Name", "Page", "LV", "Stat Block"];
    const renderedMonsters = monsterList.map((monster, index) => {
        return (
            <tr
                key={index}
                className="border-y-2 border-black"
            >
                <td className="pl-4 text-sm">{monster.name}</td>
                <td className="pl-4 text-sm">{`${(parseInt(monster.page) ? "p" : "")}${monster.page}`}</td>
                <td className="pl-4 text-sm">{monster.level}</td>
                <td className="pl-4 text-sm">{monster.statblock}</td>
            </tr>
        );
    });

    // const renderedMonsterTable = (monsterList.length === 0)
    //     ? null
    //     : <GmDisplayTable
    //         id="Monster List"
    //         heading={`MONSTERS (${monsterList.length})`}
    //         headerCells={monsterListHeaders}
    //     >
    //         {renderedMonsters}
    //     </GmDisplayTable>

    const renderedMonsterTable = <GmDisplayTable
        id="Monster List"
        heading={`MONSTERS (${monsterList.length})`}
        headerCells={monsterListHeaders}
    >
        {renderedMonsters}
    </GmDisplayTable>


    const handleKeywordsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // console.log(`Keywords.handleChange fired: event.target.value = ${event.target.value}`);
        // setName(event.target.value);
        // console.log(`CharacterName.handleChange fired: name = ${name}`);
        // updateName(event.target.value);
        dispatch(setKeywords(event.target.value));
    };




    return (
        <ToolContainer>
            <ToolPanelFull>
                <div>
                    <h2>MONSTER FILTER</h2>
                    <div>
                        <ButtonToggle
                            toggleValue={includeCSMonsters}
                            subtle solo
                            onClick={((e) => handleIncludeCSMonstersClick(e))}
                            className="mb-2"
                        >
                            <>Include Cursed Scroll Monsters</>
                        </ButtonToggle>
                    </div>
                    <div className="mb-2">
                        <label
                            htmlFor="movement tags"
                            className="tool-input-label">Monster Level from
                        </label>
                        <select
                            id="encounter table die"
                            name="encounter table die"
                            value={levelLower}
                            className="encounters-die-selector"
                            onChange={handleLowerLevelChange}
                        >
                            {renderedMonsterLevelSelectOptions}
                        </select>
                        <label
                            htmlFor="movement tags"
                            className="tool-input-label">&nbsp;&nbsp;to
                        </label>
                        <select
                            id="encounter table die"
                            name="encounter table die"
                            value={levelUpper}
                            className="encounters-die-selector"
                            onChange={handleUpperLevelChange}
                        >
                            {renderedMonsterLevelSelectOptions}
                        </select>
                    </div>
                    <div className="mb-2">
                        <label
                            htmlFor="keywords"
                            className="tool-input-label">Keywords
                        </label>
                        <input
                            className="keywords-input"
                            placeholder="space separated keywords"
                            value={keywords}
                            onChange={handleKeywordsChange}
                        />
                    </div>
                    <div>
                        <label
                            htmlFor="movement tags"
                            className="tool-input-label">Environment
                        </label>
                        <div id="movement tags" className="flex flex-row flex-wrap">
                            {biomeButtons}
                        </div>
                    </div>
                    <div className="mb-2">
                        Filtered monsters will
                        <ul className="body-text list-inside list-disc">
                            <li>be within the level range, if specified,</li>
                            <li>contain <u>one of the keywords</u>,</li>
                            <li>and be tagged with <u>one of the environments</u> selected.</li>
                        </ul>
                        They will also have <u>at least one</u> of any selected options below.
                    </div>

                    <label
                        htmlFor="movement tags"
                        className="tool-input-label">Type
                    </label>
                    <div id="movement tags" className="flex flex-row flex-wrap">
                        {typeButtons}
                    </div>

                    <label
                        htmlFor="movement tags"
                        className="tool-input-label">Movement
                    </label>
                    <div id="movement tags" className="flex flex-row flex-wrap">
                        {moveButtons}
                    </div>

                    <label
                        htmlFor="attack tags"
                        className="tool-input-label">Attack
                    </label>
                    <div id="attack tags" className="flex flex-row flex-wrap">
                        {attackButtons}
                    </div>

                    <div className="flex flex-row">
                        <Button
                            className="mt-2 mr-2"
                            primary
                            solo
                            onClick={filterMonstersClick}
                        >
                            <>Filter Monsters</>
                        </Button>
                        <Button
                            className="mt-2 mr-6"
                            primary
                            solo
                            onClick={singleMonstersClick}
                        >
                            <>One Filtered Monster</>
                        </Button>
                        <Button
                            className="mt-2"
                            secondary
                            solo
                            onClick={clearFiltersClick}
                        >
                            <>Clear Filters</>
                        </Button>
                    </div>

                    <div>
                        {renderedMonsterTable}
                    </div>
                </div>
            </ToolPanelFull>
        </ToolContainer>
    );
}


export default MonsterReference;
