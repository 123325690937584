import "./GMToolsPage.css";
import RandomEncounters from "../components/gmtools/RandomEncounters";
import "./Page.css";
import React from "react";
import AndMore from "../components/gmtools/AndMore";
import NpcNames from "../components/gmtools/NpcNames";
import Npc from "../components/gmtools/Npc";
import TavernGenerator from "../components/gmtools/TavernGenerator";
import ShopGenerator from "../components/gmtools/ShopGenerator";
import MagicItemGenerator from "../components/gmtools/MagicItemGenerator";
import RivalCrawlers from "../components/gmtools/RivalCrawlers";


function GMToolsPageFull() {

    return (
        <div>
            <RandomEncounters />
            <NpcNames />
            <Npc />
            {/* <TrapsHazards /> */}
            <RivalCrawlers />
            <ShopGenerator />
            <TavernGenerator />
            <MagicItemGenerator />
            <AndMore />
        </div>
    );

}

export default GMToolsPageFull;
