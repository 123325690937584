import "../RivalCrawlers.css";
import React from "react";
import { IoCopy } from "react-icons/io5";
import GMTools from "../../../tools/GMTools";
import GmDisplayTable from "../general/GmDisplayTable";
import GmDisplayPanel from "../general/GmDisplayPanel";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
    setRivalParty
    , setRivalPartySize
} from "../../../store";
import ButtonWithOptions, { ButtonWithOptionsOption } from "../general/ButtonWithOptions";
import Button from "../general/Button";
import { copyTextToClipboard } from "../../../tools/CopyTextToClipboard";
import ToolContainer from "../general/ToolContainer";
import ToolPanelFull from "../general/ToolPanelFull";


function RivalCrawlersMobile() {
    const dispatch = useAppDispatch();
    const {
        rivalParty
        , rivalPartySize
    } = useAppSelector((state) => {
        return {
            rivalParty: state.rivalCrawlers.rivalParty,
            rivalPartySize: state.rivalCrawlers.rivalPartySize
        }
    });

    const selectSizeOptions = [
        { "label": "Random Size", "value": "0" },
        { "label": "2 crawlers",  "value": "2" },
        { "label": "3 crawlers",  "value": "3" },
        { "label": "4 crawlers",  "value": "4" },
        { "label": "5 crawlers",  "value": "5" },
    ];
    const handleSizeChange = (event: React.FormEvent<HTMLSelectElement>) => {
        // console.log("quantity selector changed", (event.currentTarget.value));
        const newQuantity = +(event.currentTarget.value);
        dispatch(setRivalPartySize(newQuantity));
    };
    const handleGetRivalsClick = (event: React.MouseEvent<HTMLElement>) => {
        // console.log("handleGetRivalsClick");
        const newRivals = GMTools.getRivalCrawlerParty(rivalPartySize);
        // console.log(`newRivals ${JSON.stringify(newRivals, undefined, 3)}`);
        dispatch(setRivalParty(newRivals));
    }


    const headerValues = ["Name", "Ancestry", "Class"];
    const renderedCrawlers = rivalParty.members.map((crawler, index) => {
        return (
            <tr key={index} className="border-y-2 border-black">
                <td className="pl-4">{crawler.name}</td>
                <td className="pl-4">{crawler.ancestry}</td>
                <td className="pl-4">Lv. {crawler.level} {crawler.class}</td>
            </tr>
        );
    }); const renderedCrawlersTable = rivalParty.size === 0
        ? null
        : <GmDisplayTable
            id="RivalMembers"
            heading="MEMBERS"
            headerCells={headerValues}
        >
            {renderedCrawlers}
        </GmDisplayTable>



    function copyNpc(event: React.MouseEvent<HTMLElement>): void {
        const junko = document.getElementById("rivals")?.innerText;
        // console.log(`JUNKO = ${junko}`);
        copyTextToClipboard(junko as string);
    };
    const functionButtons = (rivalParty.name === "")
        ? null
        : <div className="flex flex-row items-center">
            <Button subtle lefttoright
                className='w-full justify-center'
                onClick={copyNpc}
            >
                <><IoCopy />&nbsp;Copy</>
            </Button>
        </div>;

    const renderedCrawlerParty = (rivalParty.name === "")
        ? null
        : <>
            <div id="rivals" className="mb-2">
                <GmDisplayPanel
                    heading={rivalParty.name}
                    subheading={rivalParty.alignment}
                >
                    <ul className="body-text text-left pl-8 list-inside list-disc">
                        <li><b>Renown:</b> {rivalParty.renown}</li>
                        <li><b>Known for:</b> {rivalParty.knownFor}</li>
                        <li><b>Tactic:</b> {rivalParty.tactic}</li>
                        <li><b>Wealth:</b> {rivalParty.wealth}</li>
                        <li><b>Secret:</b> {rivalParty.secret}</li>
                    </ul>
                    {renderedCrawlersTable}
                </GmDisplayPanel>
            </div>
        </>;






    return (
        <ToolContainer>
            <ToolPanelFull
                title="Rival Crawlers"
            >
                <div className="flex flex-col w-full">
                    <ButtonWithOptions
                        primary taller selectorLarge
                        options={selectSizeOptions as ButtonWithOptionsOption[]}
                        selectedValue={rivalPartySize.toString()}
                        onSelectChange={handleSizeChange}
                        onClick={handleGetRivalsClick}
                    >
                        <>Get Rivals!</>
                    </ButtonWithOptions>
                    {functionButtons}
                    <ToolPanelFull>
                        {renderedCrawlerParty}
                    </ToolPanelFull>
                </div>
            </ToolPanelFull>
        </ToolContainer>
    );

}

export default RivalCrawlersMobile;
