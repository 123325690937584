import React, { PropsWithChildren } from "react";
import "./ToolContainer.css";

type ToolPanelProps = {
    title?: string;
    reference?: string;
    description?: string;
}

function ToolPanelFull(props: PropsWithChildren<ToolPanelProps>) {
    const {
        title,
        reference,
        description,
        children } = props;

    const renderedTitle = (title === '' || title === undefined)
        ? null
        : <div className="text-center"><h2>{title}</h2></div>

    const renderedReference = (reference === '' || reference === undefined)
        ? null
        : <div className="self-end text-gray-400 text-sm">
            {`(see ${reference})`}
        </div>

    const renderedDescription = (description === '' || reference === undefined)
        ? null
        : <div>
            {description}
        </div>

    return (
        <div className="w-full">
            {renderedTitle}
            {renderedDescription}
            <div className="flex">
                {children}
            </div>
            {renderedReference}
        </div>
    );

}

export default ToolPanelFull;
