import { configureStore } from '@reduxjs/toolkit';
import {
    encounterReducer
    , setEncounter
    , updateEncounter
    , addLogEntry
    , removeLogEntry
    , clearEncounterLog
    , toggleShowLog
    , toggleConfirmLogClear
    , toggleRollEncounterTableDie
    , setEncounterTableDie
} from './slices/encounterSlice';
import {
    npcNamesReducer
    , setNpcNames
    , setNpcNameQuantity
    , setNpcNameLength
    , setNpcAncestryQuantity
    , setNpcAncestry
    , setNamesType
} from './slices/npcNamesSlice';
import {
    npcReducer
    , setNpcList
    , setNpcQuantity
    , setNpcSelectedAncestry
    , addNpc
    , removeNpc
    , setSelectedNpc
} from './slices/npcSlice';
import {
    trapsHazardsReducer
    , setTraps
    , setTrapQuantity
    , setHazards
    , setHazardQuantity
} from './slices/trapsHazardsSlice';
import {
    rivalCrawlersReducer
    , setRivalParty
    , setRivalPartySize
    , removeRivalParty
} from './slices/rivalCrawlersSlice';
import { 
    shopReducer
    , setShopList 
    , addShop
    , removeShop
    , setSelectedShop
    , setShopTier
    , setShopQuantity
} from './slices/shopSlice';
import { 
    tavernReducer
    , setTavernList
    , addTavern
    , removeTavern
    , setSelectedTavern
    , setTavernTier
    , setTavernQuantity 
} from './slices/tavernSlice';
import { 
    adventureIdeasReducer
    , setAdventureIdeas
    , setAdventureQuantity
    , setAdventureSiteQuantity
    , setAdventureIdeasType
} from './slices/adventureIdeasSlice';
import { 
    siteMapReducer
    , setWorkingMap
    , setWorkingMapLocation
    , setSelectedLocation
    , setAllowEmptyLocation
    , setSiteSize
    , setSuggestedDangerLevel
} from './slices/siteMapSlice';
import { 
    magicItemReducer
    , setMagicItemList
    , setMagicItemQuantity
    , setSelectedMagicItemType
    , addMagicItem
    , removeMagicItem
    , setSelectedMagicItem
    , setAllowPriestSpells
} from './slices/magicItemSlice';
import { 
    monsterReferenceReducer 
    , setIncludeCSMonsters
    , setMonsterList
    , setLowerLevel
    , setUpperLevel
    , addBiomeTag
    , removeBiomeTag
    , clearBiomeTags
    , addMoveTag
    , removeMoveTag
    , clearMoveTags
    , addAttackTag
    , removeAttackTag
    , clearAttackTags
    , addTypeTag
    , removeTypeTag
    , clearTypeTags
    , setKeywords

} from './slices/monsterReferenceSlice';


const store = configureStore({
    reducer: {
        encounter: encounterReducer,
        npcNames: npcNamesReducer,
        npc: npcReducer,
        trapsHazards: trapsHazardsReducer,
        rivalCrawlers: rivalCrawlersReducer,
        shop: shopReducer,
        tavern: tavernReducer,
        adventureIdeas: adventureIdeasReducer,
        siteMap: siteMapReducer,
        magicItem: magicItemReducer,
        monsterReference: monsterReferenceReducer
    }
});

export {
    store
    , setEncounter
    , updateEncounter
    , addLogEntry
    , removeLogEntry
    , clearEncounterLog
    , toggleShowLog
    , toggleConfirmLogClear
    , toggleRollEncounterTableDie
    , setEncounterTableDie

    , setNpcNames
    , setNpcNameQuantity
    , setNpcNameLength
    , setNpcAncestryQuantity
    , setNpcAncestry
    , setNamesType

    , setNpcList
    , setNpcQuantity
    , setNpcSelectedAncestry
    , addNpc
    , removeNpc
    , setSelectedNpc

    , setTraps
    , setTrapQuantity
    , setHazards
    , setHazardQuantity

    , setRivalParty
    , setRivalPartySize
    , removeRivalParty

    , setShopList
    , addShop
    , removeShop
    , setSelectedShop
    , setShopTier
    , setShopQuantity

    , setTavernList
    , addTavern
    , removeTavern
    , setSelectedTavern
    , setTavernTier
    , setTavernQuantity 

    , setAdventureIdeas
    , setAdventureQuantity
    , setAdventureSiteQuantity
    , setAdventureIdeasType

    , setWorkingMap
    , setWorkingMapLocation
    , setSelectedLocation
    , setAllowEmptyLocation
    , setSiteSize
    , setSuggestedDangerLevel

    , setMagicItemList
    , setMagicItemQuantity
    , setSelectedMagicItemType
    , addMagicItem
    , removeMagicItem
    , setSelectedMagicItem
    , setAllowPriestSpells

    , setIncludeCSMonsters
    , setMonsterList
    , setLowerLevel
    , setUpperLevel
    , addBiomeTag
    , removeBiomeTag
    , clearBiomeTags
    , addMoveTag
    , removeMoveTag
    , clearMoveTags
    , addAttackTag
    , removeAttackTag
    , clearAttackTags
    , addTypeTag
    , removeTypeTag
    , clearTypeTags
    , setKeywords
};


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
