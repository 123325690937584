import React, { PropsWithChildren } from "react";
import "./ToolContainer.css";

type ToolPanelProps = {
    title: string;
    reference?: string;
    description?: string;
    special?: JSX.Element;
}

function ToolPanelLeft(props: PropsWithChildren<ToolPanelProps>) {
    const {
        title,
        reference,
        description,
        special,
        children } = props;

    const renderedReference = (reference === '' || reference === undefined)
        ? null
        : <div className="text-gray-400 text-sm">
            {reference}
        </div>

    const renderedDescription = (description === '' || description === undefined)
        ? null
        : <div>
            {description}
        </div>

    return (
        <div className="w-1/2 mr-2">
            <h2>{title}</h2>
            {renderedDescription}
            {special}
            {/* <div className="flex flex-row"> */}
                {children}
            {/* </div> */}
            {renderedReference}
        </div>
    );

}

export default ToolPanelLeft;
