import { createSlice, nanoid } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit';
import ShopData, { ShopDataType } from "../../rules/models/ShopData";

const emptyShopDataType = ShopData.getEmptyType();

const shopSlice = createSlice({
    name: 'shops',
    initialState: {
        shopList: [] as ShopDataType[],
        shopRoster: [] as ShopDataType[],
        selectedShop: emptyShopDataType as ShopDataType, 
        selectedTier: "",
        quantity: 3
    },
    reducers: {
        setShopList(state, action: PayloadAction<ShopDataType[]>) {
            state.shopList = action.payload;
        },
        addShop(state, action: PayloadAction<ShopDataType>) {
            const newData = { ...action.payload };
            newData.id = nanoid();
            state.shopRoster.push(newData);
        },
        removeShop(state, action: PayloadAction<ShopDataType>) {
            const updatedData = state.shopRoster.filter((shop) => {
                return shop.id !== action.payload.id;
            });
            state.shopRoster = updatedData;
        },
        setSelectedShop(state, action: PayloadAction<ShopDataType>) {
            state.selectedShop = action.payload;
        },
        setShopTier(state, action: PayloadAction<string>) {
            state.selectedTier = action.payload;
        },
        setShopQuantity(state, action: PayloadAction<number>) {
            state.quantity = action.payload;
        }
    }
});

export const {
    setShopList
    , addShop
    , removeShop
    , setSelectedShop
    , setShopTier
    , setShopQuantity
} = shopSlice.actions;
export const shopReducer = shopSlice.reducer;
