enum MagicItemType {
    Empty = "Empty",
    Armor = "Armor",
    Potion = "Potion",
    Scroll = "Scroll",
    Wand = "Wand",
    Utility = "Utility",
    Weapon = "Weapon"
}

const selectMagicItemOptions = [
    { "label": "Random", "value": "" },
    { "label": "Armor", "value": "Armor" },
    { "label": "Potion", "value": "Potion" },
    { "label": "Scroll", "value": "Scroll" },
    { "label": "Wand", "value": "Wand" },
    { "label": "Utility", "value": "Utility" },
    { "label": "Weapon", "value": "Weapon" }
];

type MagicItemNamedEffect = {
    name: string,
    description: string
}

type MagicItemDataType = {
    id: string,
    itemType: MagicItemType,
    nBenefits: number,
    nCurses: number,
    nVirtues: number,
    nFlaws: number,
    personalityVirtues: MagicItemNamedEffect[],
    personalityFlaws: MagicItemNamedEffect[]
    personalityTrait: string,
    personalityAlignment: string,

    type: string,
    bonus: string,
    feature: string,
    benefits: MagicItemNamedEffect[],
    curses: MagicItemNamedEffect[],

    container: string,
    liquid: string,
    tasteSmell: string,

    spellTier: string,
    spell: string
}

class MagicItemData {
    public id: string = '';
    public itemType: MagicItemType = MagicItemType.Empty;
    public nBenefits: number = 0;
    public nCurses: number = 0;
    public nVirtues: number = 0;
    public nFlaws: number = 0;
    public personalityVirtues: MagicItemNamedEffect[] = [];
    public personalityFlaws: MagicItemNamedEffect[] = [];
    public personalityTrait: string = '';
    public personalityAlignment: string = '';

    public type: string = '';
    public bonus: string = '';
    public feature: string = '';
    public benefits: MagicItemNamedEffect[] = [];
    public curses: MagicItemNamedEffect[] = [];

    public container: string = '';
    public liquid: string = '';
    public tasteSmell: string = '';

    public spellTier: string = '';
    public spell: string = '';

    public getCleanType(): MagicItemDataType {
        const result = {
            id: this.id,
            itemType: this.itemType,
            nBenefits: this.nBenefits,
            nCurses: this.nCurses,
            nVirtues: this.nVirtues,
            nFlaws: this.nFlaws,
            personalityVirtues: this.personalityVirtues,
            personalityFlaws: this.personalityFlaws,
            personalityTrait: this.personalityTrait,
            personalityAlignment: this.personalityAlignment,
            type: this.type,
            bonus: this.bonus,
            feature: this.feature,
            benefits: this.benefits,
            curses: this.curses,
            container: this.container,
            liquid: this.liquid,
            tasteSmell: this.tasteSmell,
            spellTier: this.spellTier,
            spell: this.spell,
        }
        return result;
    }

    public static getEmptyType(): MagicItemDataType {
        const result = {
            id: '',
            itemType: MagicItemType.Empty,
            nBenefits: 0,
            nCurses: 0,
            nVirtues: 0,
            nFlaws: 0,
            personalityVirtues: [],
            personalityFlaws: [],
            personalityTrait: '',
            personalityAlignment: '',
            type: '',
            bonus: '',
            feature: '',
            benefits: [],
            curses: [],
            container: '',
            liquid: '',
            tasteSmell: '',
            spellTier: '',
            spell: '',
        }
        return result;
    }

    public static getNamedEffect(name: string, description: string): MagicItemNamedEffect {
        const result = {
            name: name,
            description: description
        };
        return result;
    }
}


class MagicItemDisplay {
    private magicItem: MagicItemDataType = MagicItemData.getEmptyType();

    constructor(magicItem: MagicItemDataType) {
        this.magicItem = magicItem;
    }

    public description(): string {
        let result = '';
        switch (this.magicItem.itemType) {
            case MagicItemType.Armor:
            case MagicItemType.Weapon:
                result = `${this.magicItem.bonus} ${this.magicItem.type}`;
                break;

            case MagicItemType.Potion:
                result = `Potion`;
                break;

            case MagicItemType.Utility:
                result = `${this.magicItem.type}`;
                break;

            case MagicItemType.Scroll:
            case MagicItemType.Wand:
                result = `${this.magicItem.itemType} of ${this.magicItem.spell}`;
                break;

            default:
                return this.magicItem.id;
        }
        return result;
    }

    public feature(): string {
        let result = '';
        switch (this.magicItem.itemType) {
            case MagicItemType.Armor:
            case MagicItemType.Weapon:
            case MagicItemType.Utility:
            case MagicItemType.Scroll:
            case MagicItemType.Wand:
                result = this.magicItem.feature;
                break;

            case MagicItemType.Potion:
                result = `${this.magicItem.container}`;
                break;
            default:
                return "this isn't right";
        }
        return result;
    }


    public subheading(): string {
        let result = '';
        switch (this.magicItem.itemType) {
            case MagicItemType.Armor:
            case MagicItemType.Weapon:
                // result = `${this.magicItem.itemType} - ${this.magicItem.feature}`;
                result = this.magicItem.feature;
                break;

            case MagicItemType.Potion:
                result = `${this.magicItem.itemType} - ${this.magicItem.container}`;
                break;
            case MagicItemType.Utility:
            case MagicItemType.Scroll:
            case MagicItemType.Wand:
                result = `${this.magicItem.feature}`;
                break;
            default:
                return "this isn't right";
        }
        return result;
    }

    public benefitNames(): string {
        const benefitNames = this.magicItem.benefits.map((item) => { return item.name });
        const result = benefitNames.join("; ");
        return result;
    }
    public curseNames(): string {
        const curseNames = this.magicItem.curses.map((item) => { return item.name });
        const result = curseNames.join("; ");
        return result;
    }

    public personalitySummary(): string {
        let alignmentInitial = '-';
        if (this.magicItem.personalityAlignment.length > 0) {
            alignmentInitial = this.magicItem.personalityAlignment.charAt(0);
        }
        const result = `${this.magicItem.nVirtues}/${this.magicItem.nFlaws}/${alignmentInitial}`;
        return result;
    }

    public benfitsCursesList(): MagicItemNamedEffect[] {
        const result = this.magicItem.benefits.concat(this.magicItem.curses);
        return result;
    }

    public personalityList(): MagicItemNamedEffect[] {
        let result: MagicItemNamedEffect[] = [];
        if (this.magicItem.nVirtues + this.magicItem.nFlaws > 0) {
            const alignmentEffect = MagicItemData.getNamedEffect("Alignment", this.magicItem.personalityAlignment);
            const traitEffect = MagicItemData.getNamedEffect("Trait", this.magicItem.personalityTrait);

            result.push(alignmentEffect);
            result.push(traitEffect);
            result = result.concat(this.magicItem.personalityVirtues);
            result = result.concat(this.magicItem.personalityFlaws);
        }
        return result;
    }

    public featureList(): MagicItemNamedEffect[] {
        let result: MagicItemNamedEffect[] = [];
        if (this.magicItem.itemType === MagicItemType.Potion) {
            const containerEffect = MagicItemData.getNamedEffect("Container", this.magicItem.container);
            const liquidEffect = MagicItemData.getNamedEffect("Liquid", this.magicItem.liquid);
            const smellEffect = MagicItemData.getNamedEffect("Smell/Taste", this.magicItem.tasteSmell);

            result.push(containerEffect);
            result.push(liquidEffect);
            result.push(smellEffect);
        }
        return result;
    }

}


export default MagicItemData;
export { MagicItemType, selectMagicItemOptions, MagicItemDataType, MagicItemNamedEffect, MagicItemDisplay }

// Magic Item
// 	Item Type (Armor, Potion, Scroll, Wand, Utility, Weapon)
// 	nBenefits
// 	nCurses
// 	nVirtues
// 	nFlaws
// 	Virtues
// 	Flaws
// 	Trait

// 	Type
// 	Bonus
// 	Feature
// 	Benefits
// 	Curses

// 	Container
// 	Liquid
// 	SmellTaste

// 	Spell Tier
// 	Spell
