import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit';
import { MonsterDataType } from "../../rules/models/MonsterData";


const npcSlice = createSlice({
    name: 'monsterReference',
    initialState: {
        includeCSMonsters: false,
        monsterList: [] as MonsterDataType[],
        levelLower: -1,
        levelUpper: -1,
        selectedBiomeTags: [] as string[],
        selectedMoveTags: [] as string[],
        selectedAttackTags: [] as string[],
        selectedTypeTags: [] as string[],
        keywords: ''
    },
    reducers: {
        setIncludeCSMonsters(state, action: PayloadAction<boolean>) {
            state.includeCSMonsters = action.payload;
        },

        setMonsterList(state, action: PayloadAction<MonsterDataType[]>) {
            state.monsterList = action.payload;
        },

        setLowerLevel(state, action: PayloadAction<number>) {
            state.levelLower = action.payload;
        },
        setUpperLevel(state, action: PayloadAction<number>) {
            state.levelUpper = action.payload;
        },

        addBiomeTag(state, action: PayloadAction<string>) {
            if (state.selectedBiomeTags.indexOf(action.payload)) {
                state.selectedBiomeTags.push(action.payload);
            }
        },
        removeBiomeTag(state, action: PayloadAction<string>) {
            const updatedData = state.selectedBiomeTags.filter((t) => {
                return t !== action.payload;
            });
            state.selectedBiomeTags = updatedData;
        },
        clearBiomeTags(state, action: PayloadAction<void>) {
            state.selectedBiomeTags = [];
        },
        
        addMoveTag(state, action: PayloadAction<string>) {
            if (state.selectedMoveTags.indexOf(action.payload)) {
                state.selectedMoveTags.push(action.payload);
            }
        },
        removeMoveTag(state, action: PayloadAction<string>) {
            const updatedData = state.selectedMoveTags.filter((t) => {
                return t !== action.payload;
            });
            state.selectedMoveTags = updatedData;
        },
        clearMoveTags(state, action: PayloadAction<void>) {
            state.selectedMoveTags = [];
        },

        addAttackTag(state, action: PayloadAction<string>) {
            if (state.selectedAttackTags.indexOf(action.payload)) {
                state.selectedAttackTags.push(action.payload);
            }
        },
        removeAttackTag(state, action: PayloadAction<string>) {
            const updatedData = state.selectedAttackTags.filter((t) => {
                return t !== action.payload;
            });
            state.selectedAttackTags = updatedData;
        },
        clearAttackTags(state, action: PayloadAction<void>) {
            state.selectedAttackTags = [];
        },


        addTypeTag(state, action: PayloadAction<string>) {
            if (state.selectedTypeTags.indexOf(action.payload)) {
                state.selectedTypeTags.push(action.payload);
            }
        },
        removeTypeTag(state, action: PayloadAction<string>) {
            const updatedData = state.selectedTypeTags.filter((t) => {
                return t !== action.payload;
            });
            state.selectedTypeTags = updatedData;
        },
        clearTypeTags(state, action: PayloadAction<void>) {
            state.selectedTypeTags = [];
        },

        setKeywords(state, action: PayloadAction<string>) {
            state.keywords = action.payload;
        }
    },
});

export const {
    setIncludeCSMonsters

    , setMonsterList
    , setLowerLevel
    , setUpperLevel

    , addBiomeTag
    , removeBiomeTag
    , clearBiomeTags

    , addMoveTag
    , removeMoveTag
    , clearMoveTags

    , addAttackTag
    , removeAttackTag
    , clearAttackTags

    , addTypeTag
    , removeTypeTag
    , clearTypeTags

    , setKeywords

} = npcSlice.actions;
export const monsterReferenceReducer = npcSlice.reducer;
