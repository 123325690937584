type NpcDataType = {
    id: string;
    name: string;
    ancestry: string;
    ancestryName: string;
    age: string;
    alignment: string;
    wealth: string;
    job: string;
    appearance: string;
    behavior: string;
    secret: string;
}

class NpcData {
    public id: string = "";
    public name: string = "";
    public ancestry: string = "";
    public ancestryName: string = "";
    public age: string = "";
    public alignment: string = "";
    public wealth: string = "";
    public job: string = "";
    public appearance: string = "";
    public behavior: string = "";
    public secret: string = "";

    public getCleanType(): NpcDataType {
        const result = {
            id: this.id,
            name: this.name,
            ancestry: this.ancestry,
            ancestryName: this.ancestryName,
            age: this.age,
            alignment: this.alignment,
            wealth: this.wealth,
            job: this.job,
            appearance: this.appearance,
            behavior: this.behavior,
            secret: this.secret
        };
        return result;
    }

    public static getEmptyNpcDataType(): NpcDataType {
        const result = {
            id: '',
            name: '',
            ancestry: '',
            ancestryName: '',
            age: '',
            alignment: '',
            wealth: '',
            job: '',
            appearance: '',
            behavior: '',
            secret: ''
        };
        return result;
    }

}

export { NpcDataType };
export default NpcData; 
