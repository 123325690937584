import { ITableEntry, ITwoFactorTable, RollType } from "../rules/interfaces/ITable";
import Dice from "./Dice";

class TwoFactorTableManager<ENTRY extends ITableEntry> {

    private table: ITwoFactorTable<ENTRY>;

    constructor(table: ITwoFactorTable<ENTRY>) {
        this.table = table;
    }

    public getEntries(factor: string): ENTRY[] {
        const lcaseFactor = factor.toLowerCase();
        return this.table[lcaseFactor].entries;
    }

    private getIndexResult(factor: string, suppliedIndex?: number): ENTRY {
        // private getIndexResult(table: ITable<ENTRY>): ENTRY | null {
        // console.log(`*** getIndexResult `);
        const lcaseFactor = factor.toLowerCase();

        // let randomIndex = Dice.dCustom(0, this.table[factor].dieType - 1);
        let randomIndex = Dice.roll(this.table[lcaseFactor].dieType) - 1;
        if (typeof suppliedIndex !== 'undefined') {
            randomIndex = suppliedIndex;
        }

        if (randomIndex >= 0 && randomIndex <= this.table[lcaseFactor].entries.length - 1) {
            const result = this.table[lcaseFactor].entries[randomIndex];
            // console.log(`*** *** getIndexResult - returning ${JSON.stringify(this.table[factor].entries[randomIndex], undefined, 2)}`);
            return result;
        }
        // TODO: how to handle errors?
        // console.log(`*** *** getIndexResult - randomIndex ${randomIndex} matches no entries.`);
        throw Error(`TwoFactorTableManager.getIndexResult - roll ${randomIndex} matches no entries.`);
    }

    private getRangeResult(factor: string, suppliedRoll?: number): ENTRY {
        // private getRangeResult(table: ITable<ENTRY>): ENTRY | null {
        // console.log(`*** getRangeResult FACTOR=${factor}, suppliedRoll=${suppliedRoll}`);
        const lcaseFactor = factor.toLowerCase();

        // let roll = Dice.dCustom(1, this.table[factor].dieType);
        let roll = Dice.roll(this.table[lcaseFactor].dieType);
        if (typeof suppliedRoll !== 'undefined') {
            roll = suppliedRoll;
        }

        const result = this.table[lcaseFactor].entries.filter(e => (roll >= e.minRange) && (roll <= e.maxRange));
        if (result.length >= 1) {
            return result[0];
        }

        // TODO: how to handle errors?
        // console.log(`*** *** - roll ${roll} matches no entries.`);
        throw Error(`TwoFactorTableManager.getRangeResult - roll ${roll} matches no entries.`);
    }

    public getRandomResult(factor: string): ENTRY {
        const lcaseFactor = factor.toLowerCase();
        // public getRandomResult(table: ITable<ENTRY>): ENTRY | null {
        // console.log(`*** getRandomResult called on table ${this.table[lcaseFactor].name}`);
        // console.log(`*** getRandomResult called on table [${lcaseFactor}]`);
        // console.log(`*** *** rollType ${this.table[lcaseFactor].rollType}`);
        // console.log(`*** *** num entries ${this.table[lcaseFactor].entries.length}`);

        switch (this.table[lcaseFactor].rollType) {
            case RollType.Index:
                return this.getIndexResult(lcaseFactor);
            case RollType.Range:
                return this.getRangeResult(lcaseFactor);
            default:
                // perhaps should throw an error here.
                throw Error(`TwoFactorTableManager.getRandomResult: unknown rollType ${this.table.rollType}`);

        }
    }

    public getResult(factor: string, requestedValue: number): ENTRY {
        // console.log(`*** getResult called on table ${this.table[factor].name}`);
        // console.log(`*** *** getResult requestedValue ${requestedValue}`);
        // console.log(`*** *** getResult num entries ${this.table[factor].entries.length}`);
        const lcaseFactor = factor.toLowerCase();

        switch (this.table[lcaseFactor].rollType) {
            case RollType.Index:
                return this.getIndexResult(lcaseFactor, requestedValue - 1);
            case RollType.Range:
                return this.getRangeResult(lcaseFactor, requestedValue);
            default:
                // perhaps should throw an error here.
                throw Error(`TwoFactorTableManager.getResult: unknown rollType ${this.table[lcaseFactor].rollType}`);

        }
    }

    public getEntryByName(factor: string, soughtName: string) {
        // console.log(`*** getEntryByName `);
        const lcaseFactor = factor.toLowerCase();

        const result = this.table[lcaseFactor].entries.filter(e => (soughtName === e.name));
        // console.log(`*** *** result.lenght = ${result.length}`);
        
        if (result.length >= 1) {
            return result[0];
        }
        // TODO: how to handle errors?
        // console.log(`*** *** - name ${soughtName} matches no entries.`);
        return null;
    }

    public getAllEntryNames(factor: string): string[] {
        // console.log(`*** getAllEntryNames `);
        const lcaseFactor = factor.toLowerCase();

        const result = this.table[lcaseFactor].entries.map(({ name }) => name);
        return result;
    }

}

export default TwoFactorTableManager;
