import React, { PropsWithChildren } from "react";
import "./GmDisplayTable.css";


type GmDisplayTableProps = {
    id: string;
    heading: string;
    headerCells: string[];
}



function GmDisplayTable(props: PropsWithChildren<GmDisplayTableProps>) {
    const {
        id,
        heading,
        headerCells,
        children } = props;

    const renderHeaderRow = <tr>{headerCells.map((heading, index) => {
        return (
            <th key={`${index}${heading}`}>{heading}</th>
        );
    })}
    </tr>;

    return (
        <div id={id} className="flex-col w-full">
            <div className="w-full bg-gray-300 p-1 text-center">
                {heading}
            </div>
            <table className="table-auto w-full">
                <thead>
                    {renderHeaderRow}
                </thead>
                <tbody>
                    {children}
                </tbody>
            </table>
        </div>
    );

}

export default GmDisplayTable;
