import { createSlice, nanoid } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit';
import MagicItemData, { MagicItemDataType } from "../../rules/models/MagicItemData";

const emptyMagicItemDataType = MagicItemData.getEmptyType();

const npcSlice = createSlice({
    name: 'magicItems',
    initialState: {
        magicItemRoster: [] as MagicItemDataType[],
        selectedMagicItem: emptyMagicItemDataType as MagicItemDataType, 
        selectedMagicItemType: '',
        magicItemList: [] as MagicItemDataType[],
        magicItemQuantity: 3,
        magicItemAllowPriestSpells: false
    },
    reducers: {
        setMagicItemList(state, action: PayloadAction<MagicItemDataType[]>) {
            state.magicItemList = action.payload;
        },
        setMagicItemQuantity(state, action: PayloadAction<number>) {
            state.magicItemQuantity = action.payload;
        },
        setSelectedMagicItemType(state, action: PayloadAction<string>) {
            state.selectedMagicItemType = action.payload;
        },
        addMagicItem(state, action: PayloadAction<MagicItemDataType>) {
            const newData = { ...action.payload };
            newData.id = nanoid();
            state.magicItemRoster.push(newData);
        },
        removeMagicItem(state, action: PayloadAction<MagicItemDataType>) {
            const updatedData = state.magicItemRoster.filter((npc) => {
                return npc.id !== action.payload.id;
            });
            state.magicItemRoster = updatedData;
        },
        setSelectedMagicItem(state, action: PayloadAction<MagicItemDataType>) {
            state.selectedMagicItem = action.payload;
        },
        setAllowPriestSpells(state, action: PayloadAction<boolean>) {
            state.magicItemAllowPriestSpells = action.payload;
        }
    }
});

export const {
    setMagicItemList
    , setMagicItemQuantity
    , setSelectedMagicItemType
    , addMagicItem
    , removeMagicItem
    , setSelectedMagicItem
    , setAllowPriestSpells
} = npcSlice.actions;
export const magicItemReducer = npcSlice.reducer;
