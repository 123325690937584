import "./ShadowDarkMap.css";
import React from "react";
import Dice from "../../tools/Dice";
import { MapArea, MapLocation } from "../../rules/models/MapData";
import MapLocationTile from "../../components/maps/MapLocationTile";
import GMTools from "../../tools/GMTools";
import Button from "../../components/gmtools/general/Button";
import ToolContainer from "../../components/gmtools/general/ToolContainer";
import GmDisplayPanel from "../../components/gmtools/general/GmDisplayPanel";
import { FaDiceD20, FaTimesCircle } from "react-icons/fa";
import ButtonToggle from "../../components/gmtools/general/ButtonToggle";
import ToolPanelFull from "../../components/gmtools/general/ToolPanelFull";
import GmDisplayTable from "../../components/gmtools/general/GmDisplayTable";
import { IconContext } from "react-icons";
import ButtonWithOptions, { ButtonWithOptionsOption } from "./general/ButtonWithOptions";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
    setWorkingMap
    , setWorkingMapLocation
    , setSelectedLocation
    , setAllowEmptyLocation
    , setSiteSize
    , setSuggestedDangerLevel
} from "../../store";


function ShadowDarkMaps() {
    const dispatch = useAppDispatch();
    const {
        rowDim,
        colDim,
        workingMap,
        selectedLocation,
        allowEmptyLocations,
        siteSize,
        suggestedDangerLevel
    } = useAppSelector((state) => {
        return {
            rowDim: state.siteMap.rowDim,
            colDim: state.siteMap.colDim,
            workingMap: state.siteMap.workingMap,
            selectedLocation: state.siteMap.selectedLocation,
            allowEmptyLocations: state.siteMap.allowEmptyLocations,
            siteSize: state.siteMap.siteSize,
            suggestedDangerLevel: state.siteMap.suggestedDangerLevel
        }
    });


    // REMOVE SELECTION
    const removeLocationClick = (event: React.MouseEvent<HTMLElement>) => {
        const emptyLocation = MapLocation.getEmptyType();
        emptyLocation.row = selectedLocation.row;
        emptyLocation.column = selectedLocation.column;
        dispatch(setWorkingMapLocation(emptyLocation));
        dispatch(setSelectedLocation(emptyLocation));
    }

    const rerollLocationClick = (event: React.MouseEvent<HTMLElement>) => {
        const newLocation = GMTools.getShadowDarkMapLocation(allowEmptyLocations);
        newLocation.row = selectedLocation.row;
        newLocation.column = selectedLocation.column;
        const newLocationType = newLocation.getCleanType();
        dispatch(setWorkingMapLocation(newLocationType));
        dispatch(setSelectedLocation(newLocationType));
    }

    const renderedFunctionButtons = (selectedLocation.present)
        ? <div className="flex flex-row items-center">
            <Button subtle left
                className='w-1/2 justify-center'
                onClick={removeLocationClick}
            >
                <>
                    <IconContext.Provider value={{ size: "1em" }}>
                        <FaTimesCircle />
                    </IconContext.Provider>&nbsp;Remove
                </>
            </Button>
            <Button subtle right
                className='w-1/2 justify-center'
                onClick={rerollLocationClick}
            >
                <>Reroll&nbsp;<FaDiceD20 /></>
            </Button>
        </div>
        : null;

    // SELECTED LOCATION
    const renderedSelectedLocation = (selectedLocation === null || !selectedLocation.present)
        ? null
        : <div className="grow min-w-[250px]">
            <GmDisplayPanel
                heading={selectedLocation.type}
            // subheading={selectedLocation.description}
            >
                <ul className="body-text text-center">
                    <li>{selectedLocation.description}</li>
                    <li>{`row: ${selectedLocation.row + 1}, column: ${selectedLocation.column + 1}`}</li>
                </ul>
            </GmDisplayPanel>
            {renderedFunctionButtons}
        </div>;


    function fillNewMap(nLocations: number): MapArea {
        const newWorkingMap = new MapArea(rowDim, colDim);

        let n = 0;
        let i = 0;
        while (n < nLocations) {
            const row = Dice.dCustom(0, rowDim - 1);
            const col = Dice.dCustom(0, colDim - 1);
            if (newWorkingMap.mapArea[row][col].present) {
                console.log(`MAP COLLISION (${row},${col}) iteration ${i}`);
            }
            else {
                const newLocation = GMTools.getShadowDarkMapLocation(allowEmptyLocations);
                newLocation.row = row;
                newLocation.column = col;
                newWorkingMap.setLocation(row, col, newLocation);
                n++;
                // console.log(`filled location ${n} at (${row},${col}), ${i}`)
            }
            i++;
        }
        return newWorkingMap;
    }


    // trap - GiWolfTrap
    // empty - ImCheckboxUnchecked
    // minor hazard - BsFillExclamationTriangleFill
    // solo monster - GiMonsterGrasp
    // npc - GiPerson
    // monster mob - GrGroup
    // major hazard - BsFillExclamationOctagonFill
    // treasure - GiOpenTreasureChest
    // boss monster - GiCrownedSkull

    // Empty
    // Trick/Trap
    // Faction
    // NPCs
    // Monster
    // Flora/Fauna - TbPlant2 - GiCarnivorousPlant
    // Feature
    // Treasure
    // Objective


    const handleLocationClick = (event: React.MouseEvent<HTMLElement>, row: number, col: number) => {
        // console.log(`${row}, ${col} - ${JSON.stringify(workingMap.mapArea[row][col], undefined, 2)}`);
        if (workingMap.mapArea[row][col].present) {
            // Location is present, so select it.
            const newSelectedLocation = { ...workingMap.mapArea[row][col] };
            dispatch(setSelectedLocation(newSelectedLocation));
        }
        else {
            // Location is NOT present, so add one.
            const newLocation = GMTools.getShadowDarkMapLocation(allowEmptyLocations);
            newLocation.row = row;
            newLocation.column = col;
            const newLocationType = newLocation.getCleanType()
            dispatch(setWorkingMapLocation(newLocationType));
            dispatch(setSelectedLocation(newLocationType));
        }
    }



    const renderedMapArea = workingMap.mapArea.map((mapRow, rowIndex) => {
        return (
            mapRow.map((location, cellIndex) => {
                const id = `${rowIndex}-${cellIndex}`;
                return (
                    <MapLocationTile
                        mapLocation={location}
                        id={id}
                        key={id}
                        selected={(rowIndex === selectedLocation.row && cellIndex === selectedLocation.column)}
                        onClick={(e) => handleLocationClick(e, rowIndex, cellIndex)}
                    >
                        <></>
                    </MapLocationTile>
                );
            })
        );
    });


    const selectSiteSizeOptions = [
        { "label": "Random Size", "value": "" },
        { "label": "Small Site", "value": "d3+4" },  // 5, 6, 7
        { "label": "Medium Site", "value": "d3+7" }, // 8, 9, 10
        { "label": "Large Site", "value": "d3+10" },      // 11, 12, 13
    ];
    const handleSizeChange = (event: React.FormEvent<HTMLSelectElement>) => {
        dispatch(setSiteSize(event.currentTarget.value));
    };


    const handleMapCreateClick = (
        event: React.MouseEvent<HTMLElement>,
        mapSizeRoll: string
    ) => {
        const rollMapSize: string = (mapSizeRoll === "")
            ? selectSiteSizeOptions[Dice.dCustom(1, 3)].value
            : mapSizeRoll
        const mapSize = Dice.roll(rollMapSize);

        console.log(`mapSizeRoll = ${mapSizeRoll}`);
        console.log(`rollMapSize = ${rollMapSize}`);
        console.log(`mapSize = ${mapSize}`);

        const newWorkingMap = fillNewMap(mapSize);
        dispatch(setWorkingMap(newWorkingMap.getCleanType()));
        // dispatch(setSelectedLocation(newWorkingMap.mapArea[0][0]));
        dispatch(setSelectedLocation(MapLocation.getEmptyType()));
    }

    const handleAllowEmptyClick = (event: React.MouseEvent<HTMLElement>) => {
        dispatch(setAllowEmptyLocation(!allowEmptyLocations));
    }

    const getDangerLevel = (event: React.MouseEvent<HTMLElement>) => {
        const newDangerLevel = GMTools.getAdventureDangerLevel();
        dispatch(setSuggestedDangerLevel(newDangerLevel));
    }


    const locationListHeaders = ["Row", "Col", "Type", "Description"];
    const renderedLocations = workingMap.mapArea.map((mapRow, rowIndex) => {
        return (
            mapRow.map((location, cellIndex) => {
                const id = `${rowIndex}-${cellIndex}`;
                return (
                    (!location.present)
                        ? null
                        : <tr
                            key={id}
                            className="border-y-2 border-black"
                        >
                            <td className="pl-4 text-center">{location.row + 1}</td>
                            <td className="pl-4 text-center">{location.column + 1}</td>
                            <td className="pl-4">{location.type}</td>
                            <td className="pl-4">{location.description}</td>
                        </tr>
                );
            })
        );
    });

    const renderedLocationsTable = (workingMap.nLocations === 0)
        ? null
        : <GmDisplayTable
            id="Location List"
            heading="LOCATIONS"
            headerCells={locationListHeaders}
        >
            {renderedLocations}
        </GmDisplayTable>





    return (
        <ToolContainer>
            <h2>Site Map Inspiration</h2>
            <div className="mb-3 mt-6 body-text">
                <p>
                    Use this tool to give your creativity something to play with as you ponder your next adventure.
                </p>
                <ul className="instruction-text list-inside list-disc">
                    <li>Allow the placement of the locations below to suggest the layout of your map.</li>
                    <li>Select a location to see a suggestion of what's there or to remmove/reroll the location.</li>
                    <li>Click an empty location to add something random there.</li>
                    <li>Remember, this is meant to remove the obstacle of a blank page. Nothing more. :)</li>
                </ul>
            </div>
            <ToolPanelFull>
                <div className="flex-column pb-4">
                    <ButtonWithOptions
                            primary taller selectorLarge
                            options={selectSiteSizeOptions as ButtonWithOptionsOption[]}
                            selectedValue={siteSize}
                            onSelectChange={handleSizeChange}
                            onClick={((e) => handleMapCreateClick(e, siteSize))}
                        >
                            <>Get Locations</>
                        </ButtonWithOptions>
                    <ButtonToggle
                        toggleValue={allowEmptyLocations}
                        subtle solo
                        onClick={((e) => handleAllowEmptyClick(e))}
                    >
                        <>Allow Empty Locations</>
                    </ButtonToggle>
                    <div className="flex flex-row pt-2">
                        <Button
                            primary solo
                            onClick={(e) => getDangerLevel(e)}
                        >
                            <>Suggest a Danger Level:</>
                        </Button>
                        <label className="font-face-montserrat-medium flex items-center px-3 mb-3">
                            {suggestedDangerLevel}
                        </label>
                    </div>
                </div>
            </ToolPanelFull>
            <div>
                <div className="flex">
                    <div className="mr-2">
                        <div className="shadowdark-map-container rounded-lg">
                            <div className="grid grid-cols-9 grid-rows-12 gap-2 font-mono text-white text-sm font-bold leading-6 bg-stripes-fuchsia rounded-lg text-center">
                                {renderedMapArea}
                            </div>
                        </div>
                    </div>
                    <div className="mr-2">

                        {renderedSelectedLocation}

                    </div>
                </div>
            </div>
            <ToolPanelFull>
                <div className="w-full pt-4">
                    {renderedLocationsTable}
                </div>
            </ToolPanelFull>
        </ToolContainer>
    );

}

export default ShadowDarkMaps;
