import './index.css';
import './input.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from 'react-router-dom';
import ErrorPage from './pages/ErrorPage';
import HomePage from './pages/HomePage';
import GMToolsPage from './pages/GMToolsPage';
import CharacterPage from './pages/CharacterPage';
import ResourcesPage from './pages/Resources';
import AboutPage from './pages/AboutPage';
import ShadowDarkTools from './components/ShadowDarkTools';
import { Provider } from 'react-redux';
import { store } from './store';
import PlanningPage from './pages/PlanningPage';
import GMToolsPageFull from './pages/GMToolsPageFull';
import ReferencePage from './pages/ReferencePage';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<ShadowDarkTools />}
      errorElement={<ErrorPage />}
    >
      <Route errorElement={<ErrorPage />}>
        <Route index element={<HomePage />} />
        <Route
          path="gmtools"
          element={<GMToolsPage />}
        />
        <Route
          path="characters"
          element={<CharacterPage />}
        />
        <Route
          path="resources"
          element={<ResourcesPage />}
        />
        <Route
          path="about"
          element={<AboutPage />}
        />
        <Route
          path="planning"
          element={<PlanningPage />}
        />
        <Route
          path="gmtoolsfull"
          element={<GMToolsPageFull />}
        />
        <Route
        path="reference"
        element={<ReferencePage />}
        />
      </Route>
    </Route>
  )
);




const element = document.getElementById('root');
if (element === null) {
  throw Error("root is null");
}
ReactDOM.createRoot(element).render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

