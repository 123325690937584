type HazardDataType = {
    type: string;
    hazard: string;
}

class HazardData {
    public type: string = "";
    public hazard: string = "";
    
    public getCleanType(): HazardDataType {
        const result = {
            type: this.type,
            hazard: this.hazard
        };
        return result;
    }

    public static getEmptyEncounterDataType(): HazardDataType {
        const result = {
            type: '',
            hazard: ''
        };
        return result;
    }
}

export { HazardDataType };
export default HazardData; 
