type MapLocationType = {
    row: number,
    column: number,
    present: boolean,
    type: string,
    description: string
}

class MapLocation {
    row: number = -1;
    column: number = -1;
    present: boolean = false;
    type: string = "";
    description: string = "";

    public getCleanType(): MapLocationType {
        const result = {
            row: this.row,
            column: this.column,
            present: this.present,
            type: this.type,
            description: this.description
        };
        return result;
    }

    public static getEmptyType(): MapLocationType {
        const result = {
            row: -1,
            column: -1,
            present: false,
            type: '',
            description: ''
        };
        return result;
    }

}


type MapAreaType = {
    mapArea: MapLocationType[][],
    nRows: number,
    nColumns: number,
    nLocations: number,
    dangerLevel: string
}

class MapArea {
    mapArea: MapLocationType[][] = [];
    nRows: number = 0;
    nColumns: number = 0;
    nLocations: number = 0;
    dangerLevel: string = "";

    constructor(nRows: number, nColumns: number) {
        this.nRows = nRows;
        this.nColumns = nColumns;

        for (let r = 0; r < this.nRows; r++) {
            const row: MapLocationType[] = [];
            for (let c = 0; c < this.nColumns; c++) {
                row.push(MapLocation.getEmptyType());
            }
            this.mapArea.push(row);
        }
    }

    public setLocation(row: number, column: number, mapLocation: MapLocation) {
        const targetLocationPresent = this.mapArea[row][column].present;

        mapLocation.row = row;
        mapLocation.column = column;
        this.mapArea[row][column] = mapLocation;

        if (targetLocationPresent && !mapLocation.present) {
            // overwriting a full location with an empty location
            this.nLocations--;
        }
        else if (!targetLocationPresent && mapLocation.present) {
            // overwriting an empty location with a full location
            this.nLocations++;
        }


    }

    public duplicate(): MapArea {
        const dupe = new MapArea(this.nRows, this.nColumns);
        dupe.mapArea = JSON.parse(JSON.stringify(this.mapArea));
        dupe.nLocations = this.nLocations;
        dupe.dangerLevel = this.dangerLevel;
        return dupe;
    }

    public getCleanType(): MapAreaType {
        const result = {
            mapArea: JSON.parse(JSON.stringify(this.mapArea)), // [] as any, 
            nRows: this.nRows,
            nColumns: this.nColumns,
            nLocations: this.nLocations,
            dangerLevel: this.dangerLevel
        };
        return result;
    }

    public static getEmptyType(): MapAreaType {
        const result = {
            mapArea: [],
            nRows: 0,
            nColumns: 0,
            nLocations: 0,
            dangerLevel: ''
        };
        return result;
    }

    public static duplicateType(source: MapAreaType): MapAreaType {
        const dupe = new MapArea(source.nRows, source.nColumns);
        dupe.mapArea = JSON.parse(JSON.stringify(source.mapArea));
        dupe.nLocations = source.nLocations;
        dupe.dangerLevel = source.dangerLevel;
        return dupe.getCleanType();
    }

    public static setLocationOnType(mapAreaType: MapAreaType, newLocation: MapLocationType) {
        const targetLocationPresent = mapAreaType.mapArea[newLocation.row][newLocation.column].present;

        mapAreaType.mapArea[newLocation.row][newLocation.column] = newLocation;

        if (targetLocationPresent && !newLocation.present) {
            // overwriting a full location with an empty location
            mapAreaType.nLocations--;
        }
        else if (!targetLocationPresent && newLocation.present) {
            // overwriting an empty location with a full location
            mapAreaType.nLocations++;
        }
    }
}

export { MapArea, MapLocation, MapAreaType, MapLocationType };
