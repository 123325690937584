import "../NpcNames.css";
import React from "react";
import GMTools from "../../../tools/GMTools";
import GmDisplayPanel from "../general/GmDisplayPanel";
import ToolContainer from "../general/ToolContainer";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
    setNpcAncestry
    , setNpcAncestryQuantity
    // , setNpcNameLength
    // , setNpcNameQuantity
    , setNpcNames
    , setNamesType
} from "../../../store";
import ButtonTwoOptions, { ButtonWithOptionsOption } from "../general/ButtonTwoOptions";
import ToolPanelFull from "../general/ToolPanelFull";



function NpcNamesMobile() {
    const dispatch = useAppDispatch();
    const {
        names,
        namesType,
        // nameLength,
        // nameQuantity,
        ancestry,
        ancestryQuantity
    } = useAppSelector((state) => {
        return {
            names: state.npcNames.names,
            namesType: state.npcNames.namesType,
            nameLength: state.npcNames.nameLength,
            nameQuantity: state.npcNames.nameQuantity,
            ancestry: state.npcNames.ancestry,
            ancestryQuantity: state.npcNames.ancestryQuantity,
        }
    });

    const renderedNpcNameList = names.map((name, index) => {
        return (
            <li key={index}><b>{name}</b></li>
        );
    });

    const heading = (namesType === "") ? "Names" : `${namesType} Names`;
    const renderedNpcNames = (names.length <= 0)
        ? null
        : <GmDisplayPanel
            heading={heading}
            subheading=""
        >
            <ul className="body-text text-left pl-6 list-inside list-disc">
                {renderedNpcNameList}
            </ul>
        </GmDisplayPanel>

    const selectAncestryOptions = [
        { "label": "Dwarf", "value": "dwarf" },
        { "label": "Elf", "value": "elf" },
        { "label": "Goblin", "value": "goblin" },
        { "label": "Halfling", "value": "halfling" },
        { "label": "Half-Orc", "value": "half-orc" },
        { "label": "Human", "value": "human" },
    ];


    const selectQuantityOptions = [
        { "label": "1", "value": "1" },
        { "label": "2", "value": "2" },
        { "label": "3", "value": "3" },
        { "label": "4", "value": "4" },
        { "label": "5", "value": "5" },
    ];
    // const renderedQuantitySelectOptions = selectQuantityOptions.map((option) => {
    //     return (
    //         <option
    //             key={option.label}
    //             value={option.value}
    //         >{option.label}</option>
    //     );
    // });


    const handleAncestryQuantityChange = (event: React.FormEvent<HTMLSelectElement>) => {
        const newQuantity = +(event.currentTarget.value);
        dispatch(setNpcAncestryQuantity(newQuantity));
    };
    const handleAncestryChange = (event: React.FormEvent<HTMLSelectElement>) => {
        const newAncestry = event.currentTarget.value;
        dispatch(setNpcAncestry(newAncestry));
    };
    const handleGetAncestryNamesClick = (event: React.MouseEvent<HTMLElement>) => {
        // console.log("handleGetAncestryNamesClick");
        const newAncestryNames = GMTools.getNpcAncestryNames(ancestryQuantity, ancestry);
        dispatch(setNpcNames(newAncestryNames));
        dispatch(setNamesType(ancestry));
    }








    return (
        <ToolContainer>
            <ToolPanelFull
                title="Npc Names"
            >
                <ButtonTwoOptions
                    primary taller centerSelectorSmall rightSelectorMedium
                    optionsCenter={selectQuantityOptions as ButtonWithOptionsOption[]}
                    selectedValueCenter={ancestryQuantity.toString()}
                    onSelectChangeCenter={handleAncestryQuantityChange}
                    optionsRight={selectAncestryOptions as ButtonWithOptionsOption[]}
                    selectedValueRight={ancestry}
                    onSelectChangeRight={handleAncestryChange}
                    onClick={handleGetAncestryNamesClick}
                >
                    <>Get Names</>
                </ButtonTwoOptions>
            </ToolPanelFull>

            {renderedNpcNames}


        </ToolContainer>
    );

}

export default NpcNamesMobile;
