class Dice {

    public static dCustom(low: number, high: number): number {
        return Math.floor(Math.random() * (high - low + 1) + low);
    }

    public static dCoinFlip(): number {
        return Dice.dCustom(1, 2);
    }

    public static dCoinFlipBool(): boolean {
        return (Dice.dCustom(1, 2) === 1);
    }

    private static parseRoll(rollString: string): TTRpgRoll {
        // console.log(`TTRpgRoll rollString = ${rollString}`);

        rollString = rollString.toUpperCase();
        let firstSplit: string[] = rollString.split("D");

        // console.log("FIRST SPLIT: ", firstSplit);

        const result: TTRpgRoll = new TTRpgRoll();
        // console.log(`TTRpgRoll START RESULT = ${JSON.stringify(result, undefined, 2)}`);

        switch (firstSplit.length) {
            case 1:
                result.howMany = 1;
                result.numFaces = parseInt(firstSplit[0]);
                result.adjustment = 0;
                break;

            case 2:
                result.howMany = parseInt(firstSplit[0]);
                result.howMany = Number.isNaN(result.howMany) ? 1 : result.howMany;
                result.adjustment = 0;

                let secondSplit: string[] = [];
                if (firstSplit[1].indexOf('+') >= 0) {
                    // console.log(`TTRpgRoll CASE 2, IF 1`);

                    secondSplit = firstSplit[1].split("+");
                    result.numFaces = parseInt(secondSplit[0]);
                    result.adjustment = parseInt(secondSplit[1]);
                }
                else if (firstSplit[1].indexOf('-') >= 0) {
                    // console.log(`TTRpgRoll CASE 2, IF 2`);
                    secondSplit = firstSplit[1].split("-");
                    result.numFaces = parseInt(secondSplit[0]);
                    result.adjustment = parseInt(secondSplit[1]) * -1;
                }
                else {
                    // console.log(`TTRpgRoll CASE 2, IF 3`);
                    result.numFaces = parseInt(firstSplit[1]);
                }

                // console.log(`TTRpgRoll secondSplit = ${secondSplit}`);

                break;

            default:
                throw Error(`parseRoll: failed to parse rollString "${rollString}"`)
        }

        // var secondSplit: string[] = result

        // console.log(`TTRpgRoll = ${JSON.stringify(result, undefined, 2)}`);
        // console.log("HOW MANY: ", result.howMany);
        // console.log("N FACES : ", result.numFaces);
        return result;
    }

    public static roll(rollString: string): number {

        var ttrpgRoll: TTRpgRoll = Dice.parseRoll(rollString);
        var result: number = 0;
        for (let d = 0; d < ttrpgRoll.howMany; d++) {
            result += Dice.dCustom(1, ttrpgRoll.numFaces);
        }
        result += ttrpgRoll.adjustment;
        return result;
    }

    public static rollWithDetails(rollString: string): DiceFullResult {

        var ttrpgRoll: TTRpgRoll = Dice.parseRoll(rollString);
        var result: number = 0;
        var details: number[] = [];
        for (let d = 0; d < ttrpgRoll.howMany; d++) {
            var thisRoll: number = Dice.dCustom(1, ttrpgRoll.numFaces);
            details.push(thisRoll);
            result += thisRoll;
        }
        return new DiceFullResult(rollString, result, details);
    }



    public static rollWithAdvantage(rollString: string): AdvantageDisadvantageRollResult {
        const firstRoll = Dice.rollWithDetails(rollString);
        const secondRoll = Dice.rollWithDetails(rollString);

        let result = new AdvantageDisadvantageRollResult();
        if (firstRoll.result > secondRoll.result) {
            result.winner = firstRoll;
            result.loser = secondRoll;
        } else {
            result.winner = secondRoll;
            result.loser = firstRoll;
        }
        return result;
    }

    public static rollWithDisadvantage(rollString: string): AdvantageDisadvantageRollResult {
        const firstRoll = Dice.rollWithDetails(rollString);
        const secondRoll = Dice.rollWithDetails(rollString);

        let result = new AdvantageDisadvantageRollResult();
        if (firstRoll.result < secondRoll.result) {
            result.winner = firstRoll;
            result.loser = secondRoll;
        } else {
            result.winner = secondRoll;
            result.loser = firstRoll;
        }
        return result;
    }


}

class TTRpgRoll {

    public howMany: number = 0;
    public numFaces: number = 0;
    public adjustment: number = 0;

}


class DiceFullResult {

    private _request: string;
    public get request(): string {
        return this._request;
    }
    public set request(v: string) {
        this._request = v;
    }


    private _result: number;
    public get result(): number {
        return this._result;
    }
    public set result(v: number) {
        this._result = v;
    }


    private _rolls: number[];
    public get rolls(): number[] {
        return this._rolls;
    }
    public set rolls(v: number[]) {
        this._rolls = v;
    }

    constructor(request: string, result: number, rolls: number[]) {
        this._request = request;
        this._result = result;
        this._rolls = rolls;
    }
}

class AdvantageDisadvantageRollResult {
    public winner: DiceFullResult | null = null;
    public loser: DiceFullResult | null = null;

}

export default Dice;
export { DiceFullResult, AdvantageDisadvantageRollResult }
