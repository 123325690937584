import MonsterReference from "../components/reference/MonsterReference";
import "./HomePage.css";
import React from "react";


function ReferencePage() {

    return (
        <div>
            <div className="mb-3 mt-6 body-text">
            <p className="body-text">
                    The Monster Filter is a <b>work in progress</b>. I'd appreciate any feedback.
                </p>
                {/* <p className="body-text">
                    Cursed Scroll monster core information included with kind permission from The Arcane Library.
                </p> */}
            </div>
            <div>
                {/* <div>
                    <h2>What's on the Way</h2>
                </div> */}
                {/* <div>
                    <ul className="body-text list-inside list-disc">
                        <li>Random character generator, with ability to customize certain items.</li>
                        <li>Peasant (Level 0) character generator.</li>
                        <li>Gauntlet 4-Pack generator.</li>
                    </ul>
                </div> */}
            </div>
            <MonsterReference />

        </div>
    );

}

export default ReferencePage;
