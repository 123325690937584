import className from 'classnames';
import React from 'react';


type ButtonWithOptionsOption = {
    label: string,
    value: string
}

interface ButtonWithOptionsProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    optionsRight: ButtonWithOptionsOption[];
    selectedValueRight: string;
    onSelectChangeRight: (e: React.FormEvent<HTMLSelectElement>) => void;
    optionsCenter: ButtonWithOptionsOption[];
    selectedValueCenter: string;
    onSelectChangeCenter: (e: React.FormEvent<HTMLSelectElement>) => void;
    children: JSX.Element;
    primary?: boolean;
    secondary?: boolean;
    subtle?: boolean;
    taller?: boolean;
    centerSelectorSmall?: boolean;
    centerSelectorMedium?: boolean;
    centerSelectorLarge?: boolean;
    centerSelectorXLarge?: boolean;
    rightSelectorSmall?: boolean;
    rightSelectorMedium?: boolean;
    rightSelectorLarge?: boolean;
    rightSelectorXLarge?: boolean;
}


function ButtonWithOptions(props: ButtonWithOptionsProps) {
    const {
        optionsRight,
        selectedValueRight,
        onSelectChangeRight,
        optionsCenter,
        selectedValueCenter,
        onSelectChangeCenter,
        children,
        primary,
        secondary,
        subtle,
        taller,
        rightSelectorSmall,
        rightSelectorMedium,
        rightSelectorLarge,
        rightSelectorXLarge,
        centerSelectorSmall,
        centerSelectorMedium,
        centerSelectorLarge,
        centerSelectorXLarge,
        ...rest } = props;


    const renderedOptionsRight = optionsRight.map((option) => {
        return (
            <option
                key={option.label}
                value={option.value}
            >{option.label}</option>
        );
    });
    const renderedOptionsCenter = optionsCenter.map((option) => {
        return (
            <option
                key={option.label}
                value={option.value}
            >{option.label}</option>
        );
    });

    const classes = className(
        rest.className,
        'font-face-montserrat-medium flex items-center whitespace-nowrap px-3 rounded-l-lg',
        {
            'mb-3 border border-black bg-black text-white': primary,
            'mb-3 border border-black bg-white text-black': secondary,
            'mb-3 border border-gray-400 text-gray-400 hover:text-black': subtle,
            'py-1.5': taller,
        }
    );

    const selectorClassesRight = className(
        rest.className,
        'font-face-montserrat-medium text-lg flex items-center rounded-r-lg px-2 mb-3',
        {
            'w-14': rightSelectorSmall,
            'w-32': rightSelectorMedium,
            'w-40': rightSelectorLarge,
            'w-60': rightSelectorXLarge,
            'border': primary,
            'border border-black': secondary,
            'border border-gray-400 text-gray-400 hover:text-black': subtle
        }
    );
    const selectorClassesCenter = className(
        rest.className,
        'font-face-montserrat-medium text-lg flex items-center px-2 mb-3',
        {
            'w-14': centerSelectorSmall,
            'w-28': centerSelectorMedium,
            'w-40': centerSelectorLarge,
            'w-60': centerSelectorXLarge,
            'border': primary,
            'border border-black': secondary,
            'border border-gray-400 text-gray-400 hover:text-black': subtle
        }
    );

    // .npc-names-quantity-selector {
    //     font-family: "MontserratMedium";
    //     font-size: large;
    //     text-align: center;
    //     border-width: 1px;
    //     padding: 3px;
    //     width: 50px;
    // }


    return (
        <div className="flex flex-row">
            <button {...rest} className={classes}>
                {children}
            </button>
            <select
                value={selectedValueCenter}
                className={selectorClassesCenter}
                onChange={onSelectChangeCenter}
            >
                {renderedOptionsCenter}
            </select>
            <select
                value={selectedValueRight}
                className={selectorClassesRight} //"buttonwithoptions-selector"
                onChange={onSelectChangeRight}
            >
                {renderedOptionsRight}
            </select>
        </div>
    );

}

export { ButtonWithOptionsOption }
export default ButtonWithOptions;
