import className from 'classnames';
import React from 'react';


interface ButtonComponentProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    children: JSX.Element;
    primary?: boolean;
    secondary?: boolean;
    subtle?: boolean;
    solo?: boolean;
    left?: boolean;
    center?: boolean;
    right?: boolean;
    lefttoright?: boolean;
    menu?: boolean
}


function Button(props: ButtonComponentProps) {
    const {
        children,
        primary,
        secondary,
        subtle,
        solo,
        left,
        center,
        right,
        lefttoright,
        menu,
        ...rest } = props;


    const classes = className(
        rest.className,
        'font-face-montserrat-medium flex items-center px-3',
        {
            'mb-3 border border-black bg-black text-white': primary,
            'mb-3 border-2 border-black bg-white text-black': secondary,
            'border border-gray-400 text-gray-400 hover:text-black': subtle,
            'rounded-lg py-1.5': solo,
            'rounded-l-lg': left,
            '': center,
            'rounded-r-lg': right,
            'rounded-lg': lefttoright,
            'border border-black text-black hover:text-black': menu
        }
    );

    return (
        <button {...rest} className={classes}>
            {children}
        </button>
    );

}

export default Button;
