type RivalCrawlerType = {
    name: string;
    ancestry: string;
    class: string;
    level: number;
    alignment: string;
}

class RivalCrawler {
    public name: string = "";
    public ancestry: string = "";
    public class: string = "";
    public level: number = 0;
    public alignment: string = "";

    public getCleanType(): RivalCrawlerType {
        const result = {
            name: this.name,
            ancestry: this.ancestry,
            class: this.class,
            level: this.level,
            alignment: this.alignment,
        };
        return result;
    }

    public static getEmptyType(): RivalCrawlerType {
        const result = {
            name: '',
            ancestry: '',
            class: '',
            level: 0,
            alignment: ''
        };
        return result;
    }
}

type RivalCrawlerPartyType = {
    name: string;
    knownFor: string;
    alignment: string;
    renown: string;
    secret: string;
    wealth: string;
    tactic: string;
    size: number;
    members: RivalCrawlerType[];
}

class RivalCrawlerParty {
    public name: string = "";
    public knownFor: string = "";
    public alignment: string = "";
    public renown: string = "";
    public secret: string = "";
    public wealth: string = "";
    public tactic: string = "";
    public size: number = 0;
    public members: RivalCrawler[] = [];

    public getCleanType(): RivalCrawlerPartyType {
        const result = {
            name: this.name,
            knownFor: this.knownFor,
            alignment: this.alignment,
            renown: this.renown,
            secret: this.secret,
            wealth: this.wealth,
            tactic: this.tactic,
            size: this.size,
            members: this.members.map((m) => m.getCleanType())
        };
        return result;
    }

    public static getEmptyType(): RivalCrawlerPartyType {
        const result = {
            name: '',
            knownFor: '',
            alignment: '',
            renown: '',
            secret: '',
            wealth: '',
            tactic: '',
            size: 0,
            members: [] as RivalCrawlerType[]
        };
        return result;
    }
}

export { RivalCrawlerPartyType, RivalCrawler, RivalCrawlerType };
export default RivalCrawlerParty;
