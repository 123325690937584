import "./GMToolsPage.css";
import "./Page.css";
import React from "react";
import AdventureNames from "../components/gmtools/AdventureNames";
import ShadowDarkMaps from "../components/gmtools/ShadowDarkMaps";


function PlanningPage() {

    return (
        <div>
            <div className="mb-3 mt-6 body-text">
            </div>
            <AdventureNames />
            <ShadowDarkMaps />

        </div>
    );

}

export default PlanningPage;
