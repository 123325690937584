import { createSlice, nanoid } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit';
// import type { RootState } from '../index';
import EncounterData, { EncounterDataType } from "../../rules/models/EncounterData";

const emptyEncounterDataType = EncounterData.getEmptyEncounterDataType();

const encounterSlice = createSlice({
    name: 'encounter',
    initialState: {
        data: emptyEncounterDataType,
        log: [] as EncounterDataType[],
        showLog: false,
        confirmLogClear: false,
        rollEncounterTableDie: false,
        encounterTableDie: ''
    },
    reducers: {
        setEncounter(state, action: PayloadAction<EncounterDataType>) {
            const newData = { ...action.payload };
            newData.id = nanoid();
            state.data = newData;
            state.log.push(newData);
        },
        updateEncounter(state, action: PayloadAction<EncounterDataType>) {
            const newData = { ...action.payload };
            state.data = newData;
            const entryIndex = state.log.map(e => e.id).indexOf(action.payload.id);
            state.log[entryIndex] = newData;
        },
        addLogEntry(state, action: PayloadAction<EncounterDataType>) {
            const newData = { ...action.payload };
            newData.id = nanoid();
            state.log.push(newData);
        },
        removeLogEntry(state, action: PayloadAction<EncounterDataType>) {
            const updatedLog = state.log.filter((encounter) => {
                return encounter.id !== action.payload.id;
            });
            state.log = updatedLog;
        },
        clearEncounterLog(state) {//}, action: PayloadAction<EncounterDataType>) {
            const replacementLog = new Array<EncounterDataType>();
            state.log = replacementLog;
            state.confirmLogClear = false;
        },
        toggleShowLog(state, action: PayloadAction<boolean>) {
            state.showLog = !action.payload;
        },
        toggleConfirmLogClear(state, action: PayloadAction<boolean>) {
            state.confirmLogClear = !action.payload;
        },
        toggleRollEncounterTableDie(state, action: PayloadAction<boolean>) {
            state.rollEncounterTableDie = !action.payload;
        },
        setEncounterTableDie(state, action: PayloadAction<string>) {
            state.encounterTableDie = action.payload;
        }
    }
});

export const {
    setEncounter,
    updateEncounter,
    addLogEntry,
    removeLogEntry,
    clearEncounterLog,
    toggleShowLog,
    toggleConfirmLogClear,
    toggleRollEncounterTableDie,
    setEncounterTableDie
} = encounterSlice.actions;
export const encounterReducer = encounterSlice.reducer;
