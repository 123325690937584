import "./HomePage.css";
import React from "react";
import { useRouteError } from "react-router-dom";
import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";

function ErrorPage() {
    const error: any = useRouteError();
    console.error(error);

    return (
        <>
            <div className='app'>
                <h1 className="appTitle">Shadowdark Tools</h1>
                <div className='font-face-montserrat-medium'>
                    <Navbar />
                </div>
                <div id="error-page">
                    <h2>Yep. That's a Natural 1. Sorry.</h2>
                    <p className="mb-3 mt-6 body-text">
                        But seriously, an unexpected error has occurred.
                    </p>
                    <p className="mb-3 mt-6 body-text">
                        My apologies.
                    </p>
                    <p className="mb-3 mt-6 body-text">
                        <i>{error.statusText || error.message}</i>
                    </p>
                    <div className="mb-3 mt-6 body-text">
                        It would be super helpful if you could let me know this happened.
                        <div className="body-text">
                            I can be reached on the <a className="regular" target="_blank" href="https://discord.com/channels/558029475837902851/1082831426216534026" rel="noreferrer">Shadowdark Discord</a>, where I am Cesidio.
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <br />
            <br />
            <br />
        </>
    );

}

export default ErrorPage;
