type EncounterDataType = {
    id: string;
    when: number;
    encounterRoll: number;
    charismaMod: number;
    distance: string;
    activity: string;
    reactionRoll: number;
    reaction: string;
    treasure: string;
    encounterTableRoll: number;
}

// interface IEncounterData {
//     id: string;
//     when: number;
//     encounterRoll: number;
//     charismaMod: number;
//     distance: string;
//     activity: string;
//     reactionRoll: number;
//     reaction: string;
//     treasure: string;
// }

class EncounterData {
    public id: string = '';
    public when: number = 0;
    public encounterRoll: number = 0;
    public charismaMod: number = 0;
    public distance: string = "";
    public activity: string = "";
    public reactionRoll: number = 0;
    public reaction: string = "";
    public treasure: string = "";
    public encounterTableRoll: number = 0;

    public getCleanType(): EncounterDataType {
        const result = {
            id: this.id,
            when: this.when,
            encounterRoll: this.encounterRoll,
            charismaMod: this.charismaMod,
            distance: this.distance,
            activity: this.activity,
            reactionRoll: this.reactionRoll,
            reaction: this.reaction,
            treasure: this.treasure,
            encounterTableRoll: this.encounterTableRoll
        };
        return result;
    }

    public static getEmptyEncounterDataType(): EncounterDataType {
        const result = {
            id: '',
            when: 0,
            encounterRoll: 0,
            charismaMod: 0,
            distance: '',
            activity: '',
            reactionRoll: 0,
            reaction: '',
            treasure: '',
            encounterTableRoll: 0
        };
        return result;
    }
}


export { EncounterDataType };
export default EncounterData; 
