import React from "react";



function AndMore() {

    return (
        <div className="tool-container border-gray-200 rounded-lg">
            <h2>Additional Thoughts</h2>
            <div className="flex">
                <div className="w-11/12 mr-2">
                    <ul className="body-text list-inside list-disc">
                        <li>Returning as original content is created:
                        <ul className="body-text list-inside list-disc">
                                <li className="ml-6">Traps and Hazards</li>
                            </ul>
                        </li>
                        <li>Monster Generator</li>
                        <li>Treasure Tables, Generator</li>
                        <li>Overland and Settlement map planning tools</li>
                        <li>Thoughts or ideas you'd like to share?
                            <ul className="body-text list-inside list-disc">
                                <li className="ml-6">
                                    Please get in touch: <a className="regular" target="_blank" href="https://discord.com/channels/558029475837902851/1082831426216534026" rel="noreferrer">Shadowdark Discord</a>, user Cesidio.
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="w-1/12 ml-2">
                    <ul className="body-text list-inside list-disc">
                    </ul>
                </div>
            </div>
        </div>
    );

}

export default AndMore;
