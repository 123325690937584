import React from "react";
import { isMobile } from "react-device-detect";
import { NavLink } from "react-router-dom";



function Footer() {

    const licenseText = (isMobile)
    ? <span>See Shadowdark license on the <NavLink to="/about">About</NavLink> page.</span>
    : "Shadowdark Tools is an independent product published under the Shadowdark RPG Third-Party License and is not affiliated with The Arcane Library, LLC. Shadowdark RPG © 2023 The Arcane Library, LLC.";

    return (
        <div className=" h-12 fixed left-0 bottom-0 right-0 bg-black p-2  flex flex-row items-center">
            <span className="font-face-montserrat-light text-white w-1/3">Shadowdark Tools</span>
            <span className="grow">
                <div className="font-face-montserrat-light text-right text-white text-xs">
                    {licenseText}
                </div>
                {/* <div className="font-face-montserrat-light text-right text-white text-sm">Not affiliated with The Arcane Library, LLC. Shadow RPG © 2023 The Arcane Library.</div> */}
            </span>
        </div>
    );


}


export default Footer;


