import className from 'classnames';
import React from 'react';


type ButtonWithOptionsOption = {
    label: string,
    value: string
}

interface ButtonWithOptionsProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    options: ButtonWithOptionsOption[];
    selectedValue: string;
    onSelectChange: (e:  React.FormEvent<HTMLSelectElement>) => void;
    children: JSX.Element;
    primary?: boolean;
    secondary?: boolean;
    subtle?: boolean;
    taller?: boolean;
    selectorSmall?: boolean;
    selectorMedium?: boolean;
    selectorLarge?: boolean;
    selectorXLarge?: boolean;
}


function ButtonWithOptions(props: ButtonWithOptionsProps) {
    const {
        options,
        selectedValue,
        onSelectChange,
        children,
        primary,
        secondary,
        subtle,
        taller,
        selectorSmall,
        selectorMedium,
        selectorLarge,
        selectorXLarge,
        ...rest } = props;


    const renderedOptions = options.map((option) => {
        return (
            <option
                key={option.label}
                value={option.value}
            >{option.label}</option>
        );
    });

    const classes = className(
        rest.className,
        'font-face-montserrat-medium flex items-center whitespace-nowrap px-3 rounded-l-lg',
        {
            'mb-3 border border-black bg-black text-white': primary,
            'mb-3 border border-black bg-white text-black': secondary,
            'mb-3 border border-gray-400 text-gray-400 hover:text-black': subtle,
            'py-1.5': taller,
        }
    );

    const selectorClasses = className(
        rest.className,
        'font-face-montserrat-medium text-lg flex items-center rounded-r-lg px-2 mb-3',
        {
            'w-14': selectorSmall,
            'w-28': selectorMedium,
            'w-40': selectorLarge,
            'w-60': selectorXLarge,
            'border': primary,
            'border border-black': secondary,
            'border border-gray-400 text-gray-400 hover:text-black': subtle
        }
    );


    return (
        <div className="flex flex-row">
            <button {...rest} className={classes}>
                {children}
            </button>
            <select
                value={selectedValue}
                className={selectorClasses} //"buttonwithoptions-selector"
                onChange={onSelectChange}
            >
                {renderedOptions}
            </select>
        </div>
    );

}

export { ButtonWithOptionsOption }
export default ButtonWithOptions;
