type TrapDataType = {
    trap: string;
    trigger: string;
    damage: string;
}


class TrapData {
    public trap: string = "";
    public trigger: string = "";
    public damage: string = "";

    public getCleanType(): TrapDataType {
        const result = {
            trap: this.trap,
            trigger: this.trigger,
            damage: this.damage
        };
        return result;
    }

    public static getEmptyEncounterDataType(): TrapDataType {
        const result = {
            trap: '',
            trigger: '',
            damage: ''
        };
        return result;
    }
}

export { TrapDataType };
export default TrapData; 
