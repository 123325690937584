import "./Adventure.css";
import React from "react";
import GMTools from "../../tools/GMTools";
import GmDisplayPanel from "./general/GmDisplayPanel";
import ToolContainer from "./general/ToolContainer";
import ButtonWithOptions, { ButtonWithOptionsOption } from "./general/ButtonWithOptions";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
    setAdventureIdeas
    , setAdventureQuantity
    , setAdventureSiteQuantity
    , setAdventureIdeasType
} from "../../store";

function AdventureNames() {
    const dispatch = useAppDispatch();
    const {
        ideas,
        ideasType,
        adventureQuantity,
        adventureSiteQuantity
    } = useAppSelector((state) => {
        return {
            ideas: state.adventureIdeas.ideas,
            ideasType: state.adventureIdeas.ideasType,
            adventureQuantity: state.adventureIdeas.adventureQuantity,
            adventureSiteQuantity: state.adventureIdeas.adventureSiteQuantity,
        }
    });


    const renderedAdventureIdeaList = ideas.map((idea, index) => {
        return (
            <li key={index}><b>{idea}</b></li>
        );
    });

    const renderedAdventureIdeas = (ideas.length <= 0)
        ? null
        : <GmDisplayPanel
            heading={ideasType}
            subheading=""
        >
            <ul className="body-text text-left pl-6 list-inside list-disc">
                {renderedAdventureIdeaList}
            </ul>
        </GmDisplayPanel>

    const selectQuantityOptions = [
        { "label": "1", "value": "1" },
        { "label": "2", "value": "2" },
        { "label": "3", "value": "3" },
        { "label": "4", "value": "4" },
        { "label": "5", "value": "5" },
    ];
    const handleNameQuantityChange = (event: React.FormEvent<HTMLSelectElement>) => {
        // console.log("quantity selector changed", (event.currentTarget.value));
        const newQuantity = +(event.currentTarget.value);
        dispatch(setAdventureQuantity(newQuantity));
    };
    const handleSiteQuantityChange = (event: React.FormEvent<HTMLSelectElement>) => {
        // console.log("quantity selector changed", (event.currentTarget.value));
        const newQuantity = +(event.currentTarget.value);
        dispatch(setAdventureSiteQuantity(newQuantity));
    };





    const handleGetNamesClick = (event: React.MouseEvent<HTMLElement>) => {
        // console.log("handleGetNamesClick");
        const newNames = GMTools.getAdventureNames(adventureQuantity);
        dispatch(setAdventureIdeas(newNames));
        dispatch(setAdventureIdeasType("Adventure Names"));
    }

    const handleGetSiteClick = (event: React.MouseEvent<HTMLElement>) => {
        // console.log("handleGetSiteClick");
        const newSiteNames = GMTools.getAdventureSiteNames(adventureSiteQuantity);
        dispatch(setAdventureIdeas(newSiteNames));
        dispatch(setAdventureIdeasType("Site Names"));
    }

    return (
        <ToolContainer>
            <div className="flex">
                <div className="w-1/3 mr-2">
                    <h2>Adventure Ideas</h2>
                    <div>
                        <ButtonWithOptions
                            primary taller selectorSmall
                            options={selectQuantityOptions as ButtonWithOptionsOption[]}
                            selectedValue={adventureQuantity.toString()}
                            onSelectChange={handleNameQuantityChange}
                            onClick={handleGetNamesClick}
                        >
                            <>Adventure Names</>
                        </ButtonWithOptions>
                        <ButtonWithOptions
                            primary taller selectorSmall
                            options={selectQuantityOptions as ButtonWithOptionsOption[]}
                            selectedValue={adventureSiteQuantity.toString()}
                            onSelectChange={handleSiteQuantityChange}
                            onClick={handleGetSiteClick}
                        >
                            <>Site Names</>
                        </ButtonWithOptions>
                    </div>
                </div>
                <div className="w-2/3 mr-2">
                    {renderedAdventureIdeas}
                </div>
            </div>
        </ToolContainer>

    );

}

export default AdventureNames;
