import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit';
import { MapArea, MapAreaType, MapLocation, MapLocationType } from "../../rules/models/MapData";

const emptyMapAreaType = MapArea.getEmptyType();
const emptyMapLocationType = MapLocation.getEmptyType();

const siteMapSlice = createSlice({
    name: 'siteMap',
    initialState: {
        rowDim: 12,
        colDim: 9,
        workingMap: emptyMapAreaType,
        selectedLocation: emptyMapLocationType,
        allowEmptyLocations: false,
        siteSize: '',
        suggestedDangerLevel: 'Whatever makes you happy.'
    },
    reducers: {
        setWorkingMap(state, action: PayloadAction<MapAreaType>) {
            state.workingMap = action.payload;
        },
        setWorkingMapLocation(state, action: PayloadAction<MapLocationType>) {
            const newMapAreaType = MapArea.duplicateType(state.workingMap);
            MapArea.setLocationOnType(newMapAreaType, action.payload);
            state.workingMap = newMapAreaType;
        },
        setSelectedLocation(state, action: PayloadAction<MapLocationType>) {
            state.selectedLocation = action.payload;
        },
        setAllowEmptyLocation(state, action: PayloadAction<boolean>) {
            state.allowEmptyLocations = action.payload;
        },
        setSiteSize(state, action: PayloadAction<string>) {
            state.siteSize = action.payload;
        },
        setSuggestedDangerLevel(state, action: PayloadAction<string>) {
            state.suggestedDangerLevel = action.payload;
        }
    }
});

export const {
    setWorkingMap
    , setWorkingMapLocation
    , setSelectedLocation
    , setAllowEmptyLocation
    , setSiteSize
    , setSuggestedDangerLevel
} = siteMapSlice.actions;
export const siteMapReducer = siteMapSlice.reducer;
