import "./Page.css";
import React from "react";


function ResourcesPage() {

    return (
        <div>
            <div>
                <div className="resources">
                    <h2>Source Material</h2>
                    <div>
                        <ul className="body-text list-inside list-disc">
                            <li className="mb-2">
                                <a className="resources" target="_blank" href="https://www.drivethrurpg.com/product/413713/Shadowdark-RPG-Quickstart-Set" rel="noreferrer">Shadowdark RPG Quickstart Set</a> (free on DriveThruRPG)
                            </li>
                            <li className="mb-2">
                                <a className="resources" target="_blank" href="https://www.thearcanelibrary.com/pages/shadowdark" rel="noreferrer">Shadowdark RPG core rulebook</a> (at the Arcane Library)
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="resources">
                    <h2>Find 3rd Party Offerings</h2>
                    <div>
                        <ul className="body-text list-inside list-disc">
                            <li className="mb-2">
                                <a className="resources" target="_blank" href="https://dark-emporium.com/" rel="noreferrer">Dark Emporium</a>
                            </li>
                            <li className="mb-2">
                                <a className="resources" target="_blank" href="https://docs.google.com/spreadsheets/d/14KbvKlqmoucIZE9IM49mtSKfLczWEd2tZ5NbP-cQXVk/edit#gid=0" rel="noreferrer">Third-Party Products Master List</a> (Google Docs)
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="resources">
                    <h2>Community</h2>
                    <div>
                        <ul className="body-text list-inside list-disc">
                            <li className="mb-2">
                                <a className="resources" target="_blank" href="Discord link: https://discord.gg/abPNTQmjG7" rel="noreferrer">The Arcane Library Discord</a>, home of Shadowdark
                            </li>
                            <li className="mb-2">
                                <a className="resources" target="_blank" href="https://youtube.com/thearcanelibrary" rel="noreferrer">The Arcane Library on YouTube</a>, so much good stuff!
                            </li>
                            <li className="mb-2">
                                <a className="resources" target="_blank" href="https://www.thearcanelibrary.com/pages/shadowdark-rpg-newsletter" rel="noreferrer">Shadowdark News Letter</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="resources">
                    <h2>Character Creators/Apps</h2>
                    <div>
                        <ul className="body-text list-inside list-disc">
                            <li className="mb-2">
                                <a className="resources" target="_blank" href="https://shadowdarklings.net/" rel="noreferrer">ShadowDarklings</a>
                                , by <a className="resources" target="_blank" href="https://www.reddit.com/user/SWooNe" rel="noreferrer">SWooNe</a>
                            </li>
                            <li className="mb-2">
                                <a className="resources" target="_blank" href="https://www.shadowdork.com/" rel="noreferrer">ShadowDork</a>
                                , by <a className="resources" href="mailto:contact@shadowdork.com">Matthew Hill</a>
                            </li>
                            <li className="mb-2">
                                <a className="resources" target="_blank" href="https://sd-character-sheet.onrender.com/" rel="noreferrer">Shadowdark Character Sheet</a>
                                , by maxypoo43#1860
                            </li>
                            
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default ResourcesPage;
