import React, { PropsWithChildren } from "react";
import "./GmDisplayPanel.css";


type GmToolPanelProps = {
    heading: string;
    subheading?: string;
}



function GmDisplayPanel(props: PropsWithChildren<GmToolPanelProps>) {
    const {
        heading,
        subheading,
        children } = props;

    // TODO: STOP USING HEADING FOR THE ID
    return (
        <div
            id={heading}
            className="grow text-center bg-white rounded-lg border border-gray-400 p-2"
        >
            <h3>{heading}</h3>
            <h4>{subheading}</h4>
            <div className="px-4">
                {children}
            </div>
        </div>
    );

}

export default GmDisplayPanel;
