import MonsterReferenceTable from "../rules/MonsterReferenceTable";
import CursedScrollMonsterReferenceTable from "../rules/CursedScrollMonsterReferenceTable";
import { MonsterDataType } from "../rules/models/MonsterData";
import Dice from "./Dice";


class MonsterSearchOptions {
    public includeCSMonsters: boolean = false;
    public levelLower: number = -1;
    public levelUpper: number = -1;
    public biomeTags: string[] = [];
    public moveTags: string[] = [];
    public attackTags: string[] = [];
    public typeTags: string[] = [];
    public keywords: string = '';

}

class MonsterManual {

    public static getAllBiomeTags(): string[] {
        // console.log("GET ALL BIOME TYPES");

        let biomeTypes: string[] = [];
        MonsterReferenceTable.entries.forEach((m) => {
            const biomeArray = m.biome.split(",");
            biomeTypes = biomeTypes.concat(biomeArray);
        });

        let result = [...new Set(biomeTypes)].sort((a, b) => a.localeCompare(b));
        result = result.filter((r) => { return r !== '' });
        result = result.filter((r) => { return r !== '*' });

        // console.log(`getAllBiomeTypes: ${result}`);
        return result;
    }

    public static getAllMoveTags(): string[] {
        // console.log("GET ALL MOVE TYPES");

        // const start = Date.now();

        const moveTypes = MonsterReferenceTable.entries.map((m) => {
            return m.move;
        });
        let result = [...new Set(moveTypes)].sort((a, b) => a.localeCompare(b));
        result = result.filter((r) => { return r !== '' });

        // const end = Date.now();
        // console.log(`getAllMoveTypes: ${result}`);
        // console.log(`getAllMoveTypes start: ${start}`);
        // console.log(`getAllMoveTypes end: ${end}`);
        // console.log(`getAllMoveTypes duaation: ${end - start}`);

        return result;
    }

    public static getAllAttackTags(): string[] {
        // console.log("GET ALL ATTACK TYPES");

        let attackTypes: string[] = [];
        MonsterReferenceTable.entries.forEach((m) => {
            const attackArray = m.attack.split(",");
            attackTypes = attackTypes.concat(attackArray);
        });

        let result = [...new Set(attackTypes)].sort((a, b) => a.localeCompare(b));
        result = result.filter((r) => { return r !== '' });

        // console.log(`getAllAttackTypes: ${result}`);
        return result;
    }

    public static getAllTypeTags(): string[] {
        // console.log("GET ALL TAG TYPES");

        let tagTypes: string[] = [];
        MonsterReferenceTable.entries.forEach((m) => {
            const tagArray = m.tags.split(",");
            tagTypes = tagTypes.concat(tagArray);
        });

        let result = [...new Set(tagTypes)].sort((a, b) => a.localeCompare(b));
        result = result.filter((r) => { return r !== '' });

        // console.log(`getAllTagTypes: ${result}`);
        return result;
    }









    private static getMonstersByLevel(monsters: MonsterDataType[], lowLevel: number, highLevel: number): MonsterDataType[] {

        let result: MonsterDataType[] = [];

        if (lowLevel > -1 && highLevel > -1) {
            // both lower and upper bounds
            result = monsters.filter((m) => {
                if (m.level === "*") {
                    return true;
                }
                const mLevel: number = +(m.level);
                return (mLevel >= lowLevel && mLevel <= highLevel);
            });
        }
        else if (lowLevel > -1) {
            // lower bound only
            result = monsters.filter((m) => {
                if (m.level === "*") {
                    return true;
                }
                const mLevel: number = +(m.level);
                return (mLevel >= lowLevel);
            });
        }
        else if (highLevel > -1) {
            // uppber bound only
            result = monsters.filter((m) => {
                if (m.level === "*") {
                    return true;
                }
                const mLevel: number = +(m.level);
                return (mLevel <= highLevel);
            });
        }
        else {
            // no levels specified
            result = monsters;
        }

        return result;
    }


    private static filterMonstersByMovement(monsters: MonsterDataType[], moveTypes: string[]): MonsterDataType[] {
        // console.log(`*** filterMonstersByMovement`);
        // console.log(`*** filterMonstersByMovement monsters.length = ${monsters.length}`);
        // console.log(`*** filterMonstersByMovement moveTypes.length = ${moveTypes.length}`);
        // console.log(`*** filterMonstersByMovement moveTypes = ${moveTypes}`);

        if (moveTypes.length === 0) {
            return monsters;
        }

        let result = monsters.filter((m) => {
            const monsterMoves = m.move.split(",");
            let monsterResult = false;
            moveTypes.forEach((moveType) => {
                return monsterResult = monsterResult || (monsterMoves.indexOf(moveType) > -1);
            });
            return monsterResult;
        });
        return result;
    }

    private static filterMonstersByKeyword(monsters: MonsterDataType[], keywords: string): MonsterDataType[] {
        // console.log(`*** filterMonstersByMovement`);
        // console.log(`*** filterMonstersByMovement monsters.length = ${monsters.length}`);
        // console.log(`*** filterMonstersByMovement moveTypes.length = ${moveTypes.length}`);
        // console.log(`*** filterMonstersByMovement moveTypes = ${moveTypes}`);

        if (keywords.trim() === '') {
            return monsters;
        }

        let keywordList = keywords.toLowerCase().trim().split(" ");
        // console.log(`filterMonstersByKeyword.keywordList.lenght = ${keywordList.length}`);
        keywordList = keywordList.filter((k) => { return k.trim() !== ''; });
        // console.log(`filterMonstersByKeyword.keywordList = ${keywordList}`);
        keywordList.map((k) => k.trim());

        // console.log(`filterMonstersByKeyword.keywordList = ${keywordList}`);


        let result = monsters.filter((m) => {
            let monsterResult = false;
            keywordList.forEach((keyword) => {
                return monsterResult = monsterResult || m.name.toLowerCase().includes(keyword) || m.statblock.toLowerCase().includes(keyword);
            });
            return monsterResult;
        });
        return result;
    }

    private static filterMonstersByAttack(monsters: MonsterDataType[], attackTypes: string[]): MonsterDataType[] {
        // console.log(`*** filterMonstersByAttack`);
        // console.log(`*** filterMonstersByAttack monsters.length = ${monsters.length}`);
        // console.log(`*** filterMonstersByAttack attackTypes.length = ${attackTypes.length}`);
        // console.log(`*** filterMonstersByAttack attackTypes = ${attackTypes}`);

        if (attackTypes.length === 0) {
            return monsters;
        }

        let result = monsters.filter((m) => {
            const monsterAttacks = m.attack.split(",");
            let monsterResult = false;
            attackTypes.forEach((attackType) => {
                return monsterResult = monsterResult || (monsterAttacks.indexOf(attackType) > -1);
            });
            return monsterResult;
        });
        return result;
    }

    private static filterMonstersByBiome(monsters: MonsterDataType[], biomeTags: string[]): MonsterDataType[] {
        // console.log(`*** filterMonstersByBiome`);
        // console.log(`*** filterMonstersByBiome monsters.length = ${monsters.length}`);
        // console.log(`*** filterMonstersByBiome biomeTags.length = ${biomeTags.length}`);
        // console.log(`*** filterMonstersByBiome biomeTags = ${biomeTags}`);

        if (biomeTags.length === 0) {
            return monsters;
        }

        let result = monsters.filter((m) => {
            const monsterBiomes = m.biome.split(",");
            let monsterResult = (monsterBiomes.indexOf("*") > -1 ? true : false);
            biomeTags.forEach((biomeTag) => {
                return monsterResult = monsterResult || (monsterBiomes.indexOf(biomeTag) > -1);
            });
            return monsterResult;
        });
        return result;
    }

    private static filterMonstersByType(monsters: MonsterDataType[], typeTags: string[]): MonsterDataType[] {
        // console.log(`*** filterMonstersByBiome`);
        // console.log(`*** filterMonstersByBiome monsters.length = ${monsters.length}`);
        // console.log(`*** filterMonstersByBiome biomeTags.length = ${biomeTags.length}`);
        // console.log(`*** filterMonstersByBiome biomeTags = ${biomeTags}`);

        if (typeTags.length === 0) {
            return monsters;
        }

        let result = monsters.filter((m) => {
            const monsterTypes = m.tags.split(",");
            let monsterResult = false;
            typeTags.forEach((typeTag) => {
                return monsterResult = monsterResult || (monsterTypes.indexOf(typeTag) > -1);
            });
            return monsterResult;
        });
        return result;
    }



    public static searchMonsters(searchOptions: MonsterSearchOptions): MonsterDataType[] {

        let monsterPool: MonsterDataType[] = MonsterReferenceTable.entries;
        if (searchOptions.includeCSMonsters) {
            monsterPool = monsterPool.concat(CursedScrollMonsterReferenceTable.entries);
        }


        let levelAndBiome = MonsterManual.getMonstersByLevel(monsterPool, searchOptions.levelLower, searchOptions.levelUpper);
        levelAndBiome = MonsterManual.filterMonstersByBiome(levelAndBiome, searchOptions.biomeTags);

        levelAndBiome = MonsterManual.filterMonstersByKeyword(levelAndBiome, searchOptions.keywords);

        // return levelAndBiome;

        // let results = MonsterManual.getMonstersByLevel(searchOptions.levelLower, searchOptions.levelUpper);

        // results = MonsterManual.filterMonstersByMovement(results, searchOptions.moveTags);
        // results = MonsterManual.filterMonstersByAttack(results, searchOptions.attackTags);
        // return results;

        let byMove = [] as MonsterDataType[];
        if (searchOptions.moveTags.length > 0) {
            byMove = MonsterManual.filterMonstersByMovement(levelAndBiome, searchOptions.moveTags);
        }

        let byAttack = [] as MonsterDataType[];
        if (searchOptions.attackTags.length > 0) {
            byAttack = MonsterManual.filterMonstersByAttack(levelAndBiome, searchOptions.attackTags);
        }

        let byType = [] as MonsterDataType[];
        if (searchOptions.typeTags.length > 0) {
            byType = MonsterManual.filterMonstersByType(levelAndBiome, searchOptions.typeTags);
        }

        // console.log(`attackTags = ${searchOptions.attackTags}`);
        // console.log(`moveTags = ${searchOptions.moveTags}`);
        // console.log(`typeTags = ${searchOptions.typeTags}`);

        if ((searchOptions.attackTags.length
            + searchOptions.moveTags.length
            + searchOptions.typeTags.length) > 0) {
            // console.log('at least one optional tag');

            let result = byMove.concat(byAttack).concat(byType);
            result = [...new Set(result)].sort((a, b) => a.name.localeCompare(b.name));
            return result;

        }
        else {
            // console.log('no optional tags');
            // console.log("levelAndBiome");
            // console.log(levelAndBiome.length);
            levelAndBiome = [...levelAndBiome].sort((a, b) => a.name.localeCompare(b.name));
            return levelAndBiome;
        }
    }

    public static searchForNMonsters(searchOptions: MonsterSearchOptions, nMonsters: number): MonsterDataType[] {

        const monsterPool = MonsterManual.searchMonsters(searchOptions);
        let result = [] as MonsterDataType[];

        for (let m = 0; m < nMonsters; m++) {
            const m = Dice.dCustom(0, monsterPool.length - 1);
            // console.log(`searchForNMonsters = [${m}]`);
            result.push(monsterPool[m]);
        }

        return result;
    }


}

export { MonsterSearchOptions };
export default MonsterManual;
