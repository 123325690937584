import AdventureGeneratorTableData from "../rules/AdventureGeneratorTableData";
import AdventureSiteNameTableData from "../rules/AdventureSiteNameTableData";
import CdNpcAncestralNamesTableData from "../rules/CDNpcAncestralNamesTableData";
import CdNpcNameTableData from "../rules/CDNpcNamesTableData";
import HazardTableData from "../rules/HazardTableData";
import { MagicItemDescriptionTableData, MagicItemSimpleTableData } from "../rules/MagicItemTableData";
// import NpcAncestralNamesTableData from "../rules/NpcAncestralNamesTableData";
// import NpcNameTableData from "../rules/NpcNameTableData";
import NpcTableData from "../rules/NpcTableData";
import RandomEncounterTableData from "../rules/RandomEncounterTableData";
import RivalsTableData from "../rules/RivalsTableData";
import ShodowDarkMapsTableData from "../rules/ShodowDarkMapsTableData";
import ShopTableData from "../rules/ShopTableData";
import TavernDrinksTableData from "../rules/TavernDrinksTableData";
import TavernTableData from "../rules/TavernTableData";
import TrapTableData from "../rules/TrapsTableData";
import EncounterData, { EncounterDataType } from "../rules/models/EncounterData";
import HazardData, { HazardDataType } from "../rules/models/HazardData";
import MagicItemData, { MagicItemDataType, MagicItemType } from "../rules/models/MagicItemData";
import { MapLocation } from "../rules/models/MapData";
import NpcData, { NpcDataType } from "../rules/models/NpcData";
import RivalCrawlerParty, { RivalCrawler, RivalCrawlerPartyType } from "../rules/models/RivalCrawlersData";
import ShopData, { ShopDataType } from "../rules/models/ShopData";
import SimpleEntry from "../rules/models/SimpleEntry";
import SimplestEntry from "../rules/models/SimplestEntry";
import TavernData, { TavernDataType, TavernDrinkDataType, TavernDrinkEntry } from "../rules/models/TavernData";
import TrapData, { TrapDataType } from "../rules/models/TrapData";
import Dice from "./Dice";
import TwoFactorTableManager from "./TwoFactorTableManager";


class GMTools {

    // static NpcAncestralNamesTable: TwoFactorTableManager<SimplestEntry> = new TwoFactorTableManager(NpcAncestralNamesTableData);
    static CdNpcAncestralNamesTable: TwoFactorTableManager<SimplestEntry> = new TwoFactorTableManager(CdNpcAncestralNamesTableData);
    // static NpcNamesTable: TwoFactorTableManager<SimplestEntry> = new TwoFactorTableManager(NpcNameTableData);
    static CdNpcNamesTable: TwoFactorTableManager<SimplestEntry> = new TwoFactorTableManager(CdNpcNameTableData);
    static NpcTable: TwoFactorTableManager<SimplestEntry> = new TwoFactorTableManager(NpcTableData);

    static EncounterTable: TwoFactorTableManager<SimplestEntry> = new TwoFactorTableManager(RandomEncounterTableData);

    static TrapsTable: TwoFactorTableManager<SimplestEntry> = new TwoFactorTableManager(TrapTableData);
    static HazardsTable: TwoFactorTableManager<SimplestEntry> = new TwoFactorTableManager(HazardTableData);

    static RivalsTable: TwoFactorTableManager<SimplestEntry> = new TwoFactorTableManager(RivalsTableData);

    static ShopsTable: TwoFactorTableManager<SimplestEntry> = new TwoFactorTableManager(ShopTableData);
    static TavernTable: TwoFactorTableManager<SimplestEntry> = new TwoFactorTableManager(TavernTableData);
    static TavernDrinksTable: TwoFactorTableManager<TavernDrinkEntry> = new TwoFactorTableManager(TavernDrinksTableData);

    static SdMapsTable: TwoFactorTableManager<SimplestEntry> = new TwoFactorTableManager(ShodowDarkMapsTableData);

    static AdventureGeneratorTable: TwoFactorTableManager<SimplestEntry> = new TwoFactorTableManager(AdventureGeneratorTableData);
    static AdventureSiteNameTable: TwoFactorTableManager<SimplestEntry> = new TwoFactorTableManager(AdventureSiteNameTableData);

    static MagicItemSimpleTable: TwoFactorTableManager<SimplestEntry> = new TwoFactorTableManager(MagicItemSimpleTableData);
    static MagicItemDescriptionTable: TwoFactorTableManager<SimpleEntry> = new TwoFactorTableManager(MagicItemDescriptionTableData);




    public static getRandomEncounter(chaMod: number, encounterTableDie: string): EncounterDataType {
        const result = new EncounterData();

        result.when = Date.now();
        result.encounterRoll = Dice.roll("1d6");
        result.charismaMod = chaMod;
        result.distance = GMTools.EncounterTable.getRandomResult("distance").name;
        result.activity = GMTools.EncounterTable.getRandomResult("activity").name;
        result.reactionRoll = Dice.roll("2d6");
        result.reaction = GMTools.EncounterTable.getResult("reaction", result.reactionRoll + chaMod).name;
        result.treasure = "None";

        if (encounterTableDie !== '') {
            result.encounterTableRoll = Dice.roll(encounterTableDie);
        }

        if (Dice.dCoinFlipBool()) {
            result.treasure = "Yes!";
        }
        return result.getCleanType();
    }

    public static getEncounter(chaMod: number, encounterTableDie: string): EncounterDataType {
        const result = GMTools.getRandomEncounter(chaMod, encounterTableDie);
        result.encounterRoll = 1;
        return result;
    }

    public static updateEncounterReaction(encounter: EncounterDataType, chaMod: number): EncounterDataType {
        let result = { ...encounter };
        result.charismaMod = chaMod;
        result.reaction = GMTools.EncounterTable.getResult("reaction", result.reactionRoll + chaMod).name;
        return result;
    }


    // TRAPS AND HAZARDS
    public static getTrap(): TrapDataType {
        const result = new TrapData();

        result.trap = GMTools.TrapsTable.getRandomResult("trap").name;
        result.trigger = GMTools.TrapsTable.getRandomResult("trigger").name;
        // result.damage = GMTools.TrapsTable.getRandomResult("damage").name;
        result.damage = "";

        return result.getCleanType();
    }

    public static getTraps(n: number): TrapDataType[] {
        let result: TrapDataType[] = [];
        for (let i = 0; i < n; i++) {
            result.push(GMTools.getTrap());
        }
        return result;
    }

    public static getHazard(): HazardDataType {
        const result = new HazardData();

        const hazardTypes = ["Movement", "Damage", "Weaken"]
        const type = hazardTypes[Dice.dCustom(0, 2)];

        result.type = type;
        result.hazard = GMTools.HazardsTable.getRandomResult(type.toLowerCase()).name;

        return result.getCleanType();
    }

    public static getHazards(n: number): HazardDataType[] {
        let result: HazardDataType[] = [];
        for (let i = 0; i < n; i++) {
            result.push(GMTools.getHazard());
        }
        return result;
    }


    public static getNpcAncestryNames(n: number, ancestry: string): string[] {
        let result: string[] = [];
        for (let i = 0; i < n; i++) {
            const newName = GMTools.CdNpcAncestralNamesTable.getRandomResult(ancestry).name;
            if (result.indexOf(newName) === -1) {
                // name is new to the list
                result.push(newName);
            }
            else {
                // generated name was a duplicate, so "re-do"
                i--;
            }
        }
        return result;
    }


    public static getNpc(ancestry?: string): NpcDataType {
        const useAncestry = (ancestry === undefined || ancestry === "")
            ? GMTools.NpcTable.getRandomResult("ancestry").name
            : ancestry;

        const result = new NpcData();
        // const npcNameLength = Dice.dCustom(2, 4);
        // result.name = GMTools.getNpcName(npcNameLength);

        result.ancestry = useAncestry;
        result.name = GMTools.CdNpcAncestralNamesTable.getRandomResult(result.ancestry).name;
        result.ancestryName = GMTools.CdNpcAncestralNamesTable.getRandomResult(result.ancestry).name;

        result.age = GMTools.NpcTable.getRandomResult("age").name;
        result.alignment = GMTools.NpcTable.getRandomResult("alignment").name;
        result.wealth = GMTools.NpcTable.getRandomResult("wealth").name;

        const jobType = ["low jobs", "standard jobs"];
        switch (result.wealth.toLowerCase()) {
            case 'poor':
                result.job = GMTools.NpcTable.getRandomResult("low jobs").name;
                break;
            case 'standard':
                const j = Dice.dCustom(0, 1);
                result.job = GMTools.NpcTable.getRandomResult(jobType[j]).name;
                break;
            case 'wealthy':
                result.job = GMTools.NpcTable.getRandomResult("standard jobs").name;
                break;
            default:
                result.job = GMTools.NpcTable.getRandomResult("low jobs").name;
                break;
        }

        result.appearance = GMTools.NpcTable.getRandomResult("appearance").name;
        result.behavior = GMTools.NpcTable.getRandomResult("behavior").name;
        result.secret = GMTools.NpcTable.getRandomResult("secret").name;

        return result.getCleanType();
    }

    public static getNpcs(quantity: number, ancestry?: string): NpcDataType[] {
        let result: NpcDataType[] = [];
        for (let i = 0; i < quantity; i++) {
            result.push(GMTools.getNpc(ancestry));
        }
        return result;
    }



    // RIVALS
    public static getRivalCrawlerParty(size: number): RivalCrawlerPartyType {
        const result = new RivalCrawlerParty();

        let partySize = size === 0 ? Dice.roll("1d4+1") : size;
        // party stats

        result.size = partySize;
        result.alignment = GMTools.RivalsTable.getRandomResult("alignment").name;
        result.renown = GMTools.RivalsTable.getRandomResult("renown").name;
        result.secret = GMTools.RivalsTable.getRandomResult("secret").name;
        result.wealth = GMTools.RivalsTable.getRandomResult("wealth").name;
        result.knownFor = GMTools.RivalsTable.getRandomResult("knownfor").name;
        result.name = GMTools.getRivalCrawlerPartyName();

        let tacticTableName = "tactics";
        tacticTableName = tacticTableName.concat(result.alignment.toLowerCase());
        result.tactic = GMTools.RivalsTable.getRandomResult(tacticTableName).name;

        for (let m = 0; m < result.size; m++) {
            const crawler = GMTools.getRivalPartyMember(result.alignment);
            result.members.push(crawler);
        }

        return result.getCleanType();
    }

    public static getRivalCrawlerPartyName(): string {
        let result = "";

        const nameStart = GMTools.RivalsTable.getRandomResult("name-start").name;
        const nameEnd = GMTools.RivalsTable.getRandomResult("name-end").name;
        result = nameStart.concat(` ${nameEnd}`);

        return result;
    }

    public static getRivalPartyMember(alignment: string): RivalCrawler {
        const result = new RivalCrawler();

        result.alignment = alignment;
        result.ancestry = GMTools.RivalsTable.getRandomResult("ancestry").name;
        result.class = GMTools.RivalsTable.getRandomResult("class").name;
        result.level = Dice.roll("d6");
        result.name = GMTools.CdNpcAncestralNamesTable.getRandomResult(result.ancestry).name;

        return result;
    }

    // SHOPS

    public static getShopName(): string {
        const resultA = GMTools.ShopsTable.getRandomResult("namestart").name;
        const resultB = GMTools.ShopsTable.getRandomResult("nameend").name;
        return resultA.concat(" ").concat(resultB);
    }

    public static getShop(tier?: string): ShopDataType {
        const shopTiers: string[] = ["Poor", "Standard", "Wealthy"];
        const useTier = (tier === undefined || tier === "")
            ? shopTiers[Dice.dCustom(0, 2)]
            : tier;

        const result = new ShopData();
        result.tier = useTier;
        result.name = GMTools.getShopName();
        result.type = GMTools.ShopsTable.getRandomResult(useTier.toLowerCase()).name;
        result.knownFor = GMTools.ShopsTable.getRandomResult("knownfor").name;
        result.customer = GMTools.ShopsTable.getRandomResult("interestingcustomer").name;

        return result.getCleanType();
    }

    public static getShops(quantity: number, tier?: string): ShopDataType[] {
        let result: ShopDataType[] = [];
        for (let i = 0; i < quantity; i++) {
            result.push(GMTools.getShop(tier));
        }
        return result;
    }

    // TAVERNS

    public static getTavernName(): string {
        const resultA = GMTools.TavernTable.getRandomResult("name a").name;
        const resultB = GMTools.TavernTable.getRandomResult("name b").name;
        return resultA.concat(" ").concat(resultB);
    }

    public static getTavernMenuItem(tier: string): string {
        let item = "";
        let cost = "";
        switch (tier.toLowerCase()) {
            case "poor":
                item = GMTools.TavernTable.getRandomResult("poor menu").name;
                cost = `${Dice.roll("d4")} cp`;
                break;
            case "standard":
                item = GMTools.TavernTable.getRandomResult("standard menu").name;
                cost = `${Dice.roll("d6")} sp`;
                break;
            case "wealthy":
                item = GMTools.TavernTable.getRandomResult("wealthy menu").name;
                cost = `${Dice.roll("d8")} gp`;
                break;
            default:
                break;
        }
        return `${item} (${cost})`;
    }

    public static getTavernDrinkItem(tier: string): TavernDrinkDataType {
        // let item = "";
        // let rollResult = 0;
        // switch (tier.toLowerCase()) {
        //     case "poor":
        //         rollResult = Dice.roll("d6");
        //         break;
        //     case "standard":
        //         rollResult = Dice.roll("d12");
        //         break;
        //     case "wealthy":
        //         rollResult = Dice.roll("2d6");
        //         break;
        //     default:
        //         break;
        // }
        // item = GMTools.TavernTable.getResult("drink menu", rollResult).name;

        const drinkTier = `${tier} drink menu`;
        const result = GMTools.TavernDrinksTable.getRandomResult(drinkTier);
        return result;
    }


    //
    // Get an array of Tavern Menu entries without duplicates.
    //
    public static getTavernFoodMenu(nItems: number, tier: string): string[] {
        let result: string[] = [];
        for (let i = 0; i < nItems; i++) {
            const newMenuItem = GMTools.getTavernMenuItem(tier);
            if (result.indexOf(newMenuItem) === -1) {
                // newMenuItem is new to the list
                result.push(newMenuItem);
            }
            else {
                // generated newMenuItem was a duplicate, so "re-do"
                i--;
            }
        }
        return result;
    }

    //
    // Get an array of Tavern Drink entries without duplicates.
    //
    public static getTavernDrinkMenu(nItems: number, tier: string): TavernDrinkDataType[] {
        let result: TavernDrinkDataType[] = [];
        for (let i = 0; i < nItems; i++) {
            const newMenuItem = GMTools.getTavernDrinkItem(tier);
            const duplicate = result.find(d => d.name === newMenuItem.name);
            if (duplicate === undefined) {
                // newMenuItem is new to the list
                result.push(newMenuItem);
            }
            else {
                // generated newMenuItem was a duplicate, so "re-do"
                i--;
            }
        }
        return result;
    }



    public static getTavern(tier?: string): TavernDataType {
        const tavernTiers: string[] = ["Poor", "Standard", "Wealthy"];
        let useTier = (tier === undefined || tier === "")
            ? tavernTiers[Dice.dCustom(0, 2)]
            : tier;

        const result = new TavernData();
        result.tier = useTier;
        result.name = GMTools.getTavernName();
        result.knownFor = GMTools.TavernTable.getRandomResult("known for").name;

        useTier = useTier.toLowerCase();
        switch (useTier.toLowerCase()) {
            case "poor":
            case "standard":
                const foodMenu = GMTools.getTavernFoodMenu(3, useTier);
                result.menu = JSON.parse(JSON.stringify(foodMenu));

                const drinkMenu = GMTools.getTavernDrinkMenu(3, useTier);
                result.drinks = JSON.parse(JSON.stringify(drinkMenu));
                break;
            // case "standard":
            //     // result.menu.push(GMTools.getTavernMenuItem("poor"));
            //     result.menu.push(GMTools.getTavernMenuItem(useTier));
            //     result.menu.push(GMTools.getTavernMenuItem(useTier));
            //     result.menu.push(GMTools.getTavernMenuItem(useTier));
            //     result.drinks.push(GMTools.getTavernDrinkItem(useTier));
            //     result.drinks.push(GMTools.getTavernDrinkItem(useTier));
            //     result.drinks.push(GMTools.getTavernDrinkItem(useTier));
            //     break;
            case "wealthy":
                const foodMenuStandard = GMTools.getTavernFoodMenu(1, "standard");
                const foodMenuWealthy = GMTools.getTavernFoodMenu(2, useTier);
                result.menu = foodMenuStandard.concat(foodMenuWealthy);

                const drinkMenuWealthy = GMTools.getTavernDrinkMenu(3, useTier);
                result.drinks = JSON.parse(JSON.stringify(drinkMenuWealthy));
                // result.menu.push(GMTools.getTavernMenuItem("standard"));
                // result.menu.push(GMTools.getTavernMenuItem(useTier));
                // result.menu.push(GMTools.getTavernMenuItem(useTier));
                // result.drinks.push(GMTools.getTavernDrinkItem(useTier));
                // result.drinks.push(GMTools.getTavernDrinkItem(useTier));
                // result.drinks.push(GMTools.getTavernDrinkItem(useTier));
                break;
            default:
                break;
        }

        return result.getCleanType();
    }

    public static getTaverns(quantity: number, tier?: string): TavernDataType[] {
        let result: TavernDataType[] = [];
        for (let i = 0; i < quantity; i++) {
            result.push(GMTools.getTavern(tier));
        }
        return result;
    }

    // SHADOWDARK MAPS LOCATIONS

    public static getShadowDarkMapLocation(allowEmpty: boolean): MapLocation {
        const result = new MapLocation();
        result.present = true;

        do {
            result.type = GMTools.SdMapsTable.getRandomResult("room type").name;
        } while (!allowEmpty && result.type.toLowerCase() === 'empty');

        const typeKey = result.type.toLowerCase();
        switch (typeKey) {
            case "empty":
                result.description = "Nothing here"
                break;

            case "minor hazard":
            case "npc":
            case "major hazard":
            case "treasure":
            case "boss monster":
            case "trick/trap":
            case "faction":
            case "npcs":
            case "monster":
            case "flora/fauna":
            case "feature":
            case "objective":
                result.description = GMTools.SdMapsTable.getRandomResult(typeKey).name;
                break;

            case "trap":
            case "solo monster":
            case "monster mob":
                const partA = GMTools.SdMapsTable.getRandomResult(`${typeKey} a`).name;
                const partB = GMTools.SdMapsTable.getRandomResult(`${typeKey} b`).name;
                result.description = `${partA} ${partB}`;
                break;
            default:
                result.description = "something bad happened.";
                throw Error(`GMTools.getShadowDarkMapLocation: unknown type "${result.type.toLowerCase()}"`);
        }
        return result;

    }


    // ADVENTURE INSPIRATION
    public static getAdventureName(): string {
        const partA = GMTools.AdventureGeneratorTable.getRandomResult("adventure generator a").name;
        const partB = GMTools.AdventureGeneratorTable.getRandomResult("adventure generator b").name;
        const partC = GMTools.AdventureGeneratorTable.getRandomResult("adventure generator c").name;

        const result = `${partA} ${partB} ${partC}`
        return result;
    }

    public static getAdventureNames(quantity: number): string[] {
        let result: string[] = [];
        for (let i = 0; i < quantity; i++) {
            result.push(GMTools.getAdventureName());
        }
        return result;
    }

    public static getAdventureSiteName(): string {
        const partA = GMTools.AdventureSiteNameTable.getRandomResult("adventure site name a").name;
        const partB = GMTools.AdventureSiteNameTable.getRandomResult("adventure site name b").name;
        const partC = GMTools.AdventureSiteNameTable.getRandomResult("adventure site name c").name;

        const result = `${partA} ${partB} ${partC}`
        return result;
    }

    public static getAdventureSiteNames(quantity: number): string[] {
        let result: string[] = [];
        for (let i = 0; i < quantity; i++) {
            result.push(GMTools.getAdventureSiteName());
        }
        return result;
    }

    public static getAdventureDangerLevel(): string {
        let result = GMTools.SdMapsTable.getRandomResult("danger level").name;
        return result;
    }


    // MAGIC ITEMS
    public static getMagicItem(itemType: MagicItemType, allowPriestSpells: boolean): MagicItemDataType {
        const result = new MagicItemData();

        result.itemType = (itemType === undefined || itemType === MagicItemType.Empty)
            ? MagicItemType[GMTools.MagicItemSimpleTable.getRandomResult("magic item type").name as keyof typeof MagicItemType]
            : itemType;


        result.nBenefits = +(GMTools.MagicItemSimpleTable.getRandomResult("benefit quantity").name);
        result.nCurses = +(GMTools.MagicItemSimpleTable.getRandomResult("curse quantity").name);
        result.nVirtues = +(GMTools.MagicItemSimpleTable.getRandomResult("virtue quantity").name);
        result.nFlaws = +(GMTools.MagicItemSimpleTable.getRandomResult("flaw quantity").name);

        for (let v = 0; v < result.nVirtues; v++) {
            const virtue: SimpleEntry = GMTools.MagicItemDescriptionTable.getRandomResult("personality virtue");
            const itemVirtue = MagicItemData.getNamedEffect(virtue.name, virtue.description);
            result.personalityVirtues.push(itemVirtue);
        }
        for (let f = 0; f < result.nFlaws; f++) {
            const flaw: SimpleEntry = GMTools.MagicItemDescriptionTable.getRandomResult("personality flaw");
            const itemFlaw = MagicItemData.getNamedEffect(flaw.name, flaw.description);
            result.personalityFlaws.push(itemFlaw);
        }
        if (result.nVirtues + result.nFlaws > 0) {
            result.personalityTrait = GMTools.MagicItemSimpleTable.getRandomResult("personality trait").name;
            result.personalityAlignment = GMTools.MagicItemSimpleTable.getRandomResult("alignment").name;
        }

        let spellClass = "wizard";

        switch (result.itemType) {
            case MagicItemType.Armor:
                // Type
                // Bonus
                // Feature
                // Benefits
                // Curses
                result.type  = GMTools.MagicItemSimpleTable.getRandomResult("armor type").name;
                result.bonus = GMTools.MagicItemSimpleTable.getRandomResult("armor bonus").name;
                result.feature = GMTools.MagicItemSimpleTable.getRandomResult("armor feature").name;
                for (let b = 0; b < result.nBenefits; b++) {
                    const benefit: SimpleEntry = GMTools.MagicItemDescriptionTable.getRandomResult("armor benefit");
                    const itemBenefit = MagicItemData.getNamedEffect(benefit.name, benefit.description);
                    result.benefits.push(itemBenefit);
                }
                for (let c = 0; c < result.nCurses; c++) {
                    const curse: SimpleEntry = GMTools.MagicItemDescriptionTable.getRandomResult("armor curse");
                    const itemCurse = MagicItemData.getNamedEffect(curse.name, curse.description);
                    result.curses.push(itemCurse);
                }
                
                break;

            case MagicItemType.Potion:
                // Container
                // Liquid
                // Smell/Taste
                // Benefits
                // Curses
                result.type = result.itemType;
                result.container = GMTools.MagicItemSimpleTable.getRandomResult("potion container").name;
                result.liquid = GMTools.MagicItemSimpleTable.getRandomResult("potion liquid").name;
                result.tasteSmell = GMTools.MagicItemSimpleTable.getRandomResult("potion tastesmell").name;
                for (let b = 0; b < result.nBenefits; b++) {
                    const benefit: SimpleEntry = GMTools.MagicItemDescriptionTable.getRandomResult("potion benefit");
                    const itemBenefit = MagicItemData.getNamedEffect(benefit.name, benefit.description);
                    result.benefits.push(itemBenefit);
                }
                for (let c = 0; c < result.nCurses; c++) {
                    const curse: SimpleEntry = GMTools.MagicItemDescriptionTable.getRandomResult("potion curse");
                    const itemCurse = MagicItemData.getNamedEffect(curse.name, curse.description);
                    result.curses.push(itemCurse);
                }
                
                 break;

            case MagicItemType.Utility:
                // Type
                // Feature
                // Benefits
                // Curses
                result.type = GMTools.MagicItemSimpleTable.getRandomResult("utility item type").name;
                result.feature = GMTools.MagicItemSimpleTable.getRandomResult("utility item feature").name;
                for (let b = 0; b < result.nBenefits; b++) {
                    const benefit: SimpleEntry = GMTools.MagicItemDescriptionTable.getRandomResult("utility benefit");
                    const itemBenefit = MagicItemData.getNamedEffect(benefit.name, benefit.description);
                    result.benefits.push(itemBenefit);
                }
                for (let c = 0; c < result.nCurses; c++) {
                    const curse: SimpleEntry = GMTools.MagicItemDescriptionTable.getRandomResult("utility curse");
                    const itemCurse = MagicItemData.getNamedEffect(curse.name, curse.description);
                    result.curses.push(itemCurse);
                }
                
                break;

            case MagicItemType.Scroll:
                // Feature
                // SpellTier
                // Spell
                // Benefits
                // Curses

                if (allowPriestSpells) {
                    spellClass = GMTools.MagicItemSimpleTable.getRandomResult("spell class").name;
                }
                result.type = "Scroll";
                result.feature = GMTools.MagicItemSimpleTable.getRandomResult("scroll features").name;
                result.spellTier = GMTools.MagicItemSimpleTable.getRandomResult("spell tier").name;
                result.spell = GMTools.MagicItemSimpleTable.getRandomResult(`${spellClass} spell tier ${result.spellTier}`).name;
                
                const scrollBenefitTable = GMTools.MagicItemSimpleTable.getRandomResult("scroll wand beneft table").name;
                const scrollCurseTable = GMTools.MagicItemSimpleTable.getRandomResult("scroll wand curse table").name;
                
                for (let b = 0; b < result.nBenefits; b++) {
                    const benefit: SimpleEntry = GMTools.MagicItemDescriptionTable.getRandomResult(scrollBenefitTable);
                    const itemBenefit = MagicItemData.getNamedEffect(benefit.name, benefit.description);
                    result.benefits.push(itemBenefit);
                }
                for (let c = 0; c < result.nCurses; c++) {
                    const curse: SimpleEntry = GMTools.MagicItemDescriptionTable.getRandomResult(scrollCurseTable);
                    const itemCurse = MagicItemData.getNamedEffect(curse.name, curse.description);
                    result.curses.push(itemCurse);
                }
                
                break;

            case MagicItemType.Wand:
                // Feature
                // SpellTier
                // Spell
                // Benefits
                // Curses

                if (allowPriestSpells) {
                    spellClass = GMTools.MagicItemSimpleTable.getRandomResult("spell class").name;
                }
                result.type = "Wand";
                result.feature = GMTools.MagicItemSimpleTable.getRandomResult("wand features").name;
                result.spellTier = GMTools.MagicItemSimpleTable.getRandomResult("spell tier").name;
                result.spell = GMTools.MagicItemSimpleTable.getRandomResult(`${spellClass} spell tier ${result.spellTier}`).name;
                
                const wandBenefitTable = GMTools.MagicItemSimpleTable.getRandomResult("scroll wand beneft table").name;
                const wandCurseTable = GMTools.MagicItemSimpleTable.getRandomResult("scroll wand curse table").name;
                
                for (let b = 0; b < result.nBenefits; b++) {
                    const benefit: SimpleEntry = GMTools.MagicItemDescriptionTable.getRandomResult(wandBenefitTable);
                    const itemBenefit = MagicItemData.getNamedEffect(benefit.name, benefit.description);
                    result.benefits.push(itemBenefit);
                }
                for (let c = 0; c < result.nCurses; c++) {
                    const curse: SimpleEntry = GMTools.MagicItemDescriptionTable.getRandomResult(wandCurseTable);
                    const itemCurse = MagicItemData.getNamedEffect(curse.name, curse.description);
                    result.curses.push(itemCurse);
                }
                
                break;

            case MagicItemType.Weapon:
                // Type
                // Bonus
                // Feature
                // Benefits
                // Curses
                result.type  = GMTools.MagicItemSimpleTable.getRandomResult("weapon type").name;
                result.bonus = GMTools.MagicItemSimpleTable.getRandomResult("weapon bonus").name;
                result.feature = GMTools.MagicItemSimpleTable.getRandomResult("weapon feature").name;
                for (let b = 0; b < result.nBenefits; b++) {
                    const benefit: SimpleEntry = GMTools.MagicItemDescriptionTable.getRandomResult("weapon benefit");
                    const itemBenefit = MagicItemData.getNamedEffect(benefit.name, benefit.description);
                    result.benefits.push(itemBenefit);
                }
                for (let c = 0; c < result.nCurses; c++) {
                    const curse: SimpleEntry = GMTools.MagicItemDescriptionTable.getRandomResult("weapon curse");
                    const itemCurse = MagicItemData.getNamedEffect(curse.name, curse.description);
                    result.curses.push(itemCurse);
                }
                
                break;

            default:
                throw Error(`GMTools.getMagicItem: unknown type "${result.itemType.toString()}"`);
        }



        // console.log(JSON.stringify(result, undefined, 2));

        return result.getCleanType();

    }

    public static getMagicItems(quantity: number, itemType: MagicItemType, allowPriestSpells: boolean): MagicItemDataType[] {
        let result: MagicItemDataType[] = [];
        for (let i = 0; i < quantity; i++) {
            result.push(GMTools.getMagicItem(itemType, allowPriestSpells));
        }
        return result;
    }

}

export default GMTools;
