import React from 'react';
import Navbar from './common/Navbar';
import { Outlet } from 'react-router-dom';
import Footer from './common/Footer';
import { isMobile } from 'react-device-detect';


function ShadowDarkTools() {

    // console.log(`isMobile = ${isMobile}`);
    // const location = useLocation;
    // console.log(`LOCATION SDT = ${JSON.stringify(location, undefined, 3)}`);

    
    const appClass = (isMobile)
        ? "app-mobile"
        : "app";

    return (
        <>
            <div className={appClass}>
                <h1 className="appTitle">Shadowdark Tools</h1>
                <div className='font-face-montserrat-medium'>
                    <Navbar />
                </div>
                <Outlet />
            </div>
            <Footer />
            <br />
            <br />
            <br />
        </>
    );
}

export default ShadowDarkTools;
