import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit';

const adventureIdeasSlice = createSlice({
    name: 'npcNames',
    initialState: {
        ideas: [] as string[],
        ideasType: "" as string,
        adventureQuantity: 5, 
        adventureSiteQuantity: 5,
    },
    reducers: {
        setAdventureIdeas(state, action: PayloadAction<string[]>) {
            state.ideas = action.payload;
        },
        setAdventureQuantity(state, action: PayloadAction<number>) {
            state.adventureQuantity = action.payload;
        },
        setAdventureSiteQuantity(state, action: PayloadAction<number>) {
            state.adventureSiteQuantity = action.payload;
        },
        setAdventureIdeasType(state, action: PayloadAction<string>) {
            state.ideasType = action.payload;
        }
    }
});

export const {
    setAdventureIdeas
    , setAdventureQuantity
    , setAdventureSiteQuantity
    , setAdventureIdeasType
} = adventureIdeasSlice.actions;
export const adventureIdeasReducer = adventureIdeasSlice.reducer;
