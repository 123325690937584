import * as magicItemSimpleTableData from './tables/magic.item.simple.table.json';
import * as magicItemDescriptionTableData from './tables/magic.item.description.table.json';
import { ITwoFactorTable } from "./interfaces/ITable";
import SimpleEntry from './models/SimpleEntry';
import SimplestEntry from './models/SimplestEntry';

const MagicItemSimpleTableData: ITwoFactorTable<SimplestEntry> = magicItemSimpleTableData as ITwoFactorTable<SimplestEntry>;
const MagicItemDescriptionTableData: ITwoFactorTable<SimpleEntry> = magicItemDescriptionTableData as ITwoFactorTable<SimpleEntry>;

export {MagicItemSimpleTableData, MagicItemDescriptionTableData};
