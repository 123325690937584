import "./GmPinBoard.css";
import React, { PropsWithChildren } from "react";
import "./GmDisplayPanel.css";
import { IoCloseCircle } from "react-icons/io5";

class GmPinBoardItem<T> {
    name: string = "";
    item: T | null = null;

    constructor(name: string, item: T) {
        this.name = name;
        this.item = item;
    }
}

type GmToolPanelProps<T> = {
    maxQuantity: number;
    pinnedItems: GmPinBoardItem<T>[];
    pinnedItemName: string;
    removePin: (e: React.MouseEvent<HTMLButtonElement>, index: number) => void;
    selectPin: (e: React.MouseEvent<HTMLButtonElement>, index: number) => void;
}



function GmPinBoard<T>(props: PropsWithChildren<GmToolPanelProps<T>>) {
    const {
        maxQuantity,
        pinnedItems,
        pinnedItemName,
        removePin,
        selectPin,
    } = props;


    const renderedPinnedEntries = (pinnedItems.length === 0)
        ? <div className="text-center rounded-lg  border-2 border-gray-200 text-gray-400">
            {`Pin up to ${maxQuantity} ${pinnedItemName}.`}
        </div>
        : pinnedItems.map((item, index) => {
            return (
                <div key={index} className="h-8 flex flex-row items-center justify-left">
                    <button
                        className="pinned-item w-full h-8 rounded-l-lg px-3 border border-gray-400 text-gray-400'"
                        onClick={(e) => selectPin(e, index)}
                    >
                        {item.name}
                    </button>
                    <button
                        className="text-xl rounded-r-lg px-1 h-8 border border-gray-400 text-gray-400  hover:text-black"
                        onClick={(e) => removePin(e, index)}
                    >
                        <IoCloseCircle />
                    </button>
                </div>
            );
        });

    return (
        // <div className="text-center bg-white px-0">
        <div className=" bg-white px-0">
            <div className="">
                {renderedPinnedEntries}
            </div>
        </div>
    );

}

export { GmPinBoardItem }
export default GmPinBoard;
