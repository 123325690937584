import React, { useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import { HiMenu } from "react-icons/hi";
import Button from '../gmtools/general/Button';

function Navbar() {
    const [showMenu, setShowMenu] = useState(false);

    const links = [
        { label: 'Home', path: '/' },
        { label: 'GM Tools', path: '/gmtools' },
        { label: 'Planning', path: '/planning' },
        { label: 'Reference', path: '/reference' },
        { label: 'Resources', path: '/resources' },
        { label: 'About', path: '/about' },
    ];

    const linksMobile = [
        { label: 'Home', path: '/' },
        { label: 'GM Tools', path: '/gmtools' },
        { label: 'GM Tools (full layout)', path: '/gmtoolsfull' },
        { label: 'Planning', path: '/planning' },
        { label: 'Reference', path: '/reference' },
        { label: 'Resources', path: '/resources' },
        { label: 'About', path: '/about' },
    ];


    const currentLocation = useLocation();
    // console.log(`LOCATION = ${JSON.stringify(currentLocation, undefined, 3)}`);
    const locationName = linksMobile.find((link) => {
        return link.path === currentLocation.pathname;
    })?.label;

    const renderedLinks = links.map((link) => {
        return (
            <span key={link.label}>
                <NavLink
                    key={link.label}
                    to={link.path}
                    className={({ isActive, isPending }) =>
                        isPending
                            ? "whitespace-nowrap min-w-min border-b-4 px-4 mr-4 text-xl min-w-min"
                            : isActive
                                ? "whitespace-nowrap min-w-min border-b-4 px-4 mr-4 text-xl font-bold border-black"
                                : "whitespace-nowrap min-w-min border-b-4 px-4 mr-4 text-xl"
                    }
                >
                    {link.label}
                </NavLink>
            </span>
        );
    });

    const navigate = useNavigate();
    function gotoLocation(event: React.MouseEvent<HTMLElement>, location: string): void {
        navigate(location);
        setShowMenu(!showMenu);
    }

    const renderedLinksMobile = linksMobile.map((link) => {
        return (
            <div key={link.label}>
                <Button menu lefttoright
                    className='w-full justify-center'
                    onClick={(e) => gotoLocation(e, link.path)}
                >
                    <>{link.label}</>
                </Button>
            </div>
        );
    });

    function toggleMenu(event: React.MouseEvent<HTMLElement>): void {
        setShowMenu(!showMenu);
    }

    const navMenu = (!showMenu)
        ? null
        : <div className='pb-3'>
            {renderedLinksMobile}
        </div>


    return (
        <>
            <BrowserView>
                <div className="h-12 mb-4 sticky top-0 overflow-x-auto flex flex-row items-center">
                    {renderedLinks}
                </div>
            </BrowserView>
            <MobileView>
                <Button primary lefttoright
                    className='w-full justify-center'
                    onClick={toggleMenu}
                >
                    <><HiMenu />&nbsp;{locationName}</>
                </Button>
                {navMenu}
            </MobileView>
        </>
    );
}

export default Navbar;
